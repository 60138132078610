import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import './change-password.css';

import {RDP_CONFIG} from '../../config';

import {authenticationService, passwordService} from '../../services';

import {
  ButtonArrow,
  Footer,
  // Header,
  HeaderV2,
  Navigation,
  Loader,
  ErrorDialog,
  SuccessDialog,
  FormGroup
} from '../../components';

export class ViewChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      success: false,
      authed: authenticationService.getLocalUserData()
    }

    this.login = '';

    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onHandlerCloseSuccessDialog = this.onHandlerCloseSuccessDialog.bind(this);
  }

  componentDidMount() {
    document.title = "Mudar Senha - Page Compliance";

    this.scrollToTop();
    this.getUser();
  }

  scrollToTop() {
    $('html, body').scrollTop(0);
  }

  getUser() {
    var _this = this;
    var localUserData = JSON.parse(window.localStorage.getItem('rdp-new-client-' + RDP_CONFIG.clientName));
    _this.login = localUserData.login;
  }

  onSubmitForm(event) {
    event.preventDefault();

    var _this = this;
    var form = event.currentTarget;
    var params = {};

    params['login'] = _this.login;
    params['oldPassword'] = form['oldPassword'].value;
    params['password'] = form['password'].value;
    params['confirmPassword'] = form['confirmPassword'].value;

    // console.log(_this);

    if (params['password'] !== form['confirmPassword'].value) {
      _this.errorFormFields({custom_message: 'Nova senha e confirmação de senha não estão iguais'});
    } else if (params['oldPassword'] === params['password']) {
      _this.errorFormFields({custom_message: 'Você não pode alterar a senha para a mesma'});
    } else if (params['password'].length < 5) {
      _this.errorFormFields({custom_message: 'Sua senha deve ter no mínimo 6 caracteres'});
    } else {
      _this.changePassword(params);
    }
  }

  changePassword(params) {
    var _this = this;

    _this.setState({
      loading: true,
      error: false,
      success: false
    }, function() {
      passwordService.change(params).then(function(response) {
      // console.log('RESPONSE', response);
        _this.successResponse({message: 'Senha alterada com sucesso'})
      }, function(reason) {
      // console.log('reason', reason);
        _this.errorFormFields(reason);
      });
    });
  }

  successResponse(success) {
    var _this = this;

    _this.setState({loading: false, success: success});
  }

  errorFormFields(error) {
    var _this = this;

    _this.setState({loading: false, error: error});
  }

  onHandlerCloseSuccessDialog() {
    this.setState({success: false});
  }

  render() {
    var error,
      loader,
      success;

    if (!this.state.authed) {
      return <Redirect to="/login"/>;
    }

    if (this.state.loading) {
      loader = <Loader/>;
    }

    if (this.state.error) {
      error = <ErrorDialog error={this.state.error}/>;
    }

    if (this.state.success) {
      success = <SuccessDialog func={this.onHandlerCloseSuccessDialog} success={this.state.success}/>;
    }

    return (<div className="rdp-view-change-password">
      {success}
      {error}
      {loader}
      {/* <Header noBgTransition="noBgTransition"/> */}
      <HeaderV2 navigation={<Navigation/>} label="Alterar senha"/>
      <div className="rdp-main">
        <div className="rdp-container">
          <form className="rdp-form-change-password" onSubmit={this.onSubmitForm} autoComplete="off">
            <div className="fieldset">
              <div className="legend rdp-title-form">Alterar senha</div>
              <FormGroup formGroupClass="rdp-form-group" labelFor="oldPassword" labelClass="rdp-sr-only" labelText="Digite sua senha" inputName="oldPassword" inputType="password" inputClass="rdp-form-control" placeholder="Digite sua senha" autoComplete="nope" required="required"/>

              <FormGroup formGroupClass="rdp-form-group" labelFor="password" labelClass="rdp-sr-only" labelText="Digite sua nova senha" inputName="password" inputType="password" inputClass="rdp-form-control" placeholder="Digite sua nova senha" autoComplete="nope" required="required"/>

              <FormGroup formGroupClass="rdp-form-group" labelFor="confirmPassword" labelClass="rdp-sr-only" labelText="confirme sua nova senha" inputName="confirm-password" inputType="password" inputClass="rdp-form-control" placeholder="Confirme sua nova senha" autoComplete="nope" required="required"/>

              <div className="rdp-form-group rdp-form-actions">
                <ButtonArrow type="submit" textButton="Alterar" className="rdp-btn-arrow-orange"/>
              </div>

            </div>
          </form>
        </div>
      </div>
      {/* <Navigation ref={this.navigation}/> */}
      <Footer/>
    </div>);
  }
}
