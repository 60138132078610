import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {authenticationService} from '../../services';
import './my-certificates.css';

import {
  Header,
  Footer,
  Navigation,
  CardCertificate,
  Loader
} from '../../components';

export class ViewMyCertificates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      offers: [],
      authed: authenticationService.getLocalUserData()
    }
  }

  componentDidMount(){
    document.title = "Meus Certificados - Page Compliance";
  }

  render() {
    let loader;

    if(!this.state.authed){
      return (
        <Redirect to={{
          pathname: '/login'
        }}/>
      );
    }

    if (this.state.loading) {
      loader = <Loader/>;
    }

    return (
      <div className="rdp-view rdp-view-my-certificates">
        {loader}
        <Header/>
        <div className="rdp-main">
          <section className="rdp-certificates">
            <div className="rdp-container">
              <CardCertificate/>
              <CardCertificate/>
              <CardCertificate/>
              <CardCertificate/>
              <CardCertificate/>
              <CardCertificate/>
            </div>
          </section>
          <Navigation/>
        </div>
        <Footer/>
      </div>
    );
  }
}
