import React, { Component } from 'react';
// import {RatingStars, ModalRating} from '../../components';
import { RDP_CONFIG } from '../../config';
import { RDPHelpers } from '../../helpers';
import { ratingService } from '../../services';
import './track.css';

export default class Track extends Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: this.getProgress(this.props.offer),
      tabIndexTrack: -1
    }

    this.onClickToggle = this.onClickToggle.bind(this);
    this.onClickActivity = this.onClickActivity.bind(this);
    this.onKeyPressActivity = this.onKeyPressActivity.bind(this);
    this.onClickCloseModalRating = this.onClickCloseModalRating.bind(this);
    this.onClickOpenModalRating = this.onClickOpenModalRating.bind(this);
  }

  componentWillUpdate(nextProps) {
    //console.log('componentWillUpdate', nextProps.offer.progress, this.props.offer.progress, this.state.progress);

    let prgss = window.parseInt(this.props.offer.progress * 100);
    let prct = window.parseInt(this.state.progress.percent);

    if (prgss > 0 && prgss !== prct) {
      this.setState({
        progress: this.getProgress(this.props.offer)
      })
    }
  }

  onClickCloseModalRating() {
    this.setState({ showModalRating: false });
  }

  onClickOpenModalRating() {
    this.setState({ showModalRating: true });
  }

  onSubmitRating(rating) {
    let _this = this;
    let { offer } = this.props;
    let objRating = {
      comment: rating.review,
      score: rating.ratingStars,
      contentOfferId: offer.contentOfferId,
      featured: false
    }

    if (objRating.score > 0) {

      ratingService.insert(objRating).then(function (response) {
        _this.setState({ showModalRating: false, myRating: rating.ratingStars });
      }, function (reason) {
        _this.setState({
          showModalRating: false
        }, function () {
          _this.setState({ error: RDP_CONFIG.defaultReason });
        });
      });
    } else { }
  }

  onClickActivity(event) {
    console.log('onClickActivity');
    this.props.onClickActivity(event.currentTarget.dataset.activityId);
    this.setState({ tabIndexTrack: -1 })
  }

  onKeyPressActivity(event) {
    console.log('onKeyPressActivity');
    if (event.keyCode === 13) {
      this.props.onClickActivity(event.currentTarget.dataset.activityId);
      this.setState({ tabIndexTrack: -1 })
    }
  }


  getCategorie(offer) {
    let cat = offer.categories[0];
    if (offer.categories.length > 0) {
      cat = offer.categories[0];
    } else {
      cat = {
        color: "#666",
        name: ""
      }
    }

    return cat;
  }

  getActivities(node, lastNodes, lastContext) {
    //console.log('getActivities', this.state.progress, this.props.evaluated);

    let _this = this;
    let activities = [];

    activities = node.activities.map(function (item, idx, list) {
      let status = '';

      if (item.interaction && item.interaction.status) {
        status = item.interaction.status.toLowerCase();
      }

      let enabled = 'enabled';

      if (_this.props.trailType === 0 && status === "" && item.id !== _this.props.currentActivityId && item.id !== _this.props.nextActivityId) {
        enabled = 'disabled';
      }

      // console.log(_this.props.trailType, status, _this.props.currentActivityId, item.id, _this.props.nextActivityId, enabled);

      return <li key={idx} onClick={_this.onClickActivity} onKeyDown={(e) => _this.onKeyPressActivity(e)} data-activity-id={item.id} data-status={status} className={`activity-item ${item.assessment
        ? 'assessment'
        : ''} ${item.assessment && !_this.props.availableAssessment
          ? 'disabled-assessment'
          : ''} ${status} ${_this.props.currentActivityId === item.id
            ? 'selected'
            : ''}  ${status} ${enabled}`}>

        <h4 className="activity-title" title={item.title} tabIndex={_this.state.tabIndexTrack}>
          <span className="activity-label">
            <span className="activity-text">{item.title}</span>
          </span>
        </h4>
      </li>;
    });

    if (lastNodes === lastContext) {
      let onClick = function () {
        if (_this.state.progress.percent === 100 && !_this.props.evaluated) {
          _this.props.onClickRating();
        }
      };

      let disabled = _this.state.progress.percent === 100
        ? ''
        : 'disabled-assessment';

      let status = _this.props.evaluated
        ? 'done'
        : '';

      activities.push(<li key={activities.length + 1} onClick={onClick} data-status={status} className={`activity-item assessment ${status} ${disabled}`}>
        <h4 className="activity-title" title="Avaliar Curso">
          <span className="activity-label">
            <span className="activity-text">Avaliar Curso</span>
          </span>
        </h4>
      </li>);
    }

    return <ul node-id={node.id} className="activity-group">{activities}</ul>;
  }

  onClickToggle() {
    this.props.onClickToggle(this.props.width);

    console.log('aqui 2', this.props.width )

    if (this.props.width === "closed") {
      this.setState({ tabIndexTrack: 4 })
      let item = document.getElementById("rdp-ini-menu-conteudo")
      item.focus();  
      this.props.onFocusPlayer(false)
    } else {
      this.setState({ tabIndexTrack: -1 })
      this.props.onFocusPlayer(true)
    }
    
  }

  getNodes(context, lastContext) {
    let _this = this;
    let nodes = [];

    nodes = context.nodes.map(function (item, idx, list) {
      let activities = _this.getActivities(item, idx === list.length - 1, lastContext);

      return <li key={idx} node-id={item.id} className="node-item">
        <h3 className="node-title" title={item.title}>
          <span className="node-text">{item.title}</span>
        </h3>

        {activities}
      </li>;
    });

    return <ul aria-label="Estrutura do curso" context-id={context.id} className="node-group">{nodes}</ul>;
  }

  getContexts(offer) {
    let _this = this;
    return offer.treeOffer.contexts.map(function (item, idx, list) {
      let nodes = _this.getNodes(item, idx === list.length - 1);

      let nameCourse = "Curso " + item.title
      return <div key={idx} className="context-item">
        <h2 className="context-title" title={item.title} tabIndex={_this.state.tabIndexTrack} aria-label={nameCourse} >{item.title}</h2>

        {nodes}
      </div>;
    });
  }

  getProgress(offer) {
    //console.log('getProgress', offer);

    let progress = {
      percent: window.parseInt(offer.progress * 100),
      count: 0,
      length: 0,
      accessibility_percent: RDPHelpers.getNumberLabel(window.parseInt(offer.progress * 100)),
      accessibility_count: 0,
      accessibility_length: 0
    };

    let contexts = offer.treeOffer.contexts;

    let countActivities = function (node) {
      node.activities.forEach(function (activity) {

        if (activity.interaction && activity.interaction.progress) {
          progress.count += activity.interaction.progress;
        } else {
          // console.log('activity sem interaction', activity);
        }

        progress.length++;
      });
    }

    let countNodes = function (context) {
      context.nodes.forEach(function (node) {
        countActivities(node);
      });
    }

    contexts.forEach(function (context) {
      countNodes(context);
    })

    progress.accessibility_count = RDPHelpers.getNumberLabel(progress.count);
    progress.accessibility_length = RDPHelpers.getNumberLabel(progress.length);

    return progress;
  }

  setFocusElement = () => {
    let item = document.getElementById("rdp-btn-menu")
    item.focus();
  }

  render() {
    let { width, offer } = this.props;

    let categorie = this.getCategorie(offer);
    let contexts = this.getContexts(offer);
    let progress = this.getProgress(offer);
    let accessibility_percent = this.getProgress(offer).accessibility_percent;
    let accessibility_count = this.getProgress(offer).accessibility_count;
    let accessibility_length = this.getProgress(offer).accessibility_length;
    let collapse_btn = width === "closed" ? "Abra o menu de conteúdos" : "Feche o menu de conteúdos";
    
    // Custom
    categorie.color = "#0f4a82";

    return (<div className={`rdp-track-container ${width}`}>
      <div className="wrap-track-view">
        <div className="course-header">
          <span className="course-category" style={{
            backgroundColor: categorie.color
          }}>
            <span className="category-text">{categorie.name}</span>
          </span>

          <button tabIndex="4" id="rdp-btn-menu" onClick={this.onClickToggle} type="button" className="button-collapse">
            <span>{collapse_btn}</span>

            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448">
              <path fill={categorie.color} d="M96 136v144c0 4.25-3.75 8-8 8-2 0-4.25-0.75-5.75-2.25l-72-72c-1.5-1.5-2.25-3.75-2.25-5.75s0.75-4.25 2.25-5.75l72-72c1.5-1.5 3.75-2.25 5.75-2.25 4.25 0 8 3.75 8 8zM448 328v48c0 4.25-3.75 8-8 8h-432c-4.25 0-8-3.75-8-8v-48c0-4.25 3.75-8 8-8h432c4.25 0 8 3.75 8 8zM448 232v48c0 4.25-3.75 8-8 8h-272c-4.25 0-8-3.75-8-8v-48c0-4.25 3.75-8 8-8h272c4.25 0 8 3.75 8 8zM448 136v48c0 4.25-3.75 8-8 8h-272c-4.25 0-8-3.75-8-8v-48c0-4.25 3.75-8 8-8h272c4.25 0 8 3.75 8 8zM448 40v48c0 4.25-3.75 8-8 8h-432c-4.25 0-8-3.75-8-8v-48c0-4.25 3.75-8 8-8h432c4.25 0 8 3.75 8 8z"></path>
            </svg>
          </button>
        </div>

        <span id="rdp-ini-menu-conteudo" tabIndex={this.state.tabIndexTrack} aria-label="Início do menu de conteúdos" />

        <div className="course-container-progress">

          <div className="content-progress">
            <div className="progress-bar-collapsed">
              <span style={{
                backgroundColor: categorie.color,
                height: progress.percent + '%'
              }}></span>
            </div>

            <span aria-hidden className="text-progress" style={{
              color: categorie.color
            }}>{progress.percent}%</span>

            <span tabIndex={this.state.tabIndexTrack} className="accessibility-text-progress">{`Progresso atual do curso ${accessibility_percent} porcento`}</span>

            <svg viewBox="0 0 36 36">
              <path className="fake-progress" d="M18 2.0845a 15.9155 15.9155 0 0 1 0 31.831a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke="#d1d3d4" strokeWidth="4" strokeDasharray="100, 100"></path>
            </svg>

            <svg className="svg-progress" viewBox="0 0 36 36">
              <path className="progress-path" d="M18 2.0845a 15.9155 15.9155 0 0 1 0 31.831a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke={categorie.color} strokeWidth="4" strokeDasharray={`${progress.percent}, 100`}></path>
            </svg>
          </div>

          <p aria-hidden className="progress-description">
            <span className="concluded" style={{
              color: categorie.color
            }}>{progress.count}</span>
            de
            <span className="total-itens" style={{
              color: categorie.color
            }}>{progress.length}</span>
            Concluídos
          </p>

          <span tabIndex={this.state.tabIndexTrack} className="accessibility-progress-description">{`aulas ${accessibility_count} de ${accessibility_length} concluídas`}</span>
        </div>

        <div className="course-main">
          {contexts}
        </div>
        <span tabIndex={this.state.tabIndexTrack} aria-label="Fim do menu de conteúdos" />
        <span tabIndex={this.state.tabIndexTrack} onFocus={this.setFocusElement}></span>

      </div>

      {/* <!-- captura o evento de fechar --> */}
      <div onClick={this.onClickToggle} className="close-track-view close-from-contexts"></div>

      {/* <!-- Intercepta o evento de click pra não abrir a atividade --> */}
      <div onClick={this.onClickToggle} className="open-track-view open-from-contexts"></div>
    </div>)
  }
}
