import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { RDP_CONFIG } from '../../config';
import $ from 'jquery';
import './home.css';

import { offerService, authenticationService, bannerService, ratingService } from '../../services';

import {
  Courses,
  Carousel,
  StudentsReviews,
  Header,
  HeaderWithContent,
  Footer,
  Navigation,
  Loader,
  SearchBar,
  ErrorDialog,
  NoResults,
  ShowMore,
  Content
} from '../../components';

export class ViewHome extends Component {
  constructor(props) {
    super(props);

    // console.log('ViewHome', 'constructor');

    this.state = {
      loading: true,
      error: false,
      redirect: false,
      offers: null,
      highlights: [],
      review: null,
      categoriesCount: [],
      categories: [],
      authed: authenticationService.getLocalUserData(),
      firstSearch: false,
      size: 6,
      page: 0,
      lastPage: false,
      orderBy: 'offerStartAt asc',
      area: 0, // all
      curso: 0, // all
      search: '',
      childsButton: []
    }

    // filter and search local variables
    this.currentOffers = [];
    this.categoryId = 0;
    this.progresStatusId = 0;
    this.searchTerm = '';

    // handlers
    this.handleResult = this.handleResult.bind(this);
    this.handleFilterByCategory = this.handleFilterByCategory.bind(this);
    this.handleFilterByProgress = this.handleFilterByProgress.bind(this);
    this.handleCountOfferByCategories = this.handleCountOfferByCategories.bind(this);
    this.getCountOfferByCategories = this.getCountOfferByCategories.bind(this);
    this.setRatingToOffer = this.setRatingToOffer.bind(this);
    this.onClickShowMore = this.onClickShowMore.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onSubmitSearch = this.onSubmitSearch.bind(this);
  }

  onSubmitSearch(searchOptions) {
    console.log('onSubmitSearch', searchOptions);

    this.searchTerm = searchOptions.search;

    let _this = this;

    let params = {
      orderBy: this.state.orderBy,
      size: this.state.size,
      page: 0
    }

    if (this.categoryId > 0) {
      params.area = this.categoryId;
    }

    if (this.progresStatusId > 0) {
      params.curso = this.progresStatusId;
    }

    if (this.searchTerm !== "") {
      params.q = this.searchTerm;
    }

    this.setState({
      loading: true,
      error: null,
      offers: null
    }, function () {
      _this.getOffers(params);
    })
  }

  getAllCategories() {
    let _this = this;

    offerService.getAllCategories().then(function (response) {
      _this.setState({
        categories: response
      })
    })
  }

  handleResult(result) {
    console.log(result);
    this.currentOffers = result;
    var filteredOffers = this.getFilteredOffers();
    var categoriesMap = this.getCountOfferByCategories();
    this.setState({ offers: filteredOffers, firstSearch: true, categoriesCount: categoriesMap });
  }

  handleFilterByCategory(categoryId) {
    console.log('handleFilterByCategory', categoryId);

    let _this = this;

    this.categoryId = Number(categoryId);

    let params = {
      orderBy: this.state.orderBy,
      size: this.state.size,
      page: this.state.page
    }

    if (this.categoryId > 0) {
      params.page = 0;
      params.area = this.categoryId
    }

    if (this.progresStatusId > 0) {
      params.curso = this.progresStatusId;
    }

    if (this.searchTerm !== "") {
      params.q = this.searchTerm;
    }

    this.setState({
      loading: true,
      error: null,
      offers: null
    }, function () {
      _this.getOffers(params);
    })
  }

  handleFilterByProgress(progresStatusId) {
    console.log('handleFilterByProgress', progresStatusId);

    let _this = this;

    this.progresStatusId = Number(progresStatusId);

    let params = {
      orderBy: this.state.orderBy,
      size: this.state.size,
      page: this.state.page
    }

    if (this.categoryId > 0) {
      params.area = this.categoryId
    }

    if (this.progresStatusId > 0) {
      params.page = 0;
      params.curso = this.progresStatusId;
    }

    if (this.searchTerm !== "") {
      params.q = this.searchTerm;
    }

    this.setState({
      loading: true,
      error: null,
      offers: null
    }, function () {
      _this.getOffers(params);
    })
  }

  resetForm() {
    console.log('resetForm');

    document.querySelector(".rdp-form-search").reset();
    if (document.getElementsByClassName("rdp-input-option").length > 0) document.getElementsByClassName("rdp-input-option")[0].style.display = "none";

    this.categoryId = 0;
    this.progresStatusId = 0;
    this.searchTerm = '';
    this.setState({
      page: 0,
      offers: null
    }, function () {
      this.getOffers({ size: this.state.size, page: 0 });
    })
  }

  getAllFeatured() {
    var _this = this;

    ratingService.getRandomFeatured().then(function (response) {
      // console.log("ratingService.getRandomFeatured", response);
      // response = response.filter(function(item) {
      //   return item.userName && item.comment && item.userAvatar;
      // });
      //
      // if (response.length === 1) {
      //   _this.setState({
      //     review: response[0]
      //   })
      // } else if (response.length > 1) {
      //   var index = Math.floor(Math.random() * response.length);
      //
      //   _this.setState({
      //     review: response[index]
      //   });
      // }
      _this.setState({ review: response })
    }, function (reason) {
      // console.log('ratingService.getRandomFeatured', reason);
      _this.setState({ error: reason })
    })
  }

  getFilteredOffers() {
    console.log('getFilteredOffers');

    var _this = this;
    var catFiltered = {};
    var filteredList;

    console.log('this.progresStatusId', _this.progresStatusId);

    if (_this.categoryId === 0) {
      catFiltered = _this.currentOffers;
    } else {
      catFiltered = _this.currentOffers.filter(function (item) {
        return _this.filterOfferCategories(item, _this.categoryId)
      });
    }

    if (_this.progresStatusId === 0) {
      // 0 - Todos os cursos

      filteredList = catFiltered;
    } else {
      if (_this.progresStatusId === 1) {
        // 1 - Meus Cursos

        filteredList = catFiltered.filter(function (item) {
          return item.progress > 0
            ? item
            : null;
        });

        console.log('filteredList', filteredList);
      } else if (_this.progresStatusId === 2) {
        // 2 - Em andamento

        filteredList = catFiltered.filter(function (item) {
          if ((item.progress * 100) > 1 && (item.progress * 100) < RDP_CONFIG.offerProgressToDone) {
            return item;
          } else {
            return null
          }
        });
      } else if (_this.progresStatusId === 3) {
        // 3 - Concluídos / Certificados

        filteredList = catFiltered.filter(function (item) {
          if ((item.progress * 100) >= RDP_CONFIG.offerProgressToDone) {
            return item;
          } else {
            return null
          }
        });
      }

      filteredList = filteredList.filter(function (item) {
        if (item.offerStartAt < (new Date())) {
          return item;
        } else {
          return null;
        }
      })
    }

    return filteredList;
  }

  filterOfferCategories(item, categoryId) {
    // console.log('filterOfferCategories', item, categoryId);

    var numCategories = item.categories.length;

    for (let i = numCategories - 1; i >= 0; i--) {
      var cat = item.categories[i];
      // console.log(cat.id, categoryId);
      if (cat.id === Number(categoryId)) {
        return item;
      }
    }

    return null;
  }

  getCountOfferByCategories() {
    var _this = this;
    var categoriesMap = [];
    var numOffers = _this.currentOffers.length;

    for (let i = numOffers - 1; i >= 0; i--) {
      var offer = _this.currentOffers[i];

      offer.categories = offer.categories || [];

      offer.categories.map(function (item) {
        categoriesMap[item.id]
          ? categoriesMap[item.id]++
          : categoriesMap[item.id] = 1;
        return categoriesMap[item.id];
      });
    }
    return categoriesMap;
  }

  handleCountOfferByCategories() {
    var categoriesMap = this.getCountOfferByCategories();
    this.setState({ categoriesCount: categoriesMap });
  }

  componentWillMount() {
    // console.log('ViewHome', 'componentWillMount');

    if (this.state.authed) {
      this.getOffers({ orderBy: this.state.orderBy, size: this.state.size, page: this.state.page });
      this.getBanner();
      this.getAllFeatured();
    }
  }

  componentDidMount = async() => {
    document.title = "Todos os Cursos - Page Compliance";

    this.scrollToTop();
    this.getAllCategories();

  }

  componentDidUpdate(){

    //CUSTOM
    // let childs = document.getElementById("rdp-carousel-slider").getElementsByTagName('button')
    // for(let i=0; i< childs.length; i++){
    //   let item= childs.item(i)
    //   item.setAttribute('tabIndex', '-1')
    // }

  }

  scrollToTop() {
    $('html,body').scrollTop(0);
  }

  getAvarageRatingByOfferId(params) {
    // console.log('getRatingByOfferId', params);

    var _this = this;

    this.setState({
      loading: true
    }, function () {
      ratingService.getAllByOfferId({ offerId: params.offerId }).then(function (response) {
        _this.setRatingToOffer({ offerId: params.offerId, ratings: response });
      });
    });
  }

  setRatingToOffer(params) {
    // console.log('setRatingToOffer', params);

    var rating = 0;

    for (let i = 0; i < params.ratings.length; i++) {
      rating += params.ratings[i].score;
    }

    rating = Math.floor(rating / params.ratings.length);

    if (isNaN(rating)) {
      rating = 0;
    }

    // console.log(rating);

    let offers = this.state.offers.map(function (offer) {
      // console.log(offer.contentOfferId, params.offerId);

      if (offer.contentOfferId === params.offerId) {
        offer.rating = rating;
      }

      return offer;
    });

    let offersWithRaiting = 0;

    for (let i = 0; i < offers.length; i++) {
      // console.log(offers[i].rating);
      if (offers[i].rating !== undefined) {
        offersWithRaiting++
      }
    }

    let state = {
      offers: offers
    }

    // console.log(offersWithRaiting, offers.length, offersWithRaiting === offers.length);

    if (offersWithRaiting === offers.length) {
      state.loading = false;
    }

    this.setState(state);
  }

  getOffers(params) {
    //console.log('getOffers', params);

    var _this = this;

    _this.setState({
      loading: true
    }, function () {
      // console.log('offerService.getAll', 'request', params);
      offerService.getAll(params).then(function (response) {
        //console.log('offerService.getAll', 'response', response);
        response.content.sort((a,b) => b.progress - a.progress);

        _this.currentOffers = Array.isArray(_this.state.offers)
          ? _this.state.offers.concat(response.content)
          : response.content;

        var categoriesMap = _this.getCountOfferByCategories();

        _this.setState({
          loading: false,
          page: response.number,
          lastPage: response.last,
          offers: Array.isArray(_this.state.offers)
            ? _this.state.offers.concat(response.content)
            : response.content,
          filteredOffers: response.content,
          categoriesCount: categoriesMap
        });
      }, function (reason) {
        // console.log('aqui');

        _this.currentOffers = null;
        _this.setState({ loading: false, error: reason });
      });
    });
  }

  getBanner() {
    var _this = this;
    _this.setState({
      loading: true
    }, function () {
      bannerService.getBanner().then(function (banners) {
        // console.log(banners);
        _this.setState({ highlights: banners.content });
      }, function (reason) {
        // console.log('aqui');

        _this.setState({ error: reason });
      });
    });
  }

  onClickShowMore() {
    // console.log('onClickShowMore');

    let params = {
      orderBy: this.state.orderBy,
      size: this.state.size,
      page: this.state.page + 1
    }

    if (this.categoryId > 0) {
      params.area = this.categoryId
    }

    if (this.progresStatusId > 0) {
      params.curso = this.progresStatusId;
    }

    if (this.searchTerm !== "") {
      params.q = this.searchTerm;
    }

    this.getOffers(params);
  }

  render() {
    // console.log('ViewHome', 'render', this);

    let loader,
      error,
      result,
      showMore;

    if (!this.state.authed) {
      return <Redirect to="/login" />;
    }

    if (this.state.loading) {
      loader = <Loader />;
    }

    if (this.state.error) {
      error = <ErrorDialog error={this.state.error} />;
    }

    var highlights;

    if (this.state.highlights.length > 0) {
      highlights = <Carousel tabIndex="-1" items={this.state.highlights} />;
    }

    var reviews;

    if (this.state.review) {
      // console.log("exibe review: ", this.state.review);
      reviews = <StudentsReviews review={this.state.review} />;
      // console.log("aqui", reviews);
    }

    // console.log(this.state.offers);

    if (this.state.offers && this.state.offers.length && this.state.offers.length > 0) {
      result = (<div className="rdp-container">
        <Courses offers={this.state.offers} />
      </div>);

      if (!this.state.lastPage) {
        showMore = <ShowMore onClick={this.onClickShowMore} />
      }
    } else if (this.state.offers && this.state.offers.length === 0) {
      if (this.progresStatusId === 1) {
        result = <NoResults context={null} title="Você ainda não iniciou nenhum curso." />;
      } else if (this.progresStatusId === 2) {
        result = <NoResults context={null} title="Você ainda não iniciou nenhum curso." />;
      } else if (this.progresStatusId === 3) {
        result = <NoResults context={null} title="Você ainda não concluiu nenhum curso." />;
      } else {
        if (this.state.categoriesCount[this.categoryId] === undefined) {
          result = <NoResults context={null} title="Não foram encontrados cursos nesta categoria" />;
        } else {
          result = <NoResults />;
        }
      }
    }

    return (<div className="rdp-view rdp-view-home">
      {error}
      {loader}
      {/*<Header noBgTransition={true} initialPage={true}/>*/}
      {/*<Navigation />*/}
      
      <Content>
        <span tabIndex="0" aria-label="Início da página Todos os cursos" className="rdp-view-course-section-review" />

        <HeaderWithContent navigation={<Navigation/>}>
          {/* <Navigation/> */}
          <div id="rdp-carousel-slider">
            {highlights}
          </div>
        </HeaderWithContent>

        <SearchBar white="white" categoriesCount={this.state.categoriesCount} onGetResult={this.handleResult} onFilterByCategory={this.handleFilterByCategory} onFilterByProgress={this.handleFilterByProgress} countOfferByCategories={this.handleCountOfferByCategories} onSubmitSearch={this.onSubmitSearch} resetFilters={this.resetForm} />

        <span tabIndex="0" aria-label="Início da listagem de cursos disponíveis" className="rdp-view-home-section-info" />
        {result}
        <span tabIndex="0" aria-label="Fim da listagem de cursos disponíveis" className="rdp-view-home-section-info" />

        {showMore}

        <span tabIndex="0" aria-label="Início do comentário" />
        {reviews}
        <span tabIndex="0" aria-label="Fim do comentário" />

        <span tabIndex="0" aria-label="Fim da página Todos os cursos" />

        <Footer />
      </Content>
    </div>);
  }
}
