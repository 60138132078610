import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './forgot-password.css';

import {forgotPasswordService} from '../../services';

import {ButtonArrow, FormGroup, Loader, ErrorDialog} from '../../components';

export class ViewForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      messageFeedback: false,
      error: false
    }

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    document.title = "Esqueci minha senha - Page Compliance";
  }

  onSubmit(event) {
    event.preventDefault();

    let _this = this,
      params = event.target.toJSON();

    _this.setState({
      loading: true,
      error: false
    }, function() {
      forgotPasswordService.sendToEmail(params).then(function(response) {
        // console.log('HERE', response);
        _this.setState({
          loading: false,
          messageFeedback: true
        });
      }, function(reason){
        _this.setState({loading: false, error: reason});
      });
    });
  }

  render() {
    let error,
      container,
      loader;

    if (this.state.loader) {
      loader = <Loader/>;
    }

    if (this.state.error) {
      error = <ErrorDialog top="top" error={this.state.error}/>;
    }

    if (!this.state.messageFeedback) {
      container = (<form className="rdp-form-forgot-password" onSubmit={this.onSubmit} autoComplete="off">
        <div className="fieldset">
          <div className="legend">Identifique‐se para receber um e‐mail com as instruções e o link para criar uma nova senha.</div>

          <FormGroup formGroupClass="rdp-form-group" labelFor="email" labelClass="rdp-sr-only" labelText="Digite seu email" inputName="email" inputType="email" inputClass="rdp-form-control" placeholder="email@email.com.br" autocomplete="email" required="required"/>

          <div className="rdp-form-group rdp-form-actions">
            <ButtonArrow back="back" tag="link" address="/login" className="rdp-btn-arrow-gray" textButton="Voltar"/>

            <ButtonArrow type="submit" textButton="Enviar" className="rdp-btn-arrow-orange"/>
          </div>
        </div>

      </form>)
    } else {
      container = (<p className="rdp-message-feedback">
        Mensagem enviada para o email cadastrado :)
        <br/>
        <Link to="/login" className="rdp-link">Voltar para login</Link>
      </p>);
    }

    return (<div className="rdp-view rdp-view-forgot-password rdp-bg-squares">
      {error}
      {loader}
      {container}
    </div>);
  }
}
