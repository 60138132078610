import React, {Component} from 'react';
import $ from 'jquery';
import './terms-of-use.css';

// import { authenticationService, fileService, supportService, termsService } from '../../services';
import { termsService } from '../../services';

import {
  // Header,
  HeaderV2,
  Footer,
  Navigation,
  Loader,
  ErrorDialog
} from '../../components';

export class ViewTermsOfUse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      terms: '',
    }
  }

  componentWillMount() {
    this.getTerms();
  }

  componentDidMount() {
    document.title = "Termos de Uso - Page Compliance";
    this.scrollToTop();
  }

  scrollToTop() {
    $('html, body').scrollTop(0);
  }

  getTerms() {
    var _this = this;

    _this.setState({ loading: true }, function() {
      termsService.getTerms().then(function(terms) {
        _this.setState({
          terms,
          loading: false
        });
      }, function(reason) {
        _this.setState({
          error: reason,
          loading: false
        });
      });
    });
  }

  render() {
    let loader, error;

    if (this.state.loading) {
      loader = <Loader/>;
    }

    if (this.state.error) {
      error = <ErrorDialog error={this.state.error} />;
    }

    return (<div className="rdp-view rdp-view-terms">
    {error}
      {loader}
      {/* <Header noBgTransition/> */}
      <HeaderV2 navigation={<Navigation/>} label="Termos de uso"/>
      <div className="rdp-main">
        <section className="rdp-context-container">
          <div className="rdp-container">
            {/* <h2 className="rdp-title-page">Termos de uso</h2> */}
            <div className="rdp-terms-context" dangerouslySetInnerHTML={{__html :this.state.terms}}/>
          </div>
        </section>
        {/* <Navigation/> */}
      </div>
      <Footer/>
    </div>);
  }
}
