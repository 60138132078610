import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import './register.css';

import {authenticationService, registerService, passwordService} from '../../services';

import {ButtonArrow, Loader, ErrorDialog, ModalTerms} from '../../components';

export class ViewRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      redirectErrorPage: false,
      redirectHome: false,
      showModalTerms: false,
      agreeTerms: false
    }

    this.oldPassword = '';
    this.URLToken = '';
    this.login = '';

    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  componentWillMount() {
    document.title = "Cadastro - Page Compliance";

    this.firstAccess();
  }

  errorFormFields(error) {
    var _this = this;

    _this.setState({loading: false, error: error});
  }

  toggleModalTerms(event, value) {
    if (event)
      event.preventDefault();

    var _this = this;

    _this.setState({
      showModalTerms: value,
      agreeTerms: !value
    });
  }

  firstAccess() {
    var _this = this;

    _this.URLToken = window.location.hash.substring(17);

    const params = {
      token: _this.URLToken
    };

    _this.setState({
      loading: true
    }, function() {
      registerService.getFirstAccess(params).then(function(response) {
        _this.fillForm(response);
        _this.setState({loading: false});
      }, function(reason) {
        _this.setState({loading: false, error: reason, redirectErrorPage: true});
      });
    });
  }

  fillForm(data) {
    let _this = this;

    var formRegister = document.forms[0];

    formRegister['cpf-email'].value = data.email;

    _this.oldPassword = data.password;
    _this.login = data.login;
  }

  onSubmitForm(event) {
    event.preventDefault();

    var _this = this,
      params = event.target.toJSON();

    if (params['cpf-email'].length === 0) {
      _this.errorFormFields({custom_message: 'Preencha o campo CPF ou E-mail'});
      return false;
    } else if (params['define-password'] !== params['confirm-password']) {
      _this.errorFormFields({custom_message: 'senhas não são iguais'});
      return false;
    } else if (params['define-password'].length === 0 && params['confirm-password'].length === 0) {
      _this.errorFormFields({custom_message: 'É necessário definir uma nova senha'});
      return false;
    } else if (!params['terms-of-use']) {
      _this.errorFormFields({custom_message: 'Por favor, verifique os termos de uso'});
      return false;
    }

    delete params['terms-of-use'];

    var resetParams = {
      password: params['define-password'],
      token: _this.URLToken
    };

    var signInParams = {
      username: _this.login,
      password: params['define-password']
    };

    _this.setState({
      loading: true,
      error: false
    }, function() {
      passwordService.reset(resetParams).then(function(responseReset) {
        authenticationService.signIn(signInParams).then(function(responseLogin) {
          _this.setState({redirectHome: true});
        }, function(reason) {
          _this.errorFormFields(reason);
        });
      }, function(reason) {
        _this.errorFormFields(reason);
      });
    });
  }

  render() {
    let loader,
      error,
      modalTerms;

    if (this.state.loading) {
      loader = <Loader/>;
    }

    if (this.state.error) {
      error = <ErrorDialog top="top" error={this.state.error}/>;
    }

    if (this.state.showModalTerms) {
      modalTerms = <ModalTerms toggleModal={(event, value) => this.toggleModalTerms(event, value)}/>;
    }

    if (this.state.redirectErrorPage) {
      return (<Redirect to={{
          pathname: '/error',
          state: {
            errorMessage: this.state.error
          }
        }}/>);
    }

    if (this.state.redirectHome) {
      return <Redirect to="/"/>;
    }

    return (<section className="rdp-view rdp-view-register rdp-bg-squares">
      {error}
      {loader}
      <div className="rdp-main">
        <div className="rdp-container">
          <h2 className="rdp-title-transparency">Transparência e Unicidade</h2>
          <form className="rdp-form-register" onSubmit={this.onSubmitForm}>
            <div className="fieldset">
              {/* <div className="legend">
                  Verifique o seu CPF ou email e defina uma nova senha para finalizar o seu cadastro.
                  Quando desejar finalizar o seu cadastro, clique em "Meu perfil" na sua área exclusiva.
                </div> */
              }

              <div className="rdp-form-group">
                <label htmlFor="password">CPF ou E-mail*</label>
                <input type="text" name="cpf-email" id="cpf-email" className="rdp-form-control" placeholder="CPF ou E-mail" aria-label="CPF ou E-mail" autoCapitalize="off" aria-required="true" required="required"/>
              </div>

              <div className="rdp-form-group">
                <label htmlFor="password">Defina sua senha*</label>
                <input type="password" name="define-password" id="define-password" className="rdp-form-control" placeholder="Defina sua senha" aria-label="Defina sua senha" autoComplete="new-password" aria-required="true" required="required"/>
              </div>

              <div className="rdp-form-group">
                <label htmlFor="confirm-password">Confirmar senha*</label>
                <input type="password" name="confirm-password" id="confirm-password" className="rdp-form-control" placeholder="Confirmar senha" aria-label="Confirmar Senha" autoComplete="new-password" required="required"/>
              </div>

              <div className="rdp-form-group">
                <div className="rdp-container-checkbox">
                  <input type="checkbox" id="terms-of-use" className="rdp-form-control" name="terms-of-use" checked={this.state.agreeTerms
                      ? 'checked'
                      : ''} onChange={() => this.setState({
                      agreeTerms: !this.state.agreeTerms
                    })} required="required"/>

                  <label htmlFor="terms-of-use">
                    Aceito os
                    <a className="link-terms" href="#modal-terms-of-use" onClick={event => this.toggleModalTerms(event, true)}>Termos de Uso</a>
                  </label>
                </div>
              </div>

              <div className="rdp-form-group rdp-form-actions">
                <ButtonArrow type="submit" textButton="Salvar" className="rdp-btn-arrow-orange"/>
              </div>

            </div>
          </form>

        </div>
      </div>
      {modalTerms}
    </section>);
  }
}
