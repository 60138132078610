if (!Object.keys) {
  // console.log('Object.keys');

  Object.keys = (function() {
    var hasOwnProperty = Object.prototype.hasOwnProperty,
      hasDontEnumBug = !({toString: null}).propertyIsEnumerable('toString'),
      dontEnums = [
        'toString',
        'toLocaleString',
        'valueOf',
        'hasOwnProperty',
        'isPrototypeOf',
        'propertyIsEnumerable',
        'constructor'
      ],
      dontEnumsLength = dontEnums.length;

    return function(obj) {
      if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
        throw new TypeError('Object.keys called on non-object');
      }

      var result = [],
        prop,
        i;

      for (prop in obj) {
        if (hasOwnProperty.call(obj, prop)) {
          result.push(prop);
        }
      }

      if (hasDontEnumBug) {
        for (i = 0; i < dontEnumsLength; i++) {
          if (hasOwnProperty.call(obj, dontEnums[i])) {
            result.push(dontEnums[i]);
          }
        }
      }
      return result;
    };
  }());
}

if (!Object.entries) {
  // console.log('Object.entries');

  Object.entries = function(obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--)
      resArray[i] = [
        ownProps[i],
        obj[ownProps[i]]
      ];

    return resArray;
  };
}

if (!Array.prototype.forEach) {
  // eslint-disable-next-line
  Array.prototype.forEach = function(fn, scope) {
    for (var i = 0, len = this.length; i < len; ++i) {
      fn.call(scope, this[i], i, this);
    }
  };
}

if (!window.URLSearchParams) {
  window.URLSearchParams = function(paramsString) {
    // console.log('URLSearchParams', paramsString);

    var _this = this;

    // Properties
    this.params = {
      string: '',
      json: {}
    }

    // Methods

    this.toString = function() {
      // console.log('toString');

      var str = '';
      var obj = _this.params.json;

      Object.getOwnPropertyNames(obj).forEach(function(val, idx, array) {
        if (idx > 0) {
          str += '&';
        }

        str += (val + '=' + obj[val]);
      });

      _this.params.string = str;

      return _this.params.string;
    };

    this.toJSON = function(str) {
      // console.log('toJSON', str);

      var obj = {};

      var nodes = str.split('&');

      for (var i = 0; i < nodes.length; i++) {
        obj[nodes[i].split('=')[0]] = nodes[i].split('=')[1];
      }

      return obj;
    }

    this.set = function(key, value) {
      // console.log('set', key, value);

      _this.params.json[key] = value;
    };

    this.get = function(key) {
      // console.log('get', key);

      return _this.params.json[key];
    };

    this.delete = function(key) {
      // console.log('delete', key);

      delete _this.params.json[key];
    };

    // console.log(this);

    return this;
  }
}
