import { RDP_Utils } from '../utils';

export default class Interactions {
  constructor(params) {
    this.user = params.user;
    this.config = params.config;
  }

  put(data) {
    let _this = this;

    return new Promise(function(resolve, reject) {
      if (data.hasOwnProperty('properties')) {
        if (data.properties === null || data.properties === undefined) {
          data.properties = '';
        } else if (Object.keys(data.properties).length === 0 && data.properties.constructor === Object) {
          data.properties = '';
        }
      }

      var dataStr = JSON.stringify({
        id: data.id,
        status: data.status,
        performance: data.performance,
        properties: data.properties,
        progress: data.progress,
        timeSpent: data.timeSpent,
        activityId: data.activityId,
        userId: data.userId
      });

      if (RDP_Utils.inApp()) {
        window.CordovaExec(function(retData) {
          if (typeof data === 'string') {
            retData = JSON.parse(retData);
          }

          resolve(retData);
        }, function(reason) {
          reject(reason);
        }, "LearningPlayer", "RegisterInteraction", [dataStr]);
      } else {
        // console.log('customInteractions', JSON.parse(dataStr));

        RDP_Utils.request({
          url: _this.config.learningURL + 'restapi/api/protected/customInteractions/v2',
          type: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + _this.user.authentication_token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          data: dataStr
        }).then(function(response) {
          resolve(response);
        }, function(reason) {
          reject(reason);
        });
      }
    });
  }

  get() {
    let _this = this;

    return new Promise(function(resolve, reject) {
      RDP_Utils.request({
        url: _this.config.learningURL + 'restapi/api/protected/customInteractions/getFullInteractionsByUser',
        type: 'GET',
        headers: {
          'Authorization': 'Bearer ' + _this.user.authentication_token
        }
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  set(data) {
    let _this = this;

    return new Promise(function(resolve, reject) {
      var dataStr = JSON.stringify(data);

      RDP_Utils.request({
        url: _this.config.learningURL + 'restapi/api/protected/customInteractions/v4',
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + _this.user.authentication_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: dataStr
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  };
}
