import React, {Component} from 'react';
import $ from 'jquery';
import {RDP_CONFIG} from '../../config';
import {RDP_Utils} from '../../utils';
import {authenticationService, ratingService} from '../../services';
import {Loader, ModalRating, ErrorDialog, CardCourse} from '../../components'
import './course-about.css';

export default class CourseAbout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      showModalRating: false,
      buttonRating: false,
      alreadyEvaluated: false
    }

    this.onClickDownloadCertificate = this.onClickDownloadCertificate.bind(this);
    this.onClickDownloadCourse = this.onClickDownloadCourse.bind(this);
    this.onClickCloseModalRating = this.onClickCloseModalRating.bind(this);
    this.onSubmitRating = this.onSubmitRating.bind(this);
    this.onClickOpenModalRating = this.onClickOpenModalRating.bind(this);
    this.isEvaluable = this.isEvaluable.bind(this);
  }

  isEvaluable() {
    let _this = this;
    if (this.state.offerId) {
      ratingService.getRating(this.state.offerId).then(function(response) {
        if (!response || response.length === 0) {
          _this.setState({buttonRating: true});
        } else {
          _this.setState({buttonRating: false, alreadyEvaluated: true});
        }
      }, function(reason) {
        _this.setState({buttonRating: false});
      });
    }
  }

  onClickOpenModalRating() {
    let showModalRating = this.state.showModalRating;
    if (!showModalRating) {
      this.setState({showModalRating: true});
    }
  }

  onClickCloseModalRating() {
    this.setState({showModalRating: false});
  }

  onSubmitRating(rating) {
    let _this = this;
    let objRating = {
      comment: rating.review,
      score: rating.ratingStars,
      contentOfferId: this.props.offer.treeOffer.id,
      featured: false
    }

    // console.log(rating);
    // console.log(objRating);tabIndexOfferCertificate

    if (objRating.score > 0) {

      ratingService.insert(objRating).then(function(response) {
        _this.setState({
          showModalRating: false
        }, function() {
          _this.isEvaluable();
        });
      }, function(reason) {
        _this.setState({
          showModalRating: false,
          alreadyEvaluated: true
        }, function() {
          _this.setState({error: RDP_CONFIG.defaultReason});
        });
      });
    } else {}
  }

  onClickDownloadCertificate() {
    let _this = this;
    var id = this.props.offer.treeOffer.id;
    var RDP_USER = authenticationService.getLocalUserData();
    let url = RDP_CONFIG.learningURL + 'api/protected/certificates/pagecompliance/content-offer/' + id;
    var fileName = "Certificado_Page_Compliance" + id + ".pdf";

    _this.setState({loading: true});

    if (RDP_Utils.hasCordova()) {
      window.CordovaExec(function(certficatePath) {
        window.CordovaExec(function() {
          _this.setState({loading: false});
        }, function() {
          _this.setState({loading: false});
        }, "ShelfPlugin", "OpenUrl", [certficatePath]);
      }, function() {
        _this.setState({loading: false});
      }, "LearningPlugin", "Request", [
        {
          "url": url,
          "method": "GET",
          "cacheEnabled": true,
          "oneWay": false,
          "resultType": "Path",
          "fileName": fileName
        }
      ]);
    } else {
      var req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.setRequestHeader('Authorization', 'Bearer ' + RDP_USER.authentication_token);
      req.responseType = "blob";

      if (RDP_CONFIG.azureApisHeaderParam !== undefined && RDP_CONFIG.azureApisHeaderParam !== null){
        req.setRequestHeader(Object.keys(RDP_CONFIG.azureApisHeaderParam)[0], Object.values(RDP_CONFIG.azureApisHeaderParam)[0]);
      }

      req.onload = function(event) {
        var blob = req.response;
        var userAgent = window.navigator.userAgent;

        //TODO fallback needed for IE8 & IE9
        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
          //IE 10+
          window.navigator.msSaveBlob(blob, fileName);

          _this.setState({loading: false});
        } else if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
          var file = new Blob([blob], {type: 'application/pdf'});
          var fileURL = window.URL.createObjectURL(file);
          window.location.href = fileURL;
        } else {
          //Firefox, Chrome
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.style = "display: none";
          link.download = fileName;
          link.click();
          document.body.removeChild(link);

          _this.setState({loading: false});
        }
      };

      req.send();
    }
  }

  onClickDownloadCourse() {
    // console.log('onClickDownloadCourse');

    var _this = this;
    var contextId = this.props.offer.treeOffer.offeredContextId;
    var RDP_USER = authenticationService.getLocalUserData();
    var url = RDP_CONFIG.learningURL + 'restapi/api/protected/files/context-id/' + contextId
    var fileName = 'Curso_Page_Compliance_' + contextId + '.pdf';
    var appPath = '';

    var getURL = function() {
      // console.log('getURL');

      return new Promise(function(resolve, reject) {
        var RDP_USER = authenticationService.getLocalUserData();

        // console.log(url);

        RDP_Utils.request({
          url: url,
          type: 'GET',
          xhrType: 'ajax',
          headers: {
            'Authorization': 'Bearer ' + RDP_USER.authentication_token
          }
        }).then(function(response) {
          resolve(response);
        }, function(reason) {
          reject(reason);
        });
      });
    }

    _this.setState({
      loading: true,
      error: false
    }, function() {
      getURL().then(function(response) {
        // console.log('getURL', response);

        appPath = response[0].fullPath;

        // console.log(appPath);

        if (RDP_Utils.hasCordova()) {
          window.CordovaExec(function(appPath) {
            window.CordovaExec(function(OpenUrlResponse) {
              _this.setState({loading: false});
            }, function(reason) {
              _this.setState({loading: false, error: reason});
            }, 'ShelfPlugin', 'OpenUrl', [appPath]);
          }, function(reason) {
            _this.setState({loading: false, error: reason});
          }, 'LearningPlugin', 'Request', [
            {
              'url': appPath,
              'method': 'GET',
              'cacheEnabled': true,
              'oneWay': false,
              'resultType': 'Path',
              'fileName': fileName
            }
          ]);
        } else {
          let _header = {
            'Authorization': 'Bearer ' + RDP_USER.authentication_token
          };
          if (RDP_CONFIG.azureApisHeaderParam !== undefined && RDP_CONFIG.azureApisHeaderParam !== null){
            Object.assign(_header, RDP_CONFIG.azureApisHeaderParam);
          }
          $.ajax({
            url: url,
            type: 'GET',
            headers: _header
          }).then(function(response) {
            var userAgent = window.navigator.userAgent;
            if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
              window.location.href = response[0].fullPath;
            } else {
              window.open(response[0].fullPath, '_blank');
            }
            _this.setState({loading: false});
          }, function(reason) {
            _this.setState({loading: false, error: reason});
          });
        }
      }, function(reason) {
        _this.setState({loading: false, error: reason});
      });
    });
  }

  render() {
    // console.log('CourseAbout', 'render', this.props.offer);

    const defaultVideo = "";

    if (this.props.offer) {

      const {offer} = this.props;

      var offerDuration,
        offerAbout,
        offerInformation,
        offerCertificate,
        //tabIndexOfferCertificate,
        offerCategory = [],
        offerType,
        tabIndexOfferType,
        offerRating = <span className="rdp-course-rating rdp-information-item" onClick={this.onClickOpenModalRating}>Avaliar curso</span>,
        offerEvaluated = <span className="rdp-course-evaluated rdp-information-item">Curso já avaliado</span>

      // Sobre
      if (offer.about) {
        offer.videoUrl = offer.videoUrl && offer.videoUrl !== ''
          ? offer.videoUrl
          : defaultVideo;
        offerAbout = (<div className="rdp-course-about">
          <h3 tabIndex={0} className="rdp-title-page">Sobre este curso</h3>

          <div className="rdp-course-frame">
            <span className="rdp-course-subtitle">Informações</span>
            <p tabIndex={0}>{offer.about}</p>

            <span className="rdp-course-subtitle">Carga horária</span>
            <p tabIndex={0}>{offer.workload} horas</p>

            <span className="rdp-course-subtitle">Certificado</span>
            <p tabIndex={0}>Sim</p>
          </div>

          <div className="rdp-course-frame">
            <CardCourse tabIndex="0" key="0" offer={offer} indexProgress={true}/>
          </div>

          {/* {
            RDP_Utils.onLine()
              ? (<div className="rdp-wrap-video">
                <video tabIndex={0} aria-label="Vídeo demonstrativo com capturas das telas do curso" controls="controls" poster={offer.videoPoster || defaultPoster}>
                  <source src={offer.videoUrl} type="video/mp4"/>
                </video>
              </div>)
              : null
          } */}

        </div>);
      }

      // Informações
        // Duração
        if (offer.workload) {
          offerDuration = <span className="rdp-course-time rdp-information-item">{offer.workload}h</span>;
        }

        if ((offer.progress * 100) >= RDP_CONFIG.offerProgressToDone) {
          if (RDP_Utils.onLine()) {
            if (!this.state.offerId) {
              let _this = this;
              this.setState({
                offerId: offer.treeOffer.id
              }, function() {
                _this.isEvaluable();
              });
            }
            offerCertificate = <button className="rdp-course-certificate rdp-information-item" onClick={this.onClickDownloadCertificate} aria-label="Baixe o certificado deste curso">Baixar certificado</button>;
            //tabIndexOfferCertificate = 0;
          }
        } else {
          offerCertificate = null;
          //tabIndexOfferCertificate = -1;
        }

        let listDescCategory = 'Este curso pertence às áreas: '
        if (offer.categories && offer.categories.length > 0) {

          offerCategory = offer.categories.map(function(category, index) {
            var bgStyle = {
              backgroundColor: category.color
            };
            listDescCategory = listDescCategory + category.name
            if (index < (offer.categories.length - 2)) {
              listDescCategory = listDescCategory + ', '
            } else {
              if (index < (offer.categories.length - 1)) {
                listDescCategory = listDescCategory + ' e '
              }
            }
            return <span aria-label={category.name} key={index} className="rdp-course-category rdp-information-item rdp-category" style={bgStyle}>
              {category.name}
            </span>;
          });
        }

        if (offer.type) {
          offerType = <span className="rdp-course-type information-item">{offer.type}</span>;
          tabIndexOfferType = 0;
        }else{
          tabIndexOfferType = -1;
        }

        let descCargaHoraria = offer.workload > 1 ? ' horas' : ' hora'
        let descCurso = "A carga horária desse curso é de " + offer.workload + descCargaHoraria

        offerInformation = (<div className="rdp-course-informations">
          {/* <span tabIndex="0" className="rdp-category-group">{listDescCategory}</span> */}
          {/* <span tabIndex="-1">{offerCategory}</span> */}
          {/* <span tabIndex="0" className="rdp-category-group">{descCurso}</span> */}
          {/* <span tabIndex="-1">{offerDuration}</span> */}
          {/* <span tabIndex={tabIndexOfferType} aria-label="Tipo deste curso">{offerType}</span> */}
          <span>{offerCertificate}</span>
          {/* <button tabIndex="0" aria-label="Baixe a versão do curso em PDF" className="rdp-course-download rdp-information-item" onClick={this.onClickDownloadCourse}>Baixar curso em PDF</button> */}
          {/* {
            this.state.buttonRating
              ? offerRating
              : null
          }
          {
            this.state.alreadyEvaluated
              ? offerEvaluated
              : null
          } */}
        </div>);

      let loading;

      if (this.state.loading) {
        loading = <Loader/>;
      }

      if (offerInformation || offerAbout) {
        return (<section className="rdp-course-about rdp-container">
          {
            this.state.error
              ? <ErrorDialog error={this.state.error}/>
              : null
          }
          {loading}
          {offerInformation}
          {offerAbout}
          {
            this.state.showModalRating
              ? <ModalRating onClickCloseModalRating={this.onClickCloseModalRating} onSubmitRating={this.onSubmitRating} view="home"/>
              : null
          }
        </section>);
      } else {
        return null;
      }
    }
  }
}
