import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter as Router} from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import {RDP_Utils} from './utils';

window.online = true;

function init() {
  // console.log('init');
  ReactDOM.render(<Router><App/></Router>, document.querySelector('#main-root'));
}

RDP_Utils.AppReady().then(function(response) {
  init();
}, function(reason) {
// console.log(reason);
});

registerServiceWorker();
