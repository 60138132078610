/*global CordovaExec*/
import React, {Component} from 'react';
import {RDP_Utils} from '../../utils';
// import $ from 'jquery';

import './player.css';

import {Loader, ErrorDialog, Header} from '../../components';

export class ViewPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false
    }

    this.backToCourseDetails = this.backToCourseDetails.bind(this);
  }

  componentDidMount() {
    if (RDP_Utils.inApp() && RDP_Utils.hasCordova()) {
      let setup = {
        'margin_top': 44
      };
      // Header
      CordovaExec(function setup() {}, "LearningPlayer", "Setup", [setup]);
    // console.log("LearningPlayer", "Setup", [setup], "HEADER");
    }
  }

  backToCourseDetails(event) {
  // console.log('backToCourseDetails', event.currentTarget);

    if (RDP_Utils.inApp()) {
      CordovaExec(function(status) {

      }, 'ReaderPlugin', 'BackToShelf');
    }
  }

  render() {
    let loader,
      error;

    if (this.state.loading) {
      loader = <Loader/>;
    }

    if (this.state.error) {
      error = <ErrorDialog error={this.state.error}/>;
    }

    return (<div className="rdp-view rdp-view-player">
      {error}
      {loader}
      <Header className="rdp-header-edition-app" noBgTransition={true} onClickBack={event => this.backToCourseDetails(event)} buttonBack={true} fullWidth={true} headerOnEdition={true}/>
    </div>);
  }
}
