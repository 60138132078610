import {mockService} from './mock';

var Storage = function() {

  this.filePost = function(params) {
  // console.log('filePost', params);

    return new Promise(function(resolve, reject) {
      resolve(mockService.file());
    });
  }

  return this;
}

var fileService = new Storage();

export {
  fileService
};
