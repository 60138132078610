import React, { Component } from 'react';

import './students-reviews.css';
import defaultImageAvatar from './generic-avatar.png';

export default class StudentsReviews extends Component {
  constructor(props){
    super(props);

    this.state = {
      review: this.props.review
    }

    // console.log('StudentsReviews', 'constructor', this);
  }

  componentDidUpdate(prevProps) {
    console.log(this.props)
    if (this.props.review !== prevProps.review) {
      this.setState({
        review: this.props.review
      })
    }
  }

  render() {
    // console.log('StudentsReviews', 'render', this);

    let {
      userName,
      userAvatar,
      userDepartment,
      offerName,
      comment,
      socialName
    } = this.state.review;

    // let image = userAvatar ? userAvatar : GenericAvatar;

    // console.log("socialName: ", socialName);
    // console.log("userName: ", userName);
    // console.log("comment: ", comment);

    if(!userAvatar) {
      userAvatar = defaultImageAvatar;
    }

    if((socialName || userName) && comment && userAvatar){
      let name;

      if(socialName) {
        name = socialName;
      } else {
        name = userName;
      }

      return (
        <div aria-label="Avaliação de cursos por usuários" className="rdp-students-reviews">
          <h3 className="rdp-h3">O que estão falando</h3>
          <h1 className="rdp-h1">Sobre os nossos cursos</h1>
          <div className="rdp-students-reviews-container">
            <div className="rdp-container">
              <div className="rdp-img-losange">
                <img className="rdp-student-avatar" src={userAvatar} alt={name} />
              </div>
              <div tabIndex="0" className="rdp-wrap-info">
                <h4 className="rdp-student-name">
                  {userName}
                </h4>
                <h4 className="rdp-student-offer-name">
                  {offerName}
                </h4>
                <p className="rdp-review">{comment}</p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null
    }
  }
}
