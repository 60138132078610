import React, {Component} from 'react';
import './card-certificate.css';
import imageCertificate from './certificado-modelo-teste.png';

export default class CardCertificate extends Component {
  render() {
    return (
      <div className="rdp-card-certificate">
        <div className="rdp-certificate-container">
          <img className="rdp-certificate-img" src={imageCertificate} alt="Certificado de conclusão de curso."/>
          <div className="rdp-hover">
            <p className="rdp-hover-text rdp-ico-square-download">Download</p>
          </div>
        </div>
        <p className="rdp-course-title">PSG: Nome do curso</p>
      </div>
    );
  }
}
