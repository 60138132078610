import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import {
  passwordService,
  registerService
} from '../../services';

import {
  ButtonArrow,
  ErrorDialog,
  FormGroup,
  Loader
} from '../../components';

export class ViewResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      messageFeedback: false,
      invalidToken: false
    }

    this.onSubmit = this.onSubmit.bind(this);

    this.urlToken = '';
  }

  componentWillMount() {
    document.title = "Esqueci minha senha - Page Compliance";

    var _this = this;
    _this.verifyToken();
  }

  verifyToken() {
    var _this = this;

    _this.urlToken = window.location.hash.substring(23);

    var params = { token: _this.urlToken };

    registerService.getFirstAccess(params).then(function(response) {
      // // console.log(response);
    }, function(reason) {
      _this.setState({ invalidToken: true });
    });
  }

  onSubmit(event) {
    event.preventDefault();

    var _this = this,
        params = event.target.toJSON();

    if (params['password'] === params['repeat-password']) {
      const ValidParams = {
        password: params.password,
        token: _this.urlToken
      };

      _this.setState({
        loading: true,
        error: false
      }, function() {
        passwordService.reset(ValidParams).then(function(response) {
          _this.setState({
            loading: false,
            messageFeedback: true
          });
        }, function(reason) {
          _this.setState({
            loading: false,
            error: reason
          });
        });
      });
    } else {
      _this.setState({
        error: { custom_message: 'Senhas não são iguais' }
      });
    }
  }

  render() {
    var loader, content, error;

    var { messageFeedback, invalidToken } = this.state

    if (loader) {
      loader = <Loader/>;
    }

    if (this.state.error) {
      error = <ErrorDialog top="top" show="true" error={this.state.error} />;
    }

    if (!messageFeedback && !invalidToken) {
      content = (
        <Fragment>
          {error}
          {loader}
          <form className="rdp-form-reset-password" onSubmit={this.onSubmit}>
            <div className="fieldset">
              <div className="legend">Digite a sua nova senha e confirme</div>

              <FormGroup
                formGroupClass="rdp-form-group"
                labelFor="password"
                labelClass="rdp-sr-only"
                labelText="Digite sua nova senha"
                inputName="password"
                inputType="password"
                inputClass="rdp-form-control"
                placeholder="Digite sua nova senha"
                autoComplete="new-password"
                required
              />

              <FormGroup
                formGroupClass="rdp-form-group"
                labelFor="repeat-password"
                labelClass="rdp-sr-only"
                labelText="Digite sua nova senha novamente"
                inputName="repeat-password"
                inputType="password"
                inputClass="rdp-form-control"
                placeholder="Digite sua nova senha novamente"
                autoComplete="new-password"
                required
              />

              <div className="rdp-form-group rdp-form-actions">
                <ButtonArrow
                  type="submit"
                  className="rdp-btn-arrow-orange"
                  textButton="Enviar"
                />
              </div>

            </div>
          </form>
        </Fragment>
      );
    } else if (messageFeedback && !invalidToken) {
      content = (
        <p className="rdp-message-feedback">
          Senha alterada com sucesso! <br/>
          <Link to="/login" className="rdp-back-to-login">Voltar pra login</Link>
        </p>
      );
    } else if (!messageFeedback && invalidToken) {
      content = (
        <p className="rdp-message-feedback">
          Token inválido :( <br/>
          <Link to="/login" className="rdp-back-to-login">Voltar pra login</Link>
        </p>
      )
    }

    return (
      <div className="rdp-view rdp-view-reset-password rdp-bg-squares">
        {content}
      </div>
    );
  }
}
