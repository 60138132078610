import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import './support.css';

import {authenticationService, supportService, userService} from '../../services';

import {RDP_Utils} from '../../utils';

import {
  CustomInputFile,
  ButtonArrow,
  Loader,
  // Header,
  HeaderV2,
  ErrorDialog,
  SuccessDialog,
  Navigation,
  Footer
} from '../../components';

export class ViewSupport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      success: false,
      currentUser: {},
      formFields: {},
      areasList: [],
      issuesList: [],
      selectedArea: {},
      selectedIssue: {},
      textOption: '',
      issueTypeId: '',
      authed: authenticationService.getLocalUserData()
    }

    this.image = '';
    this.imageName = '';
    this.currentUser = {};
    this.file = {};

    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSelectArea = this.onSelectArea.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.onSelectIssue = this.onSelectIssue.bind(this);
    this.onHandlerCloseSuccessDialog = this.onHandlerCloseSuccessDialog.bind(this);
  }

  componentWillMount() {
    if (this.state.authed) {
      this.getAreaList();
      this.getUser();
    }
  }

  componentDidMount() {
    document.title = "Suporte - Page Compliance";

    this.scrollToTop();
  }

  scrollToTop() {
    $('html, body').scrollTop(0);
  }

  getUser() {
    var _this = this;
    userService.getCurrent().then(function(currentUser) {
      _this.currentUser = currentUser;
      _this.setState({currentUser: currentUser});
    }, function(reason) {
      _this.errorFormFields(reason);
    });
  }

  onImageChange(inputFile) {
    var _this = this;
    _this.imageName = inputFile.files[0].name;
    _this.file = inputFile.files[0];

    userService.convertFileInputToBase64(inputFile).then(function(response) {
      _this.image = response;
    });
  }

  getAreaList() {
    var _this = this;

    _this.setState({
      loading: true
    }, function() {
      supportService.getAreaList().then(function(response) {
        _this.setState({areasList: response._embedded.supportAreas, loading: false});
      }, function(reason) {
        _this.errorFormFields(reason);
      });
    });
  }

  errorFormFields(error) {
    var _this = this;
    _this.setState({loading: false, error: error});
  }

  onSubmitForm(event) {
    //// console.log(event);
    event.preventDefault();
    var _this = this;
    var _formToReset = event.target;
    var formSupport = event.target;

    // console.log('formSupport =>', formSupport);
    // let params;
    // let params = event.target.toJSON();

    // delete params.setproblem;
    // delete params.issueTypeId;

    // params.name = _this.currentUser.name;
    // params.email = _this.currentUser.email;
    // params.cpf = _this.currentUser.cpf;
    // params.department = _this.currentUser.department;
    // params.issueTypeId = this.state.selectedIssue.id;
    // params.businessPhone = _this.currentUser.businessPhone;
    // params.mobilePhone = _this.currentUser.mobilePhone;
    // params.file = _this.file;

    if (RDP_Utils.isValid(formSupport)) {
      _this.setState({
        loading: true,
        error: false,
        success: false
      }, function() {
      // console.log(formSupport);
        supportService.sendSuppotRequest(formSupport).then(function(ticketID) {
          //// console.log(ticketID);

          _formToReset.reset();
          _this.setState({
            loading: false,
            error: false,
            success: {
              message: 'Solicitação nº ' + ticketID + ' criada com sucesso!'
            }
          });
        }, function(reason) {
          _this.errorFormFields(reason);
        });
      });
    } else {
      _this.errorFormFields({message: 'Os campos obrigatórios devem ser preenchidos corretamente'});
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onSelectArea(event) {
    var _this = this;
    var id = event.target.value;

    var filteredAreas = _this.state.areasList.filter(function(item) {
      return Number(id) === Number(item.id);
    });

    var area = filteredAreas.length > 0
      ? filteredAreas[0]
      : undefined;

    if (!!area) {
      _this.setState({textOption: 'Aguarde, carregando opções'});
      supportService.getIssueTypesByArea(area).then(function(response) {
        _this.setState({selectedArea: area, issuesList: response._embedded.issueTypes, textOption: 'Selecione uma opção'});
      }, function(reason) {
        _this.setState({textOption: ''});
        _this.errorFormFields(reason);
      });
    } else {
      _this.setState({issueList: []});
    }
  }

  onSelectIssue(event) {
    var id = event.target.value;
    var filteredIssues = this.state.issuesList.filter(function(item) {
      return Number(id) === Number(item.id);
    });
    var issue = filteredIssues.length > 0
      ? filteredIssues[0]
      : undefined;
    this.setState({selectedIssue: issue, issueTypeId: issue.id});
  }

  onHandlerCloseSuccessDialog() {
    this.setState({success: false});
  }

  render() {
    var error,
      loader,
      success,
      modalInfo,
      areaOptions = [],
      issueOptions = [];

    if (this.state.error) {
      error = <ErrorDialog show="true" error={this.state.error}/>;
    }

    if (this.state.success) {
      success = <SuccessDialog func={this.onHandlerCloseSuccessDialog} show="true" success={this.state.success}/>;
    }

    if (!this.state.authed) {
      return <Redirect to="/login"/>;
    }

    if (this.state.loading) {
      loader = <Loader/>;
    }

    if (this.state.areasList) {
      areaOptions = this.state.areasList.map(function(option) {
        // console.log('OPTION =>', option);
        return (<option key={option.id} value={option.id}>{option.supportAreaName}</option>);
      });
    }

    if (this.state.issuesList) {
      issueOptions = this.state.issuesList.map(function(option) {
        // console.log('OPTION =>', option);
        return (<option key={option.id} value={option.id}>{option.issueTypeName}</option>);
      });
    }

    return (<section className="rdp-view rdp-view-support">
      {error}
      {success}
      {loader}
      {/* <Header noBgTransition="noBgTransition"/> */}
      <HeaderV2 navigation={<Navigation/>} label="Ajuda"/>
      <div className="rdp-main">
        <div className="rdp-container">

          {/* <h3 className="rdp-title-page rdp-title-left">Suporte</h3> */}
          <p className="rdp-description-support">
            Mantenha os seus dados atualizados em &nbsp;<a href="#/profile">Meu perfil</a>&nbsp; para facilitar o contato da nossa equipe de suporte.
          </p>

          <form className="rdp-form-support" onSubmit={this.onSubmitForm}>
            <input type="hidden" name="name" defaultValue={this.state.currentUser.name}/>
            <input type="hidden" name="email" defaultValue={this.state.currentUser.email}/>
            <input type="hidden" name="cpf" defaultValue={this.state.currentUser.cpf}/>
            <input type="hidden" name="department" defaultValue={this.state.currentUser.department}/>
            <input type="hidden" name="businessPhone" defaultValue={this.state.currentUser.businessPhone}/>
            <input type="hidden" name="mobilePhone" defaultValue={this.state.currentUser.mobilePhone}/>
            <input type="hidden" name="issueTypeId" value={this.state.issueTypeId}/>

            <div className="fieldset">
              <div className="legend"></div>

              <div className="rdp-form-group rdp-col-3">
                <label htmlFor="issueTypeId">Indique a área de suporte*</label>
                <div className="rdp-custom-select">
                  <select id="fakeIssueTypeId" name="fakeIssueTypeId" aria-required="true" required="required" onChange={this.onSelectArea}>
                    <option value="" disabled selected>Selecione</option>
                    {areaOptions}
                  </select>
                </div>
              </div>

              <div className="rdp-form-group rdp-col-3 no-margin">
                <label htmlFor="setproblem">Indique o tipo de problema*</label>
                <div className={`rdp-custom-select ${this.state.issuesList.length > 0
                    ? ''
                    : 'rdp-disabled'}`}>
                  <select id="setproblem" name="setproblem" aria-required="true" required="required" onChange={this.onSelectIssue}>
                    <option value="" disabled selected>{this.state.textOption}</option>
                    {issueOptions}
                  </select>
                </div>
              </div>

              <div className="rdp-form-group">
                <label htmlFor="description">Descreva o problema*</label>
                <textarea id="description" className="rdp-form-control" name="description" placeholder="..." aria-label="Descreva o problema" aria-required="true" required="required"/>
              </div>

              <div className="rdp-form-group">
                <CustomInputFile onImageChange={this.onImageChange}/>
              </div>

              <div className="rdp-form-group rdp-form-actions">
                <ButtonArrow className="rdp-btn-arrow-orange" textButton="Enviar" ariaLabel="Envie as informações para o canal suporte" type="submit"/>
              </div>

            </div>
          </form>
        </div>
        {/* <Navigation/> */}
      </div>
      <Footer/> {modalInfo}
    </section>);
  }
}
