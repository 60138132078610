import packageJSON from '../package.json';

var redirect = false;
if (window.location.hostname === '127.0.0.1' ||
  window.location.hostname === 'localhost' ||
  window.location.hostname.indexOf('192.168.0.') > -1) {
  redirect = false
}

if (redirect) {
  if (window.location.protocol !== 'https:') {
    const httpsURL = 'https://' + window.location.href.split('//')[1]
    window.location.replace(httpsURL)
  }
}

var RDP_CONFIG = {}

RDP_CONFIG.version = packageJSON.version;
RDP_CONFIG.version_date = '10/04/2019 16:50';
RDP_CONFIG.learningURL = '';
RDP_CONFIG.learningURLProd = 'https://api.digitalpages.com.br/manager/';
RDP_CONFIG.learningURLDev = 'https://api.digitalpages.com.br/manager/';
RDP_CONFIG.learningURLHomolog = 'https://api.digitalpages.com.br/manager/';
RDP_CONFIG.azureApisHeaderParam = undefined;

var DEVOPS_TOKEN = "#{APITOKEN}#";
var API_KEY = "6b4aa3ebfa884107a52adf25c66aee10";
RDP_CONFIG.mode = "PRD";

if (DEVOPS_TOKEN.indexOf("#{") == -1) {
  RDP_CONFIG.mode = "UAT";
  API_KEY = DEVOPS_TOKEN;
}
RDP_CONFIG.API_KEY = API_KEY;
RDP_CONFIG.learningURL = RDP_CONFIG.learningURLProd;
RDP_CONFIG.azureApisHeaderParam = {
  // 'Ocp-Apim-Subscription-Key': '7bd3444ee3ea4eb6be8c166fa27399d5' //pocs-prod
  'Ocp-Apim-Subscription-Key': API_KEY
}

RDP_CONFIG.clientName = 'hagecompliance';

RDP_CONFIG.offerProgressToDone = 100;

RDP_CONFIG.alwaysResolve = true;
RDP_CONFIG.showServiceError = false;

RDP_CONFIG.publicToken = {
  'Authorization': `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZW5hbnRfaWQiOiJzZW5hY2RuIiwidXNlcl9pZCI6NiwidXNlcl9uYW1lIjoiYWx1bm8ucWEiLCJzY29wZSI6WyJST0xFX0xFQVJORVIiXSwicm9sZV90aXRsZSI6IkFsdW5vIiwiYXZhdGFyIjoiaHR0cHM6Ly9wbGF0cG9jc2RvY2tlci5ibG9iLmNvcmUud2luZG93cy5uZXQvc2VuYWNkbi9hdmF0YXIvNi9hdmF0YXJfNiIsImF1dGhvcml0aWVzIjpbIlJPTEVfTEVBUk5FUiJdLCJqdGkiOiI5MDU3Mjg2MS0xYmFmLTQyNzQtYTExMy03ZGY4YzhjYjE5ZTMiLCJjbGllbnRfaWQiOiJjbGllbnQtcmVhZCJ9.EA3Bqz12bRm63a4o6El2dnAAODqH0TMxRWL1ODHM1kss3qTZ4LY6ts9RL4vpZXHwbXnDFBHaXpOm9fYiyojh0IuPyYiB5HZ_5HpaJP18BDRhMiKOdDR1UxsBAEe3BU0OlPfWwBRAQ19ZmedKMKy0cJsSmF2TT7LFx7_joV9Rcz5h4KUHc0zyfQPHFxLEepA7-7uTvyheTlIT4zNreA0pW4dtV_VJnt6bmeLPZfeKbXzFFQr0jHNle0ir0HV6kk1yhy7rYjFqf2iQuveO6WersgZsL7kVYNTL65P09uappEVrStb39O_li-vyT2X3k-WRbrFnIM6TA_DDC9asZBzjJA`
};

RDP_CONFIG.defaultReason = {
  custom_message: 'Ops, aconteceu algum erro'
};

export {
  RDP_CONFIG
};
