import React, { Component } from 'react';
import $ from 'jquery';
import './faq.css';
import Banner from './banner.jpg';

import {
  faqService
} from '../../services';

import {
  // Header,
  HeaderV2,
  Footer,
  Navigation,
  ErrorDialog,
  FaqQuestion,
  Loader
} from '../../components';

export class ViewFaq extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      questionOpened: false,
      faqList: []
    }
  }

  onClickQuestion() {
    this.setState({ questionOpened: !this.state.questionOpened });
  }

  componentWillMount() {
    this.getFAQ();
  }

  componentDidMount() {
    document.title = "FAQ - Page Compliance";

    this.scrollToTop();
  }

  scrollToTop() {
    $('html, body').scrollTop(0);
  }

  getFAQ() {
    var _this = this;

    _this.setState({ loading: true }, function() {
      faqService.getFAQ().then(function(faqList) {
        _this.setState({
          faqList: faqList,
          loading: false
        });
      }, function(reason) {
        _this.setState({
          loading: false,
          error: reason
        });
      });
    });
  }

  render() {
    var loader, error;

    const questions = this.state.faqList.map(function(question) {
      return (
        <FaqQuestion
          key={question.id}
          question={question.title}
          answer={question.answer}
        />
      );
    });

    if (this.state.loading) {
      loader = <Loader/>;
    }

    if (this.state.error) {
      error = <ErrorDialog error={this.state.error} />;
    }

    return (
      <div className="rdp-view-faq">
        {error}
        {loader}
        {/* <Header noBgTransition/> */}
        <HeaderV2 navigation={<Navigation/>} label="Perguntas frequentes" image={Banner}/>
        <div className="rdp-main">
          <section className="rdp-faq-content">
            <div className="rdp-container">
              {/* <h3 className="rdp-title-page rdp-title-left">Perguntas frequentes</h3> */}
              <p aria-hidden="false" className="rdp-faq-description">
                Tire suas dúvidas relacionadas à Page Compliance. Se não encontrou resposta para a sua dúvida, entre em contato com a nossa equipe de suporte.
              </p>
              {questions}
            </div>
          </section>
          
        </div>
        <Footer/>
      </div>
    );
    }
  }
