import React, {Component} from 'react';
import './success-dialog.css';

export default class SuccessDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      top: this.props.top
        ? 'rdp-on-top'
        : ''
    }

    this.onClickDialog = this.onClickDialog.bind(this);
  }

  onClickDialog() {
    this.props.func();
  }

  componentDidUpdate(prevProps, prevState) {

    // if (!prevState.show && !this.state.show && prevProps.show) {
    //   let state = {
    //     show: true
    //   }
    //
    //   this.setState(state);
    // }
  }

  render() {
    return (<div className={`rdp-success-dialog ${this.state.top}`} onClick={this.onClickDialog}>
      <div tabindex={0} role="alert" className="rdp-success-dialog-content rdp-container">
        <p className="rdp-success-dialog-text">
          {this.props.success.message}
          <button type="button" className="rdp-close rdp-ico-close" aria-label="fechar alerta">
            <span>fechar alerta</span>
          </button>
        </p>
      </div>
    </div>);
  }
}
