import { RDP_Utils } from '../utils';
import { RDP_CONFIG } from '../config';
import { authenticationService } from './authentication';
// import {mockService} from './mock';

var User = function () {
  // var _this = this;

  this.getMaritalStatusList = function () {
    // console.log('getMaritalStatusList');
    let RDP_USER = authenticationService.getLocalUserData();

    return new Promise(function (resolve, reject) {
      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/enums/maritalStatus',
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function (response) {
        resolve(response);
      }, function (reason) {
        reject(reason);
      });
    });
  }

  this.getInterestedItemsList = function () {
    // console.log('getInterestedItemsList');
    let RDP_USER = authenticationService.getLocalUserData();

    return new Promise(function (resolve, reject) {
      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/enums/interestedItems',
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function (response) {
        resolve(response);
      }, function (reason) {
        reject(reason);
      });
    });
  }

  this.getGenderList = function () {
    // console.log('getGenderList');
    let RDP_USER = authenticationService.getLocalUserData();

    return new Promise(function (resolve, reject) {
      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/enums/gender',
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function (response) {
        resolve(response);
      }, function (reason) {
        reject(reason);
      });
    });
  }

  this.getEthnicityList = function () {
    // console.log('getEthnicityList');
    let RDP_USER = authenticationService.getLocalUserData();

    return new Promise(function (resolve, reject) {
      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/enums/ethnicity',
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function (response) {
        resolve(response);
      }, function (reason) {
        reject(reason);
      });
    });
  }

  this.getSchoolingList = function () {
    // console.log('getSchoolingList');
    let RDP_USER = authenticationService.getLocalUserData();

    return new Promise(function (resolve, reject) {
      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/enums/schooling',
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function (response) {
        resolve(response);
      }, function (reason) {
        reject(reason);
      });
    });
  }

  this.getCurrent = function () {
    // console.log('getCurrent');
    return new Promise(function (resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/user-profile/me',
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function (response) {
        resolve(response);
      }, function (reason) {
        reject(reason);
      });
    });
  }

  this.saveCurrent = function (params) {
    // console.log('saveCurrent', params);

    if (!!params.avatar && params.avatar.substring(0, 4) === 'data') {
      params.avatar = params.avatar.substring(params.avatar.indexOf(',') + 1);
    }

    // console.log('saveCurrent params.avatar: ', params.avatar);


    return new Promise(function (resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/user-profile/me',
        type: 'PATCH',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(params)
      }).then(function (response) {
        resolve(response);
      }, function (jqXHR) {
        var reason;

        if (jqXHR.responseJSON) {
          reason = jqXHR.responseJSON;
        } else {
          reason = RDP_CONFIG.defaultReason;
        }

        reject(reason);
      });
    });
  }

  this.convertFileInputToBase64 = function (inputFile) {
    // console.log('convertFileInputToBase64', inputFile);

    return new Promise(function (resolve, reject) {
      let file = inputFile && inputFile.files && inputFile.files[0]
        ? inputFile.files[0]
        : undefined;

      if (!!file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function (error) {
          reject(error);
        };
      } else {
        reject('inputFile.files[0] not found');
      }
    });
  }

  return this;
}

var userService = new User();

export {
  userService
};
