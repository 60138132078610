import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';

import './button-arrow.css';

export default class ButtonArrow extends Component {
  render() {
    const {
      address,
      back,
      className,
      tag,
      textButton,
      type,
      large,
      onClick,
      tabIndex,
      ariaLabel,
      id
    } = this.props;

    let buttonComponent,
      width,
      height,
      buttonClass,
      lineSVG;

    const accessibilityContentSVG = (<Fragment>
      <desc>Contorno do botão para {textButton}</desc>
    </Fragment>);

    if (large) {
      width = 259;
      height = 56;
      buttonClass = `${className} rdp-btn-large`;
      lineSVG = (<svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} version="1.1" xmlns="http://www.w3.org/2000/svg">
        {accessibilityContentSVG}
        <g className="rdp-svg-line" fill="none">
          <polyline points="209.889972 0.8906 0 0.8906 0 53.7696 256.612 53.7696 256.612 0.8906 219.552769 0.8906 219.552769 5.7656"/>
        </g>
      </svg>);
    } else {
      width = 112;
      height = 38;

      if (back) {
        buttonClass = `${className} rdp-btn-back`;
        lineSVG = (<svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
          {accessibilityContentSVG}
          <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 111 1)">
            <path d="m72.1235503.58293818h-71.54491125v34.61170912h109.16881695v-34.61170912h-24.0008288v3.19090909"/>
          </g>
        </svg>);
      } else {
        buttonClass = className;

        lineSVG = (<svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
          {accessibilityContentSVG}
          <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <path d="m72.1235503.58293818h-71.54491125v34.61170912h109.16881695v-34.61170912h-24.0008288v3.19090909"/>
          </g>
        </svg>);
      }
    }

    const contentButton = (<Fragment>
      {/* {lineSVG} */}
      <span>{textButton}</span>
    </Fragment>);

    if (tag === 'link') {
      buttonComponent = <Link data-component-name="button-arrow" tabIndex={tabIndex} aria-label={ariaLabel} to={address} className={buttonClass}>{contentButton}</Link>;
    } else {
      if (onClick) {
        buttonComponent = <button data-component-name="button-arrow" tabIndex={tabIndex} aria-label={ariaLabel} onClick={onClick} type={type} className={buttonClass} id={id} >{contentButton}</button>;
      } else {
        buttonComponent = <button data-component-name="button-arrow" tabIndex={tabIndex} aria-label={ariaLabel} type={type} className={buttonClass} id={id}>{contentButton}</button>;
      }

    }

    return buttonComponent;
  }
};
