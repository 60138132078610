/*global CordovaExec*/
import {RDP_Utils} from '../utils';

var Network = function() {
  this.getStatus = function() {
    return new Promise(function(resolve, reject) {
      if (RDP_Utils.hasCordova()) {
        CordovaExec(function(status) {
        // console.log("ShelfPlugin", "NetworkStatus", "status:", status);
          resolve(status);
        }, function(error) {
        // console.log("ShelfPlugin", "NetworkStatus", "error:", error);
          reject(error);
        }, "ShelfPlugin", "NetworkStatus");
      } else {
        resolve(true);
      };
    });
  }

  return this;
}

var networkService = new Network();

export {
  networkService
}
