import {RDP_Utils} from '../utils';
import {RDP_CONFIG} from '../config';

var ForgotPassword = function() {
  this.sendToEmail = function(params) {
  // console.log('sendToEmail', params);

    return new Promise(function(resolve, reject) {
      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/email/forgotPassword',
        type: 'GET',
        xhrType: 'ajax',
        data: params
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reason = {
          custom_message: `Email ${params.email} não encontrado.`
        }

        reject(reason);
      });
    });
  }

  return this;
}

var forgotPasswordService = new ForgotPassword();

export {
  forgotPasswordService
};
