import React, {Component} from 'react';
import './error-dialog.css';

export default class ErrorDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show || true,
      text: this.getMessage(this.props.error)
    }

    this.onClickDialog = this.onClickDialog.bind(this);
  }

  getMessage(error){
    let msg = error.custom_message || error.message || 'Ops, aconteceu um erro';

    if(
      msg === 'No message available' ||
      msg === 'GENERAL'
    ){
      msg = 'Ops, aconteceu um erro';
    }

    return msg;
  }

  onClickDialog() {
    var _this = this;

    _this.setState({show: false});
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('componentDidUpdate');

    if (!prevState.show && !this.state.show && prevProps.show) {
      this.setState({show: true});
    }
  }

  componentDidMount() {
    if (typeof this.props.error === 'string') {
      this.setState({text: this.getMessage(this.props.error)});
    }
  }

  render() {
    // console.log('ErrorDialog', 'render', this);
    if (this.state.show) {
      return (
        <div className={`rdp-error-dialog ${this.props.top ? 'rdp-on-top' : ''}`} onClick={this.onClickDialog}>
          <div tabIndex={0} role="alert" className="rdp-error-dialog-content rdp-container">
            <p className="rdp-error-dialog-text">
              {this.state.text}
              <button type="button" className="rdp-close rdp-ico-close" aria-label="fechar alerta">
                <span>fechar alerta</span>
              </button>
            </p>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
