import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';

import { RDP_CONFIG } from '../../config';
import { RDP_Utils } from '../../utils';

import { offerService, authenticationService, ratingService } from '../../services';

import './course.css';

import {
  ButtonArrow,
  CourseMainSection,
  CourseAbout,
  CourseReviews,
  SliderCourses,
  Footer,
  // Header,
  HeaderV2,
  Navigation,
  Modal,
  Loader,
  ErrorDialog
} from '../../components';

export class ViewCourse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      offer: {},
      reviews: null,
      suggestions: [],
      featurePage: 0,
      featureLastPage: null,
      featureSize: 3,
      modalSubscribeOpened: false,
      user: authenticationService.getLocalUserData(),
      scrolled: false,
      redirectTo: null,
      documentHidden: document.hidden,
      setAriaButton: false
    }

    this.toggleModalSubscribe = this.toggleModalSubscribe.bind(this);
    this.updateButton = this.updateButton.bind(this);
    this.openOffer = this.openOffer.bind(this);
    this.onClickShowMore = this.onClickShowMore.bind(this);

    var _this = this;

    var updateDocumentHidden = function () {
      // console.log('updateDocumentHidden', _this.state.documentHidden, !document.hidden);

      if (_this.state.documentHidden !== document.hidden) {
        if (_this.state.documentHidden && !document.hidden) {
          _this.getOffer(_this.props.match.params);
        }

        _this.setState({ documentHidden: document.hidden });
      }
    }

    document.addEventListener("visibilitychange", updateDocumentHidden);

    this.refContent = React.createRef();

  }

  getActivitiesHistory(params) {
    var _this = this;

    offerService.getLastAccessedActivity(params).then(function (response) {
      // console.log(response)
      _this.setState({ review: response });
    }, function (reason) {
      // console.log('offerService.getLastAccessedActivity', reason);
      _this.setState({ error: reason })
    })
  }

  componentDidMount() {
    if (this.state.user) {

      this.scrollToTop();
      this.getOffer(this.props.match.params);
      window.addEventListener('scroll', this.updateButton);
    }

  }

  componentDidUpdate = () => {

    if (!this.state.setAriaButton) {
      if (this.state.suggestions && this.state.suggestions.length > 0) {
        let childs = document.getElementById("rdp-carousel-slider")
        if (childs) {
          let items = childs.getElementsByTagName('button')
          if (items.length > 0) {
            items.item(0).setAttribute('aria-label', 'Retorne para os três cursos anteriores')
            items.item(1).setAttribute('aria-label', 'Avance para os próximos três cursos de interesse')
          }
        }
      }

      if (this.refContent.current) {
        this.refContent.current.focus();
      }

      this.setState({ setAriaButton: true })

    }

  }

  updateButton() {
    this.setState({
      scrolled: window.scrollY > 150
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.state.user) {
      if (this.props.match.params.id !== nextProps.match.params.id) {
        this.scrollToTop();
        this.getOffer(nextProps.match.params);
      }
    }
  }

  getAllFeatured(params) {
    // console.log('viewCourse', 'getAllFeatured', params);

    var _this = this;

    if (!RDP_Utils.onLine()) {
      return;
    }

    ratingService.getAllByOfferId(params).then(function (reviews) {
      // console.log(reviews);

      // reviews.content.reverse();
      // if (reviews.content && reviews.content.length > 0) {
      _this.setState({
        reviews: Array.isArray(_this.state.reviews)
          ? _this.state.reviews.concat(reviews.content)
          : reviews.content,
        featureLastPage: reviews.last,
        featurePage: reviews.number
      });
      // }
    }, function (reason) {
      _this.setState({ error: reason });
    });
  }

  scrollToTop() {
    $('html, body').scrollTop(0);
  }

  getOffer(params) {
    // console.log('getOffer', params);

    var _this = this;

    _this.setState({
      loading: true
    }, function () {
      offerService.getById(params).then(function (offer) {
        document.title = offer.offerName + " - Detalhes do Curso - Page Compliance";

        if (!_this.state.reviews) {
          _this.getAllFeatured({ offerId: offer.treeOffer.id, page: _this.state.featurePage, size: _this.state.featureSize });
        }

        _this.setState({
          loading: false,
          offer: offer
        }, function () {
          this.getSuggestions({ size: 15, currentOfferId: this.props.match.params.id });
        });
      }, function (error) {
        _this.setState({ loading: false, error: error });
      });
    });
  }

  getSuggestions(params) {
    var _this = this;

    if (!RDP_Utils.onLine()) {
      _this.setState({ loading: false });
      return;
    }

    offerService.getSuggestions(params).then(function (suggestions) {
      _this.setState({ loading: false, suggestions: suggestions.content });
    }, function (reason) {
      _this.setState({ loading: false, error: reason });
    });
  }

  toggleModalSubscribe(value) {
    let _this = this;
    _this.setState({
      modalSubscribeOpened: value || !_this.state.modalSubscribeOpened
    });

    let item = document.getElementById("course-main-section")
    item.setAttribute("tabindex", "0");
    item.focus();

  }

  openOffer() {
    var activityId = 'first';
    // var contentOfferId = this.state.offer.treeOffer.id;

    // offerService.getLastAccessedActivity(contentOfferId).then(function(lastActivity){
    //  console.log(lastActivity);
    // }, function(error){
    //  console.log(error);
    // });

    this.setState({ redirectTo: `/edition/${this.state.offer.id}/atividade/${activityId}` });
  }

  onClickShowMore() {
    this.getAllFeatured({
      offerId: this.state.offer.treeOffer.id,
      page: this.state.featurePage + 1,
      size: this.state.featureSize
    });
  }

  isOpen(status) {
    this.setState({ isOpen: status });
  }

  render() {
    // console.log('this.state.documentHidden', this.state.documentHidden);

    var error,
      loader,
      actionButton,
      titleReviews,
      courseSugestions;

    var {
      offer,
      suggestions,
      modalSubscribeOpened,
      loading,
      redirectTo,
      featureLastPage,
      reviews
    } = this.state;

    // var aux = new Date(this.state.offer.treeOffer.offerStartAt);

    // var isOpen = (aux < new Date());

    var {
      aux,
      isOpen
    } = '';

    if (this.state.offer) {
      if (this.state.offer.treeOffer) {
        aux = this.state.offer.treeOffer.offerStartAt;
        isOpen = (new Date() > new Date(aux));
      }
    }

    if (!this.state.user) {
      return <Redirect to="/login" />;
    }

    if (loading) {
      loader = <Loader />;
    }

    if (this.state.error) {
      error = <ErrorDialog error={this.state.error} />;
    }

    var buttonClasses = 'rdp-btn-arrow-orange' + (
      this.state.scrolled
        ? ' active slideInUp'
        : ' inactive slideInDown');
    var sectionStateClass;

    if (offer && offer.progress !== undefined) {
      if ((offer.progress * 100) < 1) {
        sectionStateClass = ' rdp-init-course';
        actionButton = (<ButtonArrow address={`/edition/${this.state.offer.id}/atividade/first`} onClick={this.toggleModalSubscribe} className={buttonClasses} textButton="Iniciar curso" large={true} ariaLabel="Inicie este curso" />);
      } else if ((offer.progress * 100) > 1 && (offer.progress * 100) < RDP_CONFIG.offerProgressToDone) {
        sectionStateClass = ' rdp-continue-course';
        actionButton = (<ButtonArrow onClick={this.openOffer} className={buttonClasses} alt="currentCourse" textButton="Continuar curso" ariaLabel="Continue este curso" large={true} />);
      } else {
        sectionStateClass = ' rdp-access-course';
        actionButton = (<ButtonArrow onClick={this.openOffer} className={buttonClasses} textButton="Acessar curso" large={true} />);
      }
    }

    if (suggestions && suggestions.length > 0) {
      courseSugestions = (<section className="rdp-related-courses">
        <div className="rdp-container">
          <h3 tabIndex="0" aria-label="Início da listagem de cursos de interesse" className="rdp-title-related-courses">Cursos que podem te interessar</h3>
          <div id="rdp-carousel-slider">
            <SliderCourses offers={suggestions} currentCourse={this.props.match.params.id} />
          </div>
          <span tabIndex="0" aria-label="Fim da listagem de cursos de interesse" />
        </div>
      </section>);
    }

    var modalSettings = {
      toggle: this.toggleModalSubscribe,
      opened: modalSubscribeOpened,
      offerId: offer.id,
      offerName: offer.offerName
    }


    if (reviews) {
      if (reviews.length > 0) {
        titleReviews = (<div aria-label="Avaliação de cursos por usuários" class="rdp-students-reviews">
          <h3 className="rdp-h3">O que estão falando</h3>
          <h1 className="rdp-h1">Sobre este curso</h1>
        </div>);
      }
    }

    if (redirectTo) {
      return (<Redirect to={{
        pathname: redirectTo,
        state: {
          referrer: this.props.location.pathname
        }
      }} />);
    } else {
      return (<div className="rdp-view-course">
        {error}
        {loader}
        {/* <Header noBgTransition={true} /> */}
        <HeaderV2 navigation={<Navigation/>} label={offer.offerName}/>
        <span ref={this.refContent} tabIndex="0" aria-label="Início da página do curso" className="rdp-view-course-section-review" />

        <div className="rdp-main" id="course-main-section">
          {/* {
            offer
              ? <CourseMainSection offer={offer} onClickSubscribe={this.toggleModalSubscribe} />
              : null
          } */}

          <CourseAbout offer={offer} reviews={reviews} />

          <section className={'rdp-section-subscribe' + sectionStateClass + (
            !RDP_Utils.onLine()
              ? ' rdp-is-offline'
              : '')}>
            <div className="rdp-container">
              {
                isOpen
                  ? actionButton
                  : ''
              }
            </div>
          </section>

          {
            reviews && isOpen
              ?
              <div>
                <span tabIndex="0" aria-label="Início dos comentários" className="rdp-view-course-section-review" />
                <div className="rdp-container">
                  {titleReviews}
                </div>
                <CourseReviews lastPage={featureLastPage} onClickShowMore={this.onClickShowMore} reviews={reviews} />
                <span tabIndex="0" aria-label="Fim dos comentários" className="rdp-view-course-section-review" />
              </div>
              : null
          }

          <span tabIndex="0" aria-label="Fim da página do curso" className="rdp-end-section">Fim da página do curso</span>
          {courseSugestions}
          {/* <Navigation /> */}
        </div>
        <Footer />
        <Modal {...modalSettings} />
      </div>);
    }
  }
}
