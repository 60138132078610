import React, { Component } from 'react';
import './error.css';

export class View404 extends Component {
  render() {
    return (
      <div className="rdp-view rdp-view-error rdp-bg-squares">
        <h2 className="rdp-title-error">404</h2>
      </div>
    );
  }
}
