import React, {Component} from 'react';

import './show-more.css';

export default class ShowMore extends Component {
  render() {
    // console.log('ShowMore', 'render', this);

    return (
      <section className="rdp-show-more">
        <div className="rdp-container">
          <button onClick={this.props.onClick} className="rdp-btn-show-more rdp-btn-filled-orange" aria-label="Carregue mais cursos">Carregar mais</button>
        </div>
      </section>
    );
  }
}
