import React, { Component, Fragment } from 'react';
import IdleTimer from 'react-idle-timer'

import RDPAnalytics from './index';
import { RDP_CONFIG } from '../../config';
import { withRouter } from 'react-router-dom';

class RDPSessionTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTrack: null
    }

    this.RDPAnalytics = new RDPAnalytics({
      Authorization: this.getUser() ? "Bearer " + this.getUser().authentication_token : null,
      options: {
        disable: this.props.disable,
        consoleLog: this.props.consoleLog,
        productName: this.props.productName
      }
    });

    this.RDPAnalytics.ping();

    this.updateTracker = this.updateTracker.bind(this);
    this.normalizePageTrack = this.normalizePageTrack.bind(this);
    this.updatePageTrack = this.updatePageTrack.bind(this);

    /**
     * @example
     * window.dispatchEvent(new Event('rdp-session-logout'))
     */
    window.addEventListener('rdp-session-logout', this.updateTracker);

    /**
     * @example
     * window.dispatchEvent(new Event('rdp-session-login'))
     */
    window.addEventListener('rdp-session-login', this.updateTracker);

  }

  getUser() {
    return JSON.parse(
      window.localStorage.getItem("rdp-new-client-" + RDP_CONFIG.clientName)
    );
  }

  normalizePageTrack() {
    let pageTrack = null;

    const { pathname } = this.props.location;
    const params = [];
    const match = pathname
      .replace(/\/\d+(\/|$)/g, function (e) {
        params.push(e.replace(/\//g, ""));
        return "/$/"
      })
      .replace(/\/$/g, "");

    this.props.children.forEach(route => {
      let { path, pathAnalytics } = route.props;
      let pathMatch = path
        .replace(/:(.*?)(\/|$)/g, "$/")
        .replace(/\/$/g, "");

      if (!pathAnalytics) {
        throw new Error('Não há "pathAnalytics" definido para a rota: ' + path)
      }

      if ((pathMatch.match(/[$]/g) || []).length !== (pathAnalytics.match(/[$]/g) || []).length) {
        throw new Error('Os parâmetros para "path" e "pathAnalytics" devem ser iguais: ' + pathAnalytics)
      }

      if (match === pathMatch) {
        params.forEach(param => pathAnalytics = pathAnalytics.replace("$", param));
        pageTrack = pathAnalytics;
      }
    })

    return pageTrack;
  }

  updatePageTrack() {
    const pageTrack = window.location.hash
    this.setState({
      pageTrack
    }, () => {
      const pageTrack = this.normalizePageTrack();
      this.RDPAnalytics.ping(pageTrack);
    });
  }

  componentDidMount() {
    this.updatePageTrack();
    setInterval(() => this.RDPAnalytics.ping(), 5 * 60 * 1000);
  }

  componentDidUpdate() {
    if (this.state.pageTrack !== window.location.hash) {
      this.updatePageTrack();
    }
  }

  updateTracker() {
    this.RDPAnalytics = new RDPAnalytics({
      Authorization: this.getUser() ? "Bearer " + this.getUser().authentication_token : null,
      options: {
        disable: this.props.disable,
        consoleLog: this.props.consoleLog,
        productName: this.props.productName
      }
    });
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child,
        {
          EventReactGA: this.props.EventReactGA,
          RDPAnalytics: this.RDPAnalytics
        }
      )
    );

    return (
      <Fragment>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onIdle={this.updateTracker}
          debounce={250}
          timeout={30 * 60 * 1000}
        />
        {childrenWithProps}
      </Fragment>
    );
  }
}

export default withRouter(props => <RDPSessionTracker {...props} />);