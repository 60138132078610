import {RDP_Utils} from '../utils';
import {RDP_CONFIG} from '../config';
import {authenticationService} from './authentication';

var Password = function() {

  this.reset = function(params) {
    // console.log('reset', params);

    return new Promise(function(resolve, reject) {
      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/email/resetPassword',
        type: 'POST',
        xhrType: 'ajax',
        contentType: 'application/json',
        data: JSON.stringify(params)
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.change = function(params) {
    // console.log('change', params);

    var RDP_USER = authenticationService.getLocalUserData();

    return new Promise(function(resolve, reject) {
      // console.log('url', RDP_CONFIG.learningURL + 'api/protected/email/alterPassword');

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'api/protected/customUsers/v1/alterPassword', //customUsers
        type: 'POST',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        },
        contentType: 'application/json',
        data: JSON.stringify(params)
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  return this;
}

var passwordService = new Password();

export {
  passwordService
};
