// /*global CordovaExec*/
// import $ from 'jquery';
// import { RDP_CONFIG } from '../config';
// import { authenticationService } from './authentication';
import {mockService} from './mock';

var FAQ = function() {
  // var _this = this;

  this.getFAQ = function() {
  // console.log('getFAQ');

    return new Promise(function(resolve, reject) {
      // let RDP_USER = authenticationService.getLocalUserData();
      resolve(mockService.faq());

      // $.ajax({
      //   url: `${RDP_CONFIG.learningURL}restapi/api/protected/faq`,
      //   type: 'GET',
      //   headers: { 'Authorization': 'Bearer ' + RDP_USER.authentication_token }
      // }).done(function(response) {
      //   response.content = mockService.faq(response);
      //   resolve(response);
      // }).fail(function(jqXHR) {
      //   var reason;
      //   if (jqXHR.responseJSON) {
      //     reason = jqXHR.responseJSON;
      //   } else {
      //     reason = RDP_CONFIG.defaultReason;
      //   }
      //
      //   if(RDP_CONFIG.alwaysResolve){
      //     resolve(mockService.faq());
      //   } else {
      //   // console.log(reason);
// reject(reason);
      //   }
      // });
    });
  }

  return this;
}

var faqService = new FAQ();

export {
  faqService
};
