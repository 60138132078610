import { RDP_CONFIG } from '../config';
import { RDP_Utils } from '../utils';
import { authenticationService } from './authentication';

var Banner = function() {
  // var _this = this;

  this.getBanner = function(params) {
  // console.log('getBanner', params);

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: `${RDP_CONFIG.learningURL}restapi/api/protected/banner`,
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function(response) {
        resolve(response);
      }, function(jqXHR) {

        var reason;

        if (jqXHR.responseJSON) {
          reason = jqXHR.responseJSON;
        } else {
          reason = {
            custom_message: 'Ops, aconteceu algum erro'
          }
        }

        reject(reason);
      });
    });
  }

  return this;
}

var bannerService = new Banner();

export {
  bannerService
};
