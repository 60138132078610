import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { authenticationService } from '../../services';
import { RDP_CONFIG } from '../../config';
import { RDP_Utils } from '../../utils';
import $ from 'jquery';

import './dropdown-user.css';

import defaultImageAvatar from './avatar.svg';

export default class DropdownUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageAvatar: '',
      dropdownOpened: false,
      isOnline: null,
      timestamp: (Number(new Date()))

    };

    this.toggleDropdownUser = this.toggleDropdownUser.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  updateProfileImage(image) {
    var _this = this;

    var localUserData = JSON.parse(window.localStorage.getItem('rdp-new-client-' + RDP_CONFIG.clientName));
    localUserData.avatar = image;
    window.localStorage.setItem('rdp-new-client-' + RDP_CONFIG.clientName, JSON.stringify(localUserData));
    _this.setState({ imageAvatar: image });
  }

  getProfileImage() {
    var localUserData = JSON.parse(window.localStorage.getItem('rdp-new-client-' + RDP_CONFIG.clientName));
    var imageAvatar = localUserData !== null ? localUserData.avatar : defaultImageAvatar;
    if (!imageAvatar) {
      imageAvatar = defaultImageAvatar;
    }
    // console.log(imageAvatar);
    return imageAvatar;
  }

  toggleDropdownUser() {
    let _this = this;
    _this.setState({
      dropdownOpened: !this.state.dropdownOpened
    });

    if (!this.state.dropdownOpened) {
      window.document.addEventListener("keydown", this.handleKeyDown);
    } else {
      window.document.removeEventListener("keydown", this.handleKeyDown);
    }

  }


  handleKeyDown = event => {

    let currentElement = document.activeElement.id

    switch (event.keyCode) {
      case 40:
        event.preventDefault();
        switch (currentElement) {
          case 'menudropdown':
            document.getElementById("startmenu").focus()
            break;
          case 'startmenu':
            document.getElementById("meuperfil").focus()
            break;
          case 'meuperfil':
            document.getElementById("meuscursos").focus()
            break;
          case 'meuscursos':
            document.getElementById("sair").focus()
            break;
          case 'sair':
            document.getElementById("endmenu").focus()
            break;
          case 'endmenu':
            document.getElementById("endmenu").focus()
            break;
          default:
            break;
        }

        break;
      case 38:
        event.preventDefault();
        switch (currentElement) {
          case 'endmenu':
            document.getElementById("sair").focus()
            break;
          case 'sair':
            document.getElementById("meuscursos").focus()
            break;
          case 'meuscursos':
            document.getElementById("meuperfil").focus()
            break;
          case 'meuperfil':
            document.getElementById("menudropdown").focus()
            break;
          default:
            break;
        }
        break;
      case 9:
        this.toggleDropdownUser()
        document.getElementById("menudropdown").focus()
        document.removeEventListener("keydown", this.handleKeyDown);
        break;
      case 13:
        if (this.node2.contains(event.target)) {
          this.setState({ dropdownOpened: true });
        } else if (this.node3.contains(event.target)) {
          return;
        } else {
          this.setState({ dropdownOpened: false });
          document.removeEventListener("keydown", this.handleKeyDown);
        }
        break;
      default:
        break;
    }
  }

  handleSair = () => {
    console.log('handleSair')
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("mousedown", this.handleKeyDown);
  }

  componentWillMount() {
    console.log('componentWillMount')
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    console.log('componentWillMount')
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener("keydown", this.handleKeyDown);
  }


  handleClick(event) {
    event.stopPropagation();
    var _this = this;
    if (_this.node2.contains(event.target)) {
      _this.setState({ dropdownOpened: true });
    } else if (_this.node3.contains(event.target)) {
      return;
    } else {
      if ($(event.target).hasClass('rdp-img-user')) {
        return;
      }
      _this.setState({ dropdownOpened: false });
    }

  }

  render() {
    var user = authenticationService.getLocalUserData();
    let avatar = this.getProfileImage();
    var name;

    let avatarSrc = avatar;

    if (avatar.substring(0, 4) !== 'data') {
      avatarSrc = avatar + '?timestamp=' + this.state.timestamp;
    }

    if (user.socialName) {
      name = user.socialName.split(' ')[0] || user.socialName;
    } else if (user.name) {
      name = user.name.split(' ')[0] || user.name;
    }

    if (user) {
      return (<div>
        <div onClick={this.handleClick} className={`rdp-dropdown-user ${this.state.dropdownOpened
          ? 'rdp-active'
          : ''}`}>
          <button tabIndex="2" id="menudropdown" aria-label="Entre no menu" ref={node3 => this.node3 = node3} className={`rdp-dropdown-button ${this.state.dropdownOpened
            ? 'rdp-active'
            : ''}`} onClick={this.toggleDropdownUser}>

            {
              RDP_Utils.onLine()
                ? <img src={avatarSrc} className="rdp-img-user" alt="menu com opções, meu perfil, meus cursos, sair" title="menu com opções, meu perfil, meus cursos, sair" />
                : <img src={defaultImageAvatar} className="rdp-img-user" alt="imagem de perfil" title="imagem de perfil" />
            }
            
            <span aria-hidden="true">Olá,
              <strong className="userSocialName" aria-hidden="true"> {name}</strong>
            </span>
            
          </button>

          <div ref={node => this.node = node} className="rdp-dropdown-container">
            <ul className='rdp-dropdown-list' ref={node2 => this.node2 = node2}>
              <li>
                {
                  RDP_Utils.onLine()
                    ? <NavLink tabIndex="2" className="rdp-profile-box" to="/profile">
                      <div className="rdp-image-container">
                        <img src={avatarSrc} alt="imagem de perfil" title="imagem de perfil" />
                      </div>
                      {name}
                    </NavLink>
                    : <span className="rdp-profile-box">
                      <div className="rdp-image-container">
                        <img src={defaultImageAvatar} alt="imagem de perfil" title="imagem de perfil" />
                      </div>
                      {name}
                    </span>
                }
              </li>

              <span tabIndex="-1" id="startmenu" arial="Início do Menu">Início do Menu</span>


              {
                RDP_Utils.onLine()
                  ? <li>
                    <NavLink id="meuperfil" tabIndex="-1" to="/profile" activeClassName="rdp-active">Meu perfil</NavLink>
                  </li>
                  : null
              }
              <li>
                <NavLink id="meuscursos" tabIndex="-1" to="/my-courses" activeClassName="rdp-active">Meus cursos</NavLink>
              </li>
              {/* <li><NavLink to="/my-certificates">Meus certificados</NavLink></li> */}
              <li>
                <NavLink id="sair" aria-label="Saia do portal" tabIndex="-1" to="/logout" onClick={this.handleSair} className="rdp-ico-logout">Sair</NavLink>
              </li>
              <span id="endmenu" tabIndex="-1" arial="Fim do Menu">Fim do Menu</span>
            </ul>
          </div>
        </div>
        {/* <div className="rdp-dropdown-user-accessibility">
          <NavLink tabIndex="-1" to="/profile">Meu perfil</NavLink>
          <NavLink tabIndex="-1" to="/my-courses">Meus cursos</NavLink>
          <NavLink tabIndex="-1" to="/logout">Sair</NavLink>
        </div> */}
      </div>);
    } else {
      return null;
    }
  }
}
