import React, {Component} from 'react';

import {CardCourse} from '../../components';
import "./courses.css";

export default class Courses extends Component {
  render() {
    // console.log('Courses', 'render', this.props.offers);
    let title;

    var offers = this.props.offers || [];
    var courseList = [];

    for (var i = 0; i < offers.length; i++) {
      courseList.push(<CardCourse tabIndex={i} key={i} offer={offers[i]} indexProgress={true}/>);
    }

    if (this.props.title) {
      title = <h3 tabIndex="0"  className="rdp-title-page">{this.props.title}</h3>
    }

    return (<div className="rdp-cards-container rdp-container">
      {title}
      <div className="rdp-cards-courses">
        {courseList}
      </div>
      <button className="rdp-btn-orange" aria-label="Carregue mais cursos">Carregar Mais</button>
    </div>);
  }
}
