import {RDP_Utils} from '../utils';
import {RDP_CONFIG} from '../config';
import {authenticationService} from './authentication';

var Search = function() {

  this.getAreaList = function() {
    return new Promise(function(resolve, reject) {
      var RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: `${RDP_CONFIG.learningURL}restapi/api/protected/category/search/findByNameContainingIgnoreCase`,
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        },
        data: {
          q: '',
          page: '0',
          sort: 'id,asc'
        }
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.autoComplete = function(query) {
    return new Promise(function(resolve, reject) {
      var RDP_USER = authenticationService.getLocalUserData();
      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'api/protected/customContentOffers/v2/names/search?q=' + query,
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer' + RDP_USER.authentication_token
        },
        ignoreCache: true,
        name: 'lastInteraction'
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  return this;
}

var searchService = new Search();

export {
  searchService
};
