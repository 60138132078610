import {mockService} from './mock';

var Credits = function() {
  // var _this = this;

  // var RDP_USER = authenticationService.getLocalUserData();

  this.getCreditsParticipats = function() {

    return new Promise(function(resolve, reject) {
      resolve(mockService.creditsParticipants());
    });
  }

  return this;
}

var creditsService = new Credits();

export {
  creditsService
};
