import $ from 'jquery';

HTMLFormElement.prototype.toJSON = function() {
  // const formData = new FormData(this);
// console.log(formData);
  let jsonObject = {};

  // if(formData.entries){
  //   for (const [key, value] of formData.entries()) {
  //     jsonObject[key] = value;
  //   }
  // } else {
  var list;

  if (document.querySelectorAll) {
    list = this.querySelectorAll('[name]');
  } else {
    list = $(this).find('[name]');
  }

  for (var i = 0; i < list.length; i++) {
    jsonObject[list[i].name] = list[i].value;
  }
  // }

  return jsonObject;
}

HTMLBodyElement.prototype.setNavigatorClassName = function() {
  // IE 11
  // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; rv:11.0) like Gecko"
  // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; rv:11.0) like Gecko"

  // Edge 42
  // "Mozilla/5.0 (Windows NT 10.0; Win64; x64; ServiceUI 13) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/64.0.3282.140 Safari/537.36 Edge/17.17134"
  // "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:61.0) Gecko/20100101 Firefox/61.0"

  function css_browser_selector(u) {
    var ua = u.toLowerCase(),
      is = function(t) {
        return ua.indexOf(t) > -1
      },
      g = 'gecko',
      w = 'webkit',
      s = 'safari',
      o = 'opera',
      m = 'mobile',
      h = document.documentElement,
      b = [
        (!(/opera|webtv/i.test(ua)) && /msie\s(\d)/.test(ua))
          ? ('ie ie' + RegExp.$1)
          : is('firefox/2')
            ? g + ' ff2'
            : is('firefox/3.5')
              ? g + ' ff3 ff3_5'
              : is('firefox/3.6')
                ? g + ' ff3 ff3_6'
                : is('firefox/3')
                  ? g + ' ff3'
                  : is('gecko/')
                    ? g
                    : is('opera')
                      ? o + (
                        /version\/(\d+)/.test(ua)
                        ? ' ' + o + RegExp.$1
                        : (
                          /opera(\s|\/)(\d+)/.test(ua)
                          ? ' ' + o + RegExp.$2
                          : ''))
                      : is('konqueror')
                        ? 'konqueror'
                        : is('blackberry')
                          ? m + ' blackberry'
                          : is('android')
                            ? m + ' android'
                            : is('chrome')
                              ? w + ' chrome'
                              : is('iron')
                                ? w + ' iron'
                                : is('applewebkit/')
                                  ? w + ' ' + s + (
                                    /version\/(\d+)/.test(ua)
                                    ? ' ' + s + RegExp.$1
                                    : '')
                                  : is('mozilla/')
                                    ? g
                                    : '',
        is('j2me')
          ? m + ' j2me'
          : is('iphone')
            ? m + ' iphone'
            : is('ipod')
              ? m + ' ipod'
              : is('ipad')
                ? m + ' ipad'
                : is('mac')
                  ? 'mac'
                  : is('darwin')
                    ? 'mac'
                    : is('webtv')
                      ? 'webtv'
                      : is('win')
                        ? 'win' + (
                          is('windows nt 6.0')
                          ? ' vista'
                          : '')
                        : is('freebsd')
                          ? 'freebsd'
                          : (is('x11') || is('linux'))
                            ? 'linux'
                            : '',
        'js'
      ];

    var c = b.join(' ');

    h.className += ' ' + c;

    // IE
    if (navigator.userAgent.indexOf('Trident') > -1) {
      h.className += ' ie';
    }

    // EDGE
    if (navigator.userAgent.indexOf('Edge') > -1) {
      h.className += ' edge';
    }

    return c;
  };

  css_browser_selector(navigator.userAgent);
}
