import React, { Component } from 'react';
import { Route } from 'react-router-dom';

export default class DefaultRoute extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Route
      {...this.props}
      component={(props) => <this.props.component {...props}
        RDPAnalytics={this.props.RDPAnalytics}
      />}
    />;
  }
}
