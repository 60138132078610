import React, { Component, createRef } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { userService, authenticationService } from '../../services';
// import {RDP_Utils} from '../../utils';
import InputMask from 'react-input-mask';
import $ from 'jquery';

import './profile.css';

import moment from 'moment';
import 'moment/locale/pt-br';

import {
  Content,
  ButtonArrow,
  CustomInputFileImage,
  Loader,
  // Header,
  HeaderV2,
  Footer,
  Navigation,
  ErrorDialog,
  SuccessDialog,
  ModalSocialName
} from '../../components';

export class ViewProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      card: {},
      user: {},
      formFields: {},
      savedAvatar: '',
      businessPhone: '',
      mobilePhone: '',
      birthday: '',
      admissionAt: '',
      firstName: null,
      socialNameFieldDisabled: true,
      authed: authenticationService.getLocalUserData(),
      openedModalSocialName: false,
      success: false,
      firstAccess: this.props.match.path === "/profile/first"
        ? true
        : false,
      toProfile: window.localStorage.getItem('firstAccess' + authenticationService.getLocalUserData().id),
      typeModal: 'accept',
      actuationAreas: '',
      areaActuationCharLeft: 255
    }

    this.navigation = createRef();

    this.readOnlyInfo = {};
    this.user = {};
    this.formFields = {};
    this.card = {};
    this.avatar = '';
    this.areaActuationCharLimit = 255;

    this.onProfilePictureChange = this.onProfilePictureChange.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onOpenEditSocialModal = this.onOpenEditSocialModal.bind(this);
    this.closeModalSocialName = this.closeModalSocialName.bind(this);
    this.enabledSocialNameField = this.enabledSocialNameField.bind(this);
    this.socialNameLocalStorage = this.socialNameLocalStorage.bind(this);
    this.onHandlerCloseSuccessDialog = this.onHandlerCloseSuccessDialog.bind(this);
    this.onActuationAreasChangeHandler = this.onActuationAreasChangeHandler.bind(this);
  }

  componentDidMount() {
    document.title = "Perfil - Page Compliance";

    this.scrollToTop();
    this.socialNameLocalStorage();
  }

  componentWillMount() {
    if (this.state.authed) {
      this.getFormFields();
    }
  }

  closeModalSocialName = async () => {
    var _this = this;

    await _this.setState({ openedModalSocialName: false });

    console.log('closeModalSocialName')
    let item = document.getElementById("btnSocialName")
    item.setAttribute("tabindex", "0");
    item.focus();

  }

  enabledSocialNameField = async (optionFocus) => {
    var _this = this;
    await _this.setState({ socialNameFieldDisabled: false });

    await _this.closeModalSocialName();
    localStorage.setItem('rdp-social-name-field-' + this.state.authed.id, 'true');
    _this.setState({ typeModal: 'null' });

    if (optionFocus) {
      let item = document.getElementById("socialName")
      item.setAttribute("tabindex", "0");
      item.focus();
    } else {
      let item = document.getElementsByClassName("skip-nav")
      item[0].focus();
    }
  }

  socialNameLocalStorage() {
    var _this = this;
    var isEnabledSocialName = localStorage.getItem('rdp-social-name-field-' + this.state.authed.id);
    if (isEnabledSocialName) {
      _this.enabledSocialNameField(false);
    }
  }

  getFormFields() {
    var _this = this;

    userService.getMaritalStatusList().then(function (maritalStatus) {
      _this.formFields.maritalStatus = maritalStatus;

      userService.getGenderList().then(function (gender) {
        _this.formFields.gender = gender;

        userService.getEthnicityList().then(function (ethnicity) {
          _this.formFields.ethnicity = ethnicity;

          userService.getSchoolingList().then(function (schooling) {
            _this.formFields.schooling = schooling;

            userService.getInterestedItemsList().then(function (interestedItems) {
              _this.formFields.interestedItems = interestedItems;
              _this.setState({ formFields: _this.formFields });
              _this.getCurrentUser();
            }, function (reason) {
              _this.errorFormFields(reason);
            });

          }, function (reason) {
            _this.errorFormFields(reason);
          });

        }, function (reason) {
          _this.errorFormFields(reason);
        });

      }, function (reason) {
        _this.errorFormFields(reason);
      });

    }, function (reason) {
      _this.errorFormFields(reason);
    });
  }

  errorFormFields(error) {
    var _this = this;

    _this.setState({ loading: false, error: error });
  }

  getCurrentUser() {
    var _this = this;

    _this.setState({
      loading: true
    }, function () {
      userService.getCurrent().then(function (user) {
        // console.log('user', user);

        _this.setState({
          loading: false, firstName: user.name.split(' ')[0]
        }, function () {
          _this.setFormData(user);
        });
      }, function (reason) {
        _this.setState({ loading: false, error: reason });
      });
    });
  }

  setFormData(user) {
    var _this = this;

    _this.readOnlyInfo.cpf = user.cpf;
    // _this.readOnlyInfo.function = user.function;
    _this.readOnlyInfo.department = user.department;
    _this.readOnlyInfo.job = user.job;
    _this.readOnlyInfo.area = user.area;

    Object.getOwnPropertyNames(user).forEach(function (val) {
      if (user[val] && user[val].length) {
        _this.card[val] = user[val];
      }
    });

    if (user.interestedItems.length) {
      var interestedItems = document.querySelectorAll('[name="interestedItems"]');
      for (var i = 0; i < interestedItems.length; i++) {
        for (var j = 0; j < user.interestedItems.length; j++) {
          if (interestedItems[i].dataset.name === user.interestedItems[j].name) {
            interestedItems[i].checked = true;
          }
        }
      }
    }

    if (user.actuationAreas) {
      //document.querySelector('[name="actuationAreas"]').value = user.actuationAreas;
      var charLeft = this.areaActuationCharLimit - user.actuationAreas.length;
      _this.setState({ actuationAreas: user.actuationAreas, areaActuationCharLeft: charLeft });
    }

    if (user.businessPhone) {
      _this.setState({ businessPhone: user.businessPhone });
    }

    if (user.mobilePhone) {
      _this.setState({ mobilePhone: user.mobilePhone });
    }

    if (user.birthday) {
      _this.setState({
        birthday: moment(user.birthday).format('DD/MM/YYYY')
      });
    }

    if (user.socialName) {
      document.querySelector('[name="socialName"]').value = user.socialName;
    }

    if (user.admissionAt) {
      this.setState({
        admissionAt: moment(user.admissionAt).format('DD/MM/YYYY')
      })
    }

    if (user.reducedMobility !== null) {
      document.querySelector('[name="reducedMobility"]').value = user.reducedMobility
        ? 'yes'
        : 'no';
    }

    if (user.gender) {
      document.querySelector('[name="gender"]').value = user.gender.name;
    }

    if (user.ethnicity) {
      document.querySelector('[name="ethnicity"]').value = user.ethnicity.name;
    }

    if (user.maritalStatus) {
      document.querySelector('[name="maritalStatus"]').value = user.maritalStatus.name;
    }

    if (user.schooling) {
      document.querySelector('[name="schooling"]').value = user.schooling.name;
    }

    // console.log("_this.card.avatar: ", _this.card.avatar);

    _this.setState({ card: _this.card, savedAvatar: _this.card.avatar });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onSubmitForm(event) {
    event.preventDefault();

    var _this = this;
    var params = event.target.toJSON();
    var _interestedItems = [];
    var selectedInterestItems = document.querySelectorAll('input[name="interestedItems"]:checked');
    var error = false;

    Array.prototype.forEach.call(selectedInterestItems, function (element) {
      _interestedItems.push(element.getAttribute('data-name'));
    });

    if (_this.avatar) {
      // console.log('_this.avatar: ', _this.avatar);
      params.avatar = _this.avatar;
    } else {
      delete params.avatar;
    }

    params.cpf = _this.readOnlyInfo.cpf;
    params['function'] = _this.readOnlyInfo['function'];
    params.department = _this.readOnlyInfo.department;
    params.job = _this.readOnlyInfo.job;
    params.area = _this.readOnlyInfo.area;

    params.name = _this.card.name;
    params.email = _this.card.email;
    params.reducedMobility = (params.reducedMobility !== '' && params.reducedMobility !== 'no')
      ? true
      : false;
    params.interestedItems = _interestedItems;
    // if (params.actuationAreas.length > 255) {
    //   params.actuationAreas = params.actuationAreas.substring(0, 255);
    // }

    if (params.birthday) {
      if (moment(params.birthday, 'DD/MM/YYYY').isValid()) {
        var birthday = new Date(moment(params.birthday, 'DD/MM/YYYY').set({ hour: 12, minute: 0 }).toString());
        params.birthday = birthday;
        // console.log("birthday: ", birthday);

        //// console.log(birthday < (new Date()));

        if (birthday > (new Date())) {
          error = {
            message: 'Data de nascimento inválida'
          }
        }
      } else {
        error = {
          message: 'Data de nascimento inválida'
        }
      }
    }

    if (params.admissionAt) {
      if (moment(params.admissionAt, 'DD/MM/YYYY').isValid()) {
        var admissionAt = new Date(moment(params.admissionAt, 'DD/MM/YYYY').set({ hour: 12, minute: 0 }).toString());
        params.admissionAt = admissionAt;

        if (admissionAt > (new Date())) {
          error = {
            message: 'Data de admissão inválida'
          }
        }
      } else {
        error = {
          message: 'Data de admissão inválida'
        }
      }
    }

    if (_this.state.businessPhone.length !== 0 && params.businessPhone.indexOf('_') > 1) {
      _this.errorFormFields({ custom_message: 'Telefone comercial inválido' });
      return false;
    }

    if (_this.state.mobilePhone.length !== 0 && params.mobilePhone.indexOf('_') > 1) {
      _this.errorFormFields({ custom_message: 'Telefone celular inválido' });
      return false;
    }

    //// console.log(error);
    if (!error) {
      _this.setState({
        loading: true,
        error: false,
        success: {
          message: 'Informações atualizadas com sucesso!'
        }
      }, function () {
        // console.log('params: ', params);
        userService.saveCurrent(params).then(function (user) {
          // console.log(user);
          window.localStorage.setItem('firstAccess' + authenticationService.getLocalUserData().id, true);

          _this.setState({
            loading: false,
            firstAccess: false,
            toProfile: true
          }, function () {
            _this.setFormData(user);
            // console.log(params);
            // if(params.socialName) {
            //   $(".userSocialName").text(params.socialName);
            // }

            let currentUser = authenticationService.getLocalUserData();
            //// console.log(authenticationService.getLocalUserData());
            currentUser.socialName = params.socialName;
            authenticationService.patchLocalUserData(currentUser);
            //// console.log(authenticationService.getLocalUserData());

            if (_this.avatar) {
              // console.log('linha 357: ', _this.avatar);
              _this.setState({ savedAvatar: user.avatar });
              _this.navigation.current.updateProfileImage(_this.avatar);
              _this.avatar = '';
            }
          });
        }, function (reason) {
          _this.setState({ loading: false, error: reason });
        });
      });
    } else {
      _this.setState({ loading: false, error: error });
    }
  }

  scrollToTop() {
    $('html, body').scrollTop(0);
  }

  onOpenEditSocialModal() {
    var _this = this;

    _this.setState({ openedModalSocialName: true });
    let item = document.getElementById("rdp-modal-socialname")
    item.setAttribute("tabindex", "0");
    item.focus();

  }

  onProfilePictureChange(inputFile) {
    // variaveis
    let _this = this;

    userService.convertFileInputToBase64(inputFile).then(function (response) {
      // console.log(response);

      let canvas = document.createElement('canvas'),
        context = canvas.getContext('2d');

      canvas.id = 'canvas-profile-image';
      canvas.className = 'rdp-canvas-image';
      canvas.style.display = 'none';

      let imageProfile = new Image();

      imageProfile.onload = function (event) {
        let { target } = event;

        // define os limites
        var limit = {
          width: 500,
          height: 500
        }

        var getScale = function (size, limit) {
          var scale = limit / size;
          return scale;
        };

        // define os valores iniciais
        var scale = 0,
          dif = 0,
          pos = {
            top: 0,
            left: 0
          };

        if (target.width < target.height) {
          // console.log('vertical');
          dif = target.width - target.height;
          scale = getScale(target.width, limit.width);
          pos.left = (dif * scale) / 2;
        } else {
          // console.log('horizontal');
          dif = target.height - target.width;
          scale = getScale(target.height, limit.height);
          pos.top = (dif * scale) / 2;
        }

        // organiza as medidas
        var ctx = {
          width: target.width * scale,
          height: target.height * scale,
          left: pos.left,
          top: pos.top
        }

        // Atribui o tamanho do canvas
        canvas.width = limit.width;
        canvas.height = limit.height;

        // renderiza a imagem
        context.drawImage(event.target, ctx.top, ctx.left, ctx.width, ctx.height);
      }

      imageProfile.src = response;

      document.body.appendChild(canvas);

      setTimeout(function () {
        var canvasProfileImage = document.querySelector('canvas');
        _this.avatar = canvasProfileImage.toDataURL();
        _this.setState({ savedAvatar: _this.avatar });
        canvasProfileImage.remove();
      }, 2000);

    }, function (reason) {
      // console.log(reason);
    });
  }

  onHandlerCloseSuccessDialog() {
    this.setState({ success: false });
  }

  onActuationAreasChangeHandler(event) {
    var actuationAreasValue = event.currentTarget.value;
    var charLeft = this.areaActuationCharLimit - actuationAreasValue.length;
    this.setState({ actuationAreas: actuationAreasValue, areaActuationCharLeft: charLeft });
  }

  render() {
    var {
      ethnicity,
      gender,
      interestedItems,
      maritalStatus,
      schooling
    } = this.state.formFields;

    var {
      authed,
      card,
      loading,
      areaActuationCharLeft,
      actuationAreas
    } = this.state;

    let loader,
      error,
      area,
      job,
      email,
      cpf,
      firstAccess,
      regionalDirectory,
      maritalStatusOptions = [],
      genderOptions = [],
      ethnicityOptions = [],
      schoolingOptions = [],
      interestedItemsOptions = [];

    const emptySelectOption = <option disabled value="selecione">Selecione</option>;
    var success;

    if (this.state.success) {
      success = <SuccessDialog func={this.onHandlerCloseSuccessDialog} success={this.state.success} />;
    }

    if (maritalStatus) {
      maritalStatus.forEach(function (option) {
        maritalStatusOptions.push(<option key={option.ordinal} value={option.name}>{option.label}</option>);
      });
    }

    if (gender) {
      gender.forEach(function (option) {
        genderOptions.push(<option key={option.ordinal} value={option.name}>{option.label}</option>);
      });
    }

    if (ethnicity) {
      ethnicity.forEach(function (option) {
        ethnicityOptions.push(<option key={option.ordinal} value={option.name}>{option.label}</option>);
      });
    }

    if (schooling) {
      schooling.forEach(function (option) {
        schoolingOptions.push(<option key={option.ordinal} value={option.name}>{option.label}</option>);
      });
    }

    if (interestedItems) {
      interestedItems.forEach(function (option) {
        interestedItemsOptions.push(<div className="rdp-container-checkbox" key={option.ordinal}>
          <input type="checkbox" id={`option-interest-items-${option.ordinal}`} className="rdp-form-control" name="interestedItems" data-name={option.name} />
          <label htmlFor={`option-interest-items-${option.ordinal}`}>{option.label}</label>
        </div>);
      });
    }

    if (!authed) {
      return <Redirect to="/login" />;
    }

    //// console.log(this.state.firstAccess, this.state.toProfile);

    if (this.state.firstAccess && this.state.toProfile) {
      return <Redirect to="/profile" />
    }

    if (loading) {
      loader = <Loader />;
    }

    if (this.state.error) {
      error = <ErrorDialog show="true" error={this.state.error} />;
    }

    // if (card.department) {
    //   department = <span>{'DR/DN: ' + this.state.card.department}</span>;
    // }

    if (card.job) {
      job = <span tabIndex="0">{'Cargo: ' + this.state.card.job}</span>;
    }

    if (card.area) {
      area = <span tabIndex="0">{'Area: ' + this.state.card.area}</span>;
    }

    if (card.email) {
      email = <span tabIndex="0">{'E-mail: ' + this.state.card.email}</span>;
    }

    if (card.cpf) {
      cpf = <span tabIndex="0">{'CPF: ' + this.state.card.cpf}</span>;
    }

    if (card.department) {
      regionalDirectory = <span tabIndex="0">{'DR/DN: ' + this.state.card.department}</span>;
    }

    if (this.state.firstAccess) {
      firstAccess = (<p className="rdp-msg-first-access">
        Olá {this.state.firstName},<br />
        Você pode terminar de preencher seu perfil agora, ou
        <Link to="/">
          &nbsp; clique aqui &nbsp;
        </Link>
        para pular esta etapa.
      </p>);
    }
    var typeIcon;
    if (this.state.typeModal === 'accept') {

      typeIcon = <button type="button" onClick={this.onOpenEditSocialModal} id="btnSocialName" className="rdp-edit-social-name rdp-ico-pencil">
        <span>Editar nome social</span>
      </button>;
    } else {
      typeIcon = <button type="button" onClick={this.onOpenEditSocialModal} id="btnSocialName" className="rdp-edit-social-name rdp-ico-info">
        <span>Editar nome social</span>
      </button>;
    }
    //// console.log('this.state.socialNameFieldDisabled', this.state.socialNameFieldDisabled);
    return (<div>
      <HeaderV2 navigation={<Navigation/>} label="Meu Perfil"/>
      <section className="rdp-view rdp-view-profile">
        {error}
        {success}
        {loader}
        {/* <Header noBgTransition="noBgTransition" /> */}
        {/* <Navigation ref={this.navigation} /> */}
        {/* <span tabIndex="0" aria-label="Início da página meu perfil">Início da página meu perfil</span> */}
        <Content className="has-header">        
          <div className="rdp-main">
            <div className="rdp-container">            
              {/* <h2 tabIndex="0" id="title-meu-perfil" className="rdp-title-page">Meu perfil</h2> */}

              {firstAccess}

              <form className="rdp-form-profile" onSubmit={this.onSubmitForm}>
                <div className="fieldset">
                  <div className="rdp-card-profile">
                    <CustomInputFileImage containerClass="rdp-form-group" labelCLass="rdp-sr-only" labelText="Minha foto. Alterar minha foto." inputClass="rdp-form-control" inputId="avatar" onInputFileChange={this.onProfilePictureChange} savedAvatar={this.state.savedAvatar} />

                    <div className="rdp-card-info">
                      <h3 className="rdp-card-name">
                        {this.state.card.socialName || this.state.card.name}

                        <small>
                          {/* {regionalDirectory} */}
                          {job}
                          {area}
                        </small>
                      </h3>

                      <p>
                        {email}
                        {cpf}
                        <Link to="/change-password">Mudar senha</Link>
                      </p>
                    </div>
                  </div>

                  <div className="rdp-form-group rdp-form-group-social rdp-col-4">
                    <label htmlFor="socialName">Nome Social</label>
                    <div id="socialNameWrap" className="rdp-wrap-action">
                      <input id="socialName" tabIndex="0" name="socialName" className={`rdp-form-control ${this.state.socialNameFieldDisabled
                        ? 'rdp-disabled'
                        : ''}`} placeholder="Nome Social" aria-label="Nome Social" disabled={this.state.socialNameFieldDisabled
                          ? 'disabled'
                          : ''} /> {typeIcon}
                    </div>
                  </div>

                  <div className="rdp-form-group rdp-col-4">
                    <label htmlFor="reducedMobility">Possui deficiência?</label>
                    <div className="rdp-custom-select">
                      <select id="reducedMobility" name="reducedMobility" defaultValue='selecione'>
                        <option disabled value="selecione">Selecione</option>
                        <option value="yes">Sim</option>
                        <option value="no">Não</option>
                      </select>
                    </div>
                  </div>

                  <div className="rdp-form-group rdp-col-4">
                    <label htmlFor="gender">Sexo</label>
                    <div className="rdp-custom-select">
                      <select id="gender" name="gender" defaultValue='selecione'>
                        {emptySelectOption}
                        {genderOptions}
                      </select>
                    </div>
                  </div>

                  <div className="rdp-form-group rdp-col-4">
                    <label htmlFor="businessPhone">Telefone comercial</label>
                    <InputMask onChange={this.handleChange} id="businessPhone" mask="(99) 9999-9999" placeholder="(__) ____-____" name="businessPhone" className="rdp-form-control" aria-label="Telefone comercial" value={this.state.businessPhone} />
                  </div>

                  <div className="rdp-form-group rdp-col-4">
                    <label htmlFor="mobilePhone">Telefone celular</label>
                    <InputMask onChange={this.handleChange} id="mobilePhone" mask="(99) 99999-9999" placeholder="(__) _____-____" name="mobilePhone" className="rdp-form-control" aria-label="Telefone celular" value={this.state.mobilePhone} />
                  </div>

                  <div className="rdp-form-group rdp-col-4">
                    <label htmlFor="birthday">Data de nascimento</label>
                    <InputMask onChange={this.handleChange} id="birthday" mask="99/99/9999" placeholder="dd/mm/aaaa" name="birthday" className="rdp-form-control" aria-label="Data de nascimento" value={this.state.birthday} />
                  </div>

                  <div className="rdp-form-group rdp-col-4 rdp-no-padding">
                    <label htmlFor="maritalStatus">Estado civil</label>
                    <div className="rdp-custom-select">
                      <select id="maritalStatus" name="maritalStatus" defaultValue='selecione'>
                        {emptySelectOption}
                        {maritalStatusOptions}
                      </select>
                    </div>
                  </div>
{/* 
                  <div className="rdp-form-group rdp-col-3">
                    <label htmlFor="admissionAt">Data de admissão</label>
                    <InputMask onChange={this.handleChange} id="admissionAt" mask="99/99/9999" placeholder="dd/mm/aaaa" name="admissionAt" className="rdp-form-control" aria-label="Data de admissão" value={this.state.admissionAt} />
                  </div> */}

                  <div className="rdp-form-group rdp-col-4">
                    <label htmlFor="ethnicity">Etnia</label>
                    <div className="rdp-custom-select">
                      <select id="ethnicity" name="ethnicity" defaultValue='selecione'>
                        {emptySelectOption}
                        {ethnicityOptions}
                      </select>
                    </div>
                  </div>

                  <div className="rdp-form-group rdp-col-4">
                    <label htmlFor="schooling">Escolaridade</label>
                    <div className="rdp-custom-select">
                      <select id="schooling" name="schooling" defaultValue='selecione'>
                        {emptySelectOption}
                        {schoolingOptions}
                      </select>
                    </div>
                  </div>

                  {/* <div className="rdp-form-group rdp-col-12">
                    <label tabIndex="0" className="actuation-areas-label" htmlFor="actuationAreas" aria-label="Áreas de atuação">Áreas de atuação</label>
                    <div className="actuation-wrap">
                      <textarea id="actuationAreas" maxLength="255" className="rdp-form-control" onChange={this.onActuationAreasChangeHandler} value={actuationAreas} name="actuationAreas" placeholder="Descreva as áreas (ex.: atendimento, gerência de comunicação, unidade escolar.)" aria-label="Escreva em 250 caracteres" />
                      <label id="actuationAreasCharacterLeft" className={"actuation-areas-character-left " + (
                        areaActuationCharLeft < 0
                          ? 'warning'
                          : '')} htmlFor="actuationAreas">{areaActuationCharLeft}
                        &nbsp;caracteres restantes</label>
                    </div>
                  </div> */}

                  {/* <div className="rdp-form-group checkbox">
                    <label tabIndex="0" className="label-checkbox">Marque as áreas do seu interesse:</label>
                    {interestedItemsOptions}
                  </div> */}

                  <div className="rdp-form-group rdp-form-actions">
                    <ButtonArrow type="submit" textButton="Salvar" ariaLabel="Salvar seus dados" className="rdp-btn-arrow-orange" />
                  </div>

                </div>
              </form>
              <span tabIndex="0" aria-label="Fim da página meu perfil" className="rdp-aria-label">Fim da página meu perfil</span>
            </div>
            <Footer />
          </div>
        </Content>
        <ModalSocialName opened={this.state.openedModalSocialName} toggle={this.closeModalSocialName} enableField={() => this.enabledSocialNameField(true)} typeModal={this.state.typeModal} />
      </section>
    </div>);
  }
}
