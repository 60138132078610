import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './empty-explore.css';

export default class EmptyExplore extends Component {
  render() {
    return (
      <section className="rdp-empty-explore-container">
        <div className="rdp-container">
          <h2 className="rdp-empty-explore-title">Você ainda não iniciou nenhum curso</h2>
          <Link to="/">Conheça os Cursos disponíveis</Link>
        </div>
      </section>
    );
  }
}
