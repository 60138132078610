import React, { Component } from 'react';
import './loader.css';

export default class Loader extends Component {
  render() {
    return (
      <div className="rdp-loader-component">
        <div className="rdp-showbox">
          <div className="rdp-loader">
            <svg className="rdp-circular" viewBox="25 25 50 50">
              <circle
                strokeMiterlimit="10"
                className="rdp-path"
                strokeWidth="2"
                fill="none"
                cx="50"
                cy="50"
                r="20"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}
