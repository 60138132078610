import {RDP_Utils} from '../utils';
import {RDP_CONFIG} from '../config';
import {authenticationService} from './authentication';

var Support = function() {
  // var _this = this;

  this.getAreaList = function(id) {
    // console.log('getAreaList', id);

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      id = !id
        ? ''
        : id;

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/support_area/' + id,
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.getIssueListById = function(id) {
    // console.log('getIssueListById', id);

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: `${RDP_CONFIG.learningURL}restapi/api/protected/issue_type/${id}`,
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  };

  this.getIssueTypesByArea = function(area) {
    // console.log('getIssueTypesByArea', area);

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + "restapi/api/protected/support_area/" + area.id + "/issueTypes",
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  };

  this.sendSuppotRequest = function(params) {
    // console.log('sendSuppotRequest', params);

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      params = RDP_Utils.objectToFormWithAttach(params);

    // console.log(params);

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/ibm/support/create' + params.queryParams,
        method: 'POST',
        processData: false,
        cache: false,
        contentType: false,
        xhrType: 'ajax',
        headers: RDP_USER.authentication_token
          ? {
            'Authorization': 'Bearer ' + RDP_USER.authentication_token
          }
          : undefined,
        data: params.formData
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  return this;
}

var supportService = new Support();

export {
  supportService
};
