import React, { Component } from 'react';

import Slider from 'react-slick';
import Slide from './slide.png';
import 'slick-carousel/slick/slick.css';
import './carousel.css';

export default class Carousel extends Component {
  render() {
    let sliderSettings = {
      autoplay: true,
      autoplaySpeed: 10000,
      className: 'rdp-carousel',
      dots: true,
      infinite: true,
      accessibility: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    }

    let content;

    if(Array.isArray(this.props.items)){
      content = this.props.items.map(function(banner, index){
        let banners = [];
        banners.push(<img key={`${index}-hd`} className="rdp-image-carousel rdp-img-hd" src={banner.hd} alt={banner.title}/>);
        banners.push(<img key={`${index}-desktop`} className="rdp-image-carousel rdp-img-desktop" src={banner.desktop} alt={banner.title}/>);
        banners.push(<img key={`${index}-mobile`} className="rdp-image-carousel rdp-img-mobile" src={banner.mobile} alt={banner.title}/>);
        // banners.push(<img key={`${index}-hd`} className="rdp-image-carousel rdp-img-hd" src={Slide} alt={banner.title}/>);
        // banners.push(<img key={`${index}-desktop`} className="rdp-image-carousel rdp-img-desktop" src={Slide} alt={banner.title}/>);
        // banners.push(<img key={`${index}-mobile`} className="rdp-image-carousel rdp-img-mobile" src={Slide} alt={banner.title}/>);

        let bannerContent;

        if(banner.link){
          let link = "#" + banner.link;
          console.log(banner.link)
          // bannerContent = (<a className="rdp-banner-content" href={banner.link} target="_blank">{banners}</a>);
          bannerContent = (<a className="rdp-banner-content" href={link}>{banners}</a>);
        } else {
          bannerContent = (<span className="rdp-banner-content">{banners}</span>);
        }

        return (<div key={index} className="rdp-carousel-item">
            {bannerContent}
        </div>);
      });
    }

    return (
      <Slider tabIndex="-1" {...sliderSettings}>
        {content}
      </Slider>
    );
  }
}
