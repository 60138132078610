import React, {Component} from 'react';

import './content.css';

export default class ContentComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    // console.log(this.props, this);

    return <div tabIndex="-1" className={`content-rdp-component rdp-component ${this.props.className}`}>{this.props.children}</div>;
  }
}
