import React, {Component} from 'react';

import './custom-input-file-image.css';

export default class CustomInputFileImage extends Component {
  constructor(props) {
    super(props);

    this.onInputFileChange = this.onInputFileChange.bind(this);
  }

  onInputFileChange(event) {
    if (!!this.props.onInputFileChange) {
      this.props.onInputFileChange(event.target);
    } else {
      // console.log('file was selected but no callback was set.', event.target);
    }
    event.target.value = '';
  }

  render() {
    const {savedAvatar} = this.props;

    let savedImage;

    if (savedAvatar) {
      var avatarUrl = savedAvatar;
      if (Number(savedAvatar.indexOf("http")) === 0) {
        avatarUrl += "?nocache=" + Number(new Date());
      }
      savedImage = <img src={avatarUrl} alt="Avatar salvo"/>;
    }

    return (<div className={`rdp-custom-input-file-image-container ${this.props.containerClass}`}>
      <label htmlFor={this.props.inputId} className={this.props.labelCLass}>{this.props.labelText}</label>
      <div className="rdp-custom-input-file-image">
        <span className="overlay">
          <span className="icone"></span>
          <span className="legenda">Alterar foto do perfil (até 2mb)</span>
        </span>
        {savedImage}
        <input type="file" id={this.props.inputId} className={this.props.inputClass} name={this.props.inputId} onChange={this.onInputFileChange} accept="image/*"/>
      </div>
      <span className="rdp-info-image">Alterar foto do perfil (até 2mb)</span>
    </div>);
  }
}
