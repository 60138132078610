const platform = require("platform");
const { DeviceUUID } = require("device-uuid") 
const { uuid } = require('uuidv4');

function getAgentUid(productName) {
    if (!productName) {
        throw new Error("É necessário definir um productName para RDPAnalytics");
    }

    const product = platform.product || "Desktop";
    return productName + " (" + product + ";" + platform.os.family + ";" + (platform.os.version || "") + "); Framework/1.0 Config/1.0)"
}

function getDeviceUid() {
    return new DeviceUUID().get();
}

function getUuid() {
    return uuid();
}

module.exports = {
    getAgentUid,
    getDeviceUid,
    getUuid
}