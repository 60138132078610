const axios = require("axios");
const {
    getAgentUid,
    getDeviceUid,
    getUuid
} = require("./info");

const {
    RDP_CONFIG
} = require("../../config");
console.log("KEY", RDP_CONFIG)
const env = {
    ApiUrl: "https://api.digitalpages.com.br"
}

function RDPAnalytics({
    Authorization,
    SubscriptionKey,
    AgentUid,
    DeviceUid,
    SessionUid,
    ApiUrl,
    options = {}
}) {
    this.Authorization = Authorization;
    this.AgentUid = AgentUid || getAgentUid(options.productName);
    this.DeviceUid = DeviceUid || getDeviceUid();
    this.SessionUid = SessionUid || getUuid();
    this.ApiUrl = ApiUrl || env.ApiUrl;
    this.SubscriptionKey = SubscriptionKey || RDP_CONFIG.API_KEY;

    const headers = {
        "Agent-Uid": this.AgentUid,
        "Session-Uid": this.SessionUid,
        "Project-Key": this.SubscriptionKey,
    }

    if (this.Authorization) {
        headers.Authorization = this.Authorization;
    }

    if (this.DeviceUid) {
        headers["Device-Uid"] = this.DeviceUid;
    }

    this.Connection = axios.create({
        baseURL: this.ApiUrl,
        headers
    });
    this.options = options;
}

RDPAnalytics.prototype.ping = async function (screen = "home") {
    if (this.options.consoleLog) {
        console.log("RDP Analytics [Ping]; Screen: " + screen);
    }

    if (!this.options.disable) {
        return await this.Connection.post("/util/v2/ping?screen=" + screen);
    }
}
RDPAnalytics.prototype.track = async function (data) {
    if (this.options.consoleLog) {
        console.log("RDP Analytics [Track]; Data: " + JSON.stringify(data));
    }

    if (!this.options.disable) {
        return await this.Connection.post("/cms/v1/track/scorm", data);
    }
}

export default RDPAnalytics;