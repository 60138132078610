import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {offerService, authenticationService} from '../../services';
import $ from 'jquery';

import {
  Courses,
  Carousel,
  Header,
  Footer,
  Navigation,
  Loader
  // ShowMore
} from '../../components';

export class ViewCourses extends Component {
  constructor(props) {
    super(props);

    // console.log('ViewCourses', 'constructor');

    this.state = {
      loading: true,
      error: false,
      redirect: false,
      offers: [],
      highlights: [],
      reviews: [],
      authed: authenticationService.getLocalUserData()
    }
  }

  componentWillMount() {
    // console.log('ViewCourses', 'componentWillMount');

    if (this.state.authed) {
      this.getOffers({});
    }
  }

  componentDidMount() {
    document.title = "Todos os Cursos - Page Compliance";
    this.scrollToTop();
  }

  scrollToTop() {
    $('html,body').scrollTop(0);
  }

  getOffers(params) {
    // console.log('getOffers', params);

    var _this = this;

    _this.setState({
      loading: true,
      error: false
    }, function() {
      offerService.getAll(params).then(function(response) {
        // response.content.sort((a,b) => b.progress - a.progress);
        // console.log('getOffers', 'response', response);
        _this.setState({loading: false, error: false, offers: response.content.sort((a,b) => a.progress - b.progress)});
      }, function(reason) {
        _this.setState({loading: false, error: reason});
      });
    });
  }

  render() {
    // console.log('ViewHome', 'render', this.state);

    let loader;

    if (!this.state.authed) {
      return (<Redirect to={{
          pathname: '/login'
        }}/>);
    }

    if (this.state.loading) {
      loader = <Loader/>;
    }

    var highlights;

    if (this.state.highlights.length > 0) {
      highlights = <Carousel highlights={this.state.highlights}/>;
    }

    var reviews;

    // comentando as linhas abaixo pois offers não possui atributo reviews.

    // if (this.state.offers && this.state.offers.length > 0 && this.state.offers[0].reviews.length > 0) {
    //   reviews = <StudentsReviews reviews={this.state.offers[0].reviews} />;
    // }

    return (<div className="rdp-view rdp-view-courses">
      {loader}
      <Header noBgTransition="noBgTransition"/>
      <div className="rdp-main">
        <div tabIndex="2" className="rdp-container">
          <h2 className="rdp-title-page">Todos os cursos</h2>
          {highlights}
          <Courses offers={this.state.offers}/> {/* <ShowMore/> */}
          {reviews}
        </div>
        <Navigation/>
      </div>
      <Footer/>
    </div>);
  }
}
