import {RDP_Utils} from '../utils';
import {RDP_CONFIG} from '../config';
import {authenticationService} from './authentication';
// import {mockService} from './mock';

var ResetPassword = function() {


  this.saveNewPassword = function(params) {
  // console.log('saveNewPassword', params);

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: `${RDP_CONFIG.learningURL}restapi/api/protected/reset-password/save`,
        type: 'POST',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        },
        data: params
      }).done(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  return this;
}

var resetPasswordService = new ResetPassword();

export {
  resetPasswordService
};
