import $ from 'jquery';
import {RDP_Utils} from '../utils';
import {RDP_CONFIG} from '../config';
import {authenticationService} from './authentication';

var Rating = function() {
  // var _this = this,

  this.setRating = function(params) {
    // console.log('ratingService', 'setRating', params);

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: `${RDP_CONFIG.learningURL}restapi/api/protected/rating/set`,
        type: 'POST',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        },
        data: params
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.getRating = function(contentOfferId) {
    // console.log('ratingService', 'getRating');

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: `${RDP_CONFIG.learningURL}restapi/api/protected/customRating/content-offer/` + contentOfferId + '/loggeduser',
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.insert = function(params) {
    // console.log('ratingService', 'insert', params);

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'api/protected/customRating',
        type: 'POST',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(params)
      }).then(function(response) {
        resolve(response);
      }, function(error) {
        reject(error);
      });
    });
  }

  this.getAllByUser = function() {
    // console.log('ratingService', 'getAllByUser');

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      $.ajax({
        url: RDP_CONFIG.learningURL + 'api/protected/customRating/user/',
        type: 'GET',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).done(function(response) {
        resolve(response);
      }).fail(function(error) {
        reject(error);
      });
    });
  }

  this.getAllByOfferId = function(params) {
    // console.log('ratingService', 'getAllByOfferId', params);

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'api/protected/customRating/content-offer/' + params.offerId,
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        },
        data: params
      }).then(function(response) {
        resolve(response);
      }, function(error) {
        reject(error);
      });
    });
  }

  this.getById = function() {
    // GET /api/protected/customRating/{id} - Get Rating
  }

  this.getRandomFeatured = function() {

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'api/protected/customRating/content-offer/random/rating',
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.getAllFeatured = function() {
    // console.log('ratingService', 'getAllFeatured');

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'api/protected/customRating/featured',
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function(response) {
        // console.log(response);
        response = response.map(function(item) {
          if (item.userName !== "" && item.offerName !== "" && item.userAvatar !== "" && item.comment !== "") {
            return item;
          } else {
            return null;
          }
        });
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.getAllFeaturedByOfferId = function(params) {
    // console.log('ratingService', 'getAllFeaturedByOfferId', params);

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'api/protected/customRating/featured/content-offer/' + params.offerId,
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.setFeaturedById = function(params) {
    // console.log('ratingService', 'setFeaturedById', params);

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + "api/protected/customRating/featured/" + params.offerId,
        type: 'PUT',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.getAllUnfeaturedByOfferId = function(params) {
    // console.log('ratingService', 'getAllUnfeaturedByOfferId', params);

    return new Promise(function(resolve, reject) {
      let RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: `${RDP_CONFIG.learningURL}api/protected/customRating/unFeatured/content-offer/${params.offerId}`,
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function(response) {
        resolve(response);
      }, function(jqXHR) {
        var reason;
        if (jqXHR.responseJSON) {
          reason = jqXHR.responseJSON;
        } else {
          reason = RDP_CONFIG.defaultReason;
        }

        reject(reason);
      });
    });
  }

  this.unsetFeaturedById = function() {
    // PUT /api/protected/customRating/unFeatured/{id} - Unset Feature
  }

  this.delete = function() {
    // DELETE /api/protected/customRating/{id} - DELETE
  }

  return this;
}

var ratingService = new Rating();

export {
  ratingService
};
