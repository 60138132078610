import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './error.css';

export class ViewError extends Component {
  componentDidMount(){
    document.title = "Erro - Page Compliance";
  }

  render() {
    var message;
    const { state } = this.props.location;

    if (state && state.message) {
      message = state.message;
    } else {
      message = 'Você já efetuou o primeiro acesso'
    }

    return (
      <div className="rdp-view rdp-view-error rdp-bg-squares">
        <h2 className="rdp-title-error">{message}</h2>
        <p>
          <Link to="/">Acessar o portal</Link>
        </p>
      </div>
    );
  }
}
