var Helpers = function() {
  //var _this = this;

  this.getNumberLabel = function(number) {
    var label;
    if (number === 0) label = "Zero";
    if (number === 1) label = "Um";
    if (number === 2) label = "Dois";
    if (number === 3) label = "Três";
    if (number === 4) label = "Quatro";
    if (number === 5) label = "Cinco";
    if (number === 6) label = "Seis";
    if (number === 7) label = "Sete";
    if (number === 8) label = "Oito";
    if (number === 9) label = "Nove";
    if (number === 10) label = "Dez";
    if (number === 11) label = "Onze";
    if (number === 12) label = "Doze";
    if (number === 13) label = "Treze";
    if (number === 14) label = "Quatorze";
    if (number === 15) label = "Quinze";
    if (number === 16) label = "Dezesseis";
    if (number === 17) label = "Dezessete";
    if (number === 18) label = "Dezoito";
    if (number === 19) label = "Dezenove";
    if (number === 20) label = "Vinte";
    if (number === 21) label = "Vinte e um";
    if (number === 22) label = "Vinte e dois";
    if (number === 23) label = "Vinte e três";
    if (number === 24) label = "Vinte e quatro";
    if (number === 25) label = "Vinte e cinco";
    if (number === 26) label = "Vinte e seis";
    if (number === 27) label = "Vinte e sete";
    if (number === 28) label = "Vinte e oito";
    if (number === 29) label = "Vinte e nove";
    if (number === 30) label = "Trinta";
    if (number === 31) label = "Trinta e um";
    if (number === 32) label = "Trinta e dois";
    if (number === 33) label = "Trinta e três";
    if (number === 34) label = "Trinta e quatro";
    if (number === 35) label = "Trinta e cinco";
    if (number === 36) label = "Trinta e seis";
    if (number === 37) label = "Trinta e sete";
    if (number === 38) label = "Trinta e oito";
    if (number === 39) label = "Trinta e nove";
    if (number === 40) label = "Quarenta";
    if (number === 41) label = "Quarenta e um";
    if (number === 42) label = "Quarenta e dois";
    if (number === 43) label = "Quarenta e três";
    if (number === 44) label = "Quarenta e quatro";
    if (number === 45) label = "Quarenta e cinco";
    if (number === 46) label = "Quarenta e seis";
    if (number === 47) label = "Quarenta e sete";
    if (number === 48) label = "Quarenta e oito";
    if (number === 49) label = "Quarenta e nove";
    if (number === 50) label = "Cinquenta";
    if (number === 51) label = "Cinquenta e um";
    if (number === 52) label = "Cinquenta e dois";
    if (number === 53) label = "Cinquenta e três";
    if (number === 54) label = "Cinquenta e quatro";
    if (number === 55) label = "Cinquenta e cinco";
    if (number === 56) label = "Cinquenta e seis";
    if (number === 57) label = "Cinquenta e sete";
    if (number === 58) label = "Cinquenta e oito";
    if (number === 59) label = "Cinquenta e nove";
    if (number === 60) label = "Sessenta";
    if (number === 61) label = "Sessenta e um";
    if (number === 62) label = "Sessenta e dois";
    if (number === 63) label = "Sessenta e três";
    if (number === 64) label = "Sessenta e quatro";
    if (number === 65) label = "Sessenta e cinco";
    if (number === 66) label = "Sessenta e seis";
    if (number === 67) label = "Sessenta e sete";
    if (number === 68) label = "Sessenta e oito";
    if (number === 69) label = "Sessenta e nove";
    if (number === 70) label = "Sessenta";
    if (number === 71) label = "Setenta e um";
    if (number === 72) label = "Setenta e dois";
    if (number === 73) label = "Setenta e três";
    if (number === 74) label = "Setenta e quatro";
    if (number === 75) label = "Setenta e cinco";
    if (number === 76) label = "Setenta e seis";
    if (number === 77) label = "Setenta e sete";
    if (number === 78) label = "Setenta e oito";
    if (number === 79) label = "Setenta e nove";
    if (number === 80) label = "Oitenta";
    if (number === 81) label = "Oitenta e um";
    if (number === 82) label = "Oitenta e dois";
    if (number === 83) label = "Oitenta e três";
    if (number === 84) label = "Oitenta e quatro";
    if (number === 85) label = "Oitenta e cinco";
    if (number === 86) label = "Oitenta e seis";
    if (number === 87) label = "Oitenta e sete";
    if (number === 88) label = "Oitenta e oito";
    if (number === 89) label = "Oitenta e nove";
    if (number === 90) label = "Noventa";
    if (number === 91) label = "Noventa e um";
    if (number === 92) label = "Noventa e dois";
    if (number === 93) label = "Noventa e três";
    if (number === 94) label = "Noventa e quatro";
    if (number === 95) label = "Noventa e cinco";
    if (number === 96) label = "Noventa e seis";
    if (number === 97) label = "Noventa e sete";
    if (number === 98) label = "Noventa e oito";
    if (number === 99) label = "Noventa e nove";
    if (number === 100) label = "Cem";
    return label;
  }
  return this;
}

var RDPHelpers = new Helpers();

export {
  RDPHelpers
};
