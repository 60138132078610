import React, {Component} from 'react';

import {RatingStars} from '../../components';

import './course-reviews.css';

export default class CourseReviews extends Component {
  render() {
    // console.log('CourseReviews', this.props);

    return (<section className="rdp-course-reviews">
      <div className="rdp-carousel-course-reviews">
        <div className="rdp-container">
          {
            this.props.reviews.map(function(review, i){
              return <div tabIndex="0" key={i} className="rdp-carousel-course-reviews-item">
                <RatingStars rating={review.score}/>

                <p className="rdp-review-text">{review.comment}</p>
                <p className="rdp-review-author">
                  {review.userSocialName || review.userName}
                  <small>{
                      review.userDepartment
                        ? review.userDepartment
                        : null
                    }</small>
                </p>
              </div>;
            })
          }
          {
            this.props.lastPage
              ? null
              : <button aria-label="Veja mais comentários" className="bt-ver-mais" onClick={this.props.onClickShowMore}>ver mais +</button>
          }
        </div>
      </div>
    </section>);
  }
}
