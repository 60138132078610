import React, { Component } from 'react';
import $ from 'jquery';

import {
  authenticationService,
  creditsService
} from '../../services';

import {
  Header,
  Footer,
  Navigation,
  Loader,
  ErrorDialog
} from '../../components';

import './credits.css';

export class ViewCredits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      policy: '',
      authed: authenticationService.getLocalUserData(),
      participants: []
    }
  }

  componentWillMount() {
    this.getParticipants();
  }

  componentDidMount() {
    document.title = "Créditos - Page Compliance";

    this.scrollToTop();
  }

  scrollToTop() {
    $('html, body').scrollTop(0);
  }

  getParticipants() {
    var _this = this;

    _this.setState({
      loading: true
    }, function() {
      creditsService.getCreditsParticipats().then(function(participants) {
        _this.setState({
          participants: [],
          loading: false
        });
      }, function(reason) {
        _this.setState({
          error: reason,
          loading: false
        });
      });
    });
  }

  render() {
    var loader, error, participants;
    var participantsModel = this.state.participants;

    if (this.state.loading) {
      loader = <Loader/>;
    }

    if (this.state.error) {
      error = <ErrorDialog error={this.state.error}/>;
    }

    if (participantsModel.length > 0) {
      participants = [];
      for (var i = 0; i <= participantsModel.length - 1; i++) {
        participants.push(<span key={i}>{participantsModel[i]}</span>);
      }
    }

    if (!this.state.authed) {
      return (
        <div className="rdp-view rdp-view-credits rdp-bg-squares">
          <section className="rdp-context-credits no-login">
            <div className="rdp-container">
              <h2 className="rdp-title-page">Créditos</h2>

              <div className="rdp-credits-context">
                <div className="rdp-credits-row">
                  <div className="senac-logo" alt="Logo Senac"></div>

                  <div className="rdp-credits-offering">
                    <h3>Realização</h3>
                    <span>Departamento Nacional do Senac</span>
                    <span>Diretoria de Educação Profissional</span>
                    <span>Gerência de Educação Corporativa</span>
                  </div>

                  <div className="rdp-credits-solutions">
                    <h3>Soluções Educacionais</h3>
                    <span>Senac São Paulo</span>
                  </div>
                </div>

                <div className="rdp-credits-divider"></div>

                <div className="rdp-credits-row">
                  <div className="dp-logo" alt="Logo Digital Pages"/>
                  <div className="rdp-credits-solutions">
                    <h3>Soluções Técnicas</h3>
                    <span>Digital Pages</span>
                  </div>
                </div>

                <div className="rdp-credits-divider"></div>

                <div className="rdp-credits-participants-row">
                  <h3>Participantes</h3>
                  <div className="rdp-credits-participants">{participants}</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    }

    return (
      <div className="rdp-view rdp-view-credits">
        {error}
        {loader}
        <Header noBgTransition={true}/>
        <div className="rdp-main">
          <section className="rdp-context-credits">
            <div className="rdp-container">
              <h2 className="rdp-title-page">Créditos</h2>

              <div className="rdp-credits-context">
                <div className="rdp-credits-row">
                  <div className="rdp-credits-offering">
                    <h3>Realização</h3>
                    <span>Departamento Nacional</span>
                    <span>Diretoria de Educação Profissional</span>
                    <span>Gerência de Educação Corporativa</span>
                  </div>

                  <div className="rdp-credits-solutions">
                    <h3>Soluções Educacionais</h3>
                    <span>São Paulo</span>
                  </div>
                </div>

                <div className="rdp-credits-divider"></div>

                <div className="rdp-credits-row">
                  <div className="dp-logo" alt="Logo Digital Pages"></div>

                  <div className="rdp-credits-solutions">
                    <h3>Soluções Técnicas</h3>
                    <span>Digital Pages</span>
                  </div>
                </div>

                <div className="rdp-credits-divider"></div>

                <div className="rdp-credits-participants-row">
                  <h3>Participantes</h3>
                  <div className="rdp-credits-participants">{participants}</div>
                </div>
              </div>
            </div>
          </section>
          <Navigation/>
        </div>
        <Footer/>
      </div>
    );
  }
}
