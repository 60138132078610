import { RDP_Utils } from '../utils';

export default class ActivityEvaluative {
  constructor(params) {
    this.user = params.user;
    this.config = params.config;
  }

  post(data) {
    let _this = this;

    return new Promise(function(resolve, reject) {
      RDP_Utils.request({
        url: _this.config.learningURL + 'restapi/api/protected/activity-evaluative/v1',
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + _this.user.authentication_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(data)
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }
}
