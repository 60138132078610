import React, {Component} from 'react';
import {RDP_CONFIG} from '../../config';
import {RDP_Utils} from '../../utils';

import './version.css';

export default class Version extends Component {
  render() {
    let devList = [];

    if (window.location.port === '3000') {
      devList.push(<span key='in-dev' className="in-dev">inDev</span>);
      devList.push(<span key='hostname' className="hostname">{window.location.hostname}</span>);

      if (RDP_Utils.inApp()) {
        devList.push(<span key="in-app" className="in-app">inApp</span>);

        if (RDP_Utils.hasCordova()) {
          devList.push(<span key="has-cordova" className="has-cordova">hasCordova</span>);

          if (RDP_Utils.connectionStatus()) {
            devList.push(<span key="conection-status" className="conection-status online">onLine</span>);
          } else {
            devList.push(<span key="conection-status" className="conection-status offline">offLine</span>);
          }
        }
      }

      if (RDP_Utils.isMobile.any()) {
        devList.push(<span key="in-mobile" className="in-mobile">{RDP_Utils.isMobile.any()[0]}</span>);
      }
    }

    return this.props.show
      ? <span className="rdp-version"><span className="version">Versão {RDP_CONFIG.version}</span>
          {devList}</span>
      : null;
  }
}
