/*global CordovaExec*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { RDP_CONFIG } from '../../config';
import { RDPHelpers } from '../../helpers';
import { RDP_Utils } from '../../utils';
import { authenticationService } from '../../services';

import { RatingStars, ModalRating } from '../../components';
import { ratingService } from '../../services';

import './card-course.css';

export default class CardCourse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isClickable: true,
      showModalRating: false,
      myRating: null
    }

    this.onClickDownloadCertificate = this.onClickDownloadCertificate.bind(this);
    this.onClickCloseModalRating = this.onClickCloseModalRating.bind(this);
    this.onSubmitRating = this.onSubmitRating.bind(this);
    this.onClickOpenModalRating = this.onClickOpenModalRating.bind(this);
  }

  onClickCloseModalRating() {
    this.setState({ showModalRating: false });
  }

  onClickOpenModalRating() {
    this.setState({ showModalRating: true });
  }

  onSubmitRating(rating) {
    let _this = this;
    let { offer } = this.props;
    let objRating = {
      comment: rating.review,
      score: rating.ratingStars,
      contentOfferId: offer.contentOfferId,
      featured: false
    }

    if (objRating.score > 0) {

      ratingService.insert(objRating).then(function (response) {
        _this.setState({ showModalRating: false, myRating: rating.ratingStars });
      }, function (reason) {
        _this.setState({
          showModalRating: false
        }, function () {
          _this.setState({ error: RDP_CONFIG.defaultReason });
        });
      });
    } else { }
  }

  onClickDownloadCertificate() {
    let { offer } = this.props;

    var id = offer.contentOfferId;
    var RDP_USER = authenticationService.getLocalUserData();
    let url = RDP_CONFIG.learningURL + 'api/protected/certificates/pagecompliance/content-offer/' + id;
    var fileName = "Certificado_Page_Compliance" + id + ".pdf";

    if (RDP_Utils.hasCordova()) {
      CordovaExec(function (certficatePath) {
        // console.log('certficatePath', certficatePath);

        CordovaExec(function (response) {
          // console.log('response', response);
        }, function (reason) {
          // console.log('reason', reason);
        }, "ShelfPlugin", "OpenUrl", [certficatePath]);
        // console.log("ShelfPlugin", "OpenUrl", [certficatePath]);
      }, function (reason) {
        // console.log('reason', reason);
      }, "LearningPlugin", "Request", [
          {
            "url": url,
            "method": "GET",
            "cacheEnabled": true,
            "oneWay": false,
            "resultType": "Path",
            "fileName": fileName
          }
        ]);

    } else {
      var req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.setRequestHeader('Authorization', 'Bearer ' + RDP_USER.authentication_token);
      if (RDP_CONFIG.azureApisHeaderParam !== undefined && RDP_CONFIG.azureApisHeaderParam !== null) {
        req.setRequestHeader(Object.keys(RDP_CONFIG.azureApisHeaderParam)[0], Object.values(RDP_CONFIG.azureApisHeaderParam)[0]);
      }

      req.responseType = "blob";
      req.onload = function (event) {
        var blob = req.response;
        //var userAgent = window.navigator.userAgent;

        //TODO fallback needed for IE8 & IE9
        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
          //IE 10+
          window.navigator.msSaveBlob(blob, fileName);
        } else if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) {
          var file = new Blob([blob], { type: 'application/pdf' });
          var fileURL = window.URL.createObjectURL(file);
          window.location.href = fileURL;
        } else {
          //Firefox, Chrome
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.style = "display: none";
          link.download = fileName;
          link.click();
          document.body.removeChild(link);
        }
      };

      req.send();
    }
  }

  render() {
    // console.log('CardCourse', 'render', this.props.offer);

    let { offer } = this.props;
    let { showModalRating, myRating } = this.state;

    let indexProgress = this.props.indexProgress ? 0 : -1

    // console.log('loggedUserRating', offer.loggedUserRating);

    var {
      aux,
      isClickable
    } = '';
    if (offer) {
      aux = offer.offerStartAt || offer.treeOffer.offerStartAt;
      isClickable = (new Date() > new Date(aux));
    }

    var cardAction,
      cardLabel,
      cardRating,
      cardLinkCertificate,
      cardCategory = [],
      comingSoon,
      progressLabel;

    // Download do certificado
    if (RDP_Utils.onLine()) {
      cardLinkCertificate = (<button onClick={this.onClickDownloadCertificate} className="rdp-ico-medal rdp-download-certificate" title="Baixar certificado">
        <span>Baixar certificado</span>
      </button>);
    }

    // Avaliação
    if (offer.rating !== undefined) {
      // console.log(offer.contentOfferId, offer.progress, offer.rating);
      // console.log(offer.rating);
      cardRating = <RatingStars offer={offer} type="visible" rating={offer.rating} />;
    }

    if (offer.loggedUserRating) {
      cardRating = <RatingStars offer={offer} type="visible" rating={offer.loggedUserRating.score} />;
    }

    if (myRating) {
      cardRating = <RatingStars offer={offer} type="visible" rating={myRating} />;
    }

    let cardToRating = <span className="rdp-course-rating rdp-information-item" onClick={this.onClickOpenModalRating}>Avaliar curso</span>;

    // Progresso
    if (offer.progress !== undefined) {
      if (new Date() < new Date(offer.offerStartAt)) {
        cardLabel = <span tabIndex={0} aria-hidden="true" aria-label="Status do curso:" className="rdp-card-label rdp-soon">Em Breve</span>;
        comingSoon = true;
      } else if ((offer.progress * 100) < 1) {
        if (cardRating) {
          cardAction = <div>
            <span className="rdp-fake-progress">Você ainda não iniciou este curso. Para conhecer mais detalhes, entre na página do curso.</span>
            <div className="rdp-note-certificate">{cardRating}</div>
          </div>;
        }
      } else if ((offer.progress * 100) > 1 && (offer.progress * 100) < RDP_CONFIG.offerProgressToDone) {
        var progress = `${parseInt((offer.progress * 100), 10)}`;
        var accessibilityProgress = RDPHelpers.getNumberLabel(parseInt(progress, 10)) + " porcento";

        var style = {
          width: progress + "%"
        };

        cardAction = (<div className="rdp-progress">
          <span aria-hidden="true">{progress}%</span>
          <span className="rdp-fake-progress" style={style}>{`Você já iniciou este curso. Seu progresso é de ${accessibilityProgress}`}</span>
        </div>);

        progressLabel = (<div className="rdp-progress-view">
          <div className="rdp-progress-view-line">
            <div className="rdp-progress-view-line-color" style={{width: progress + "%"}}></div>
          </div>
          <span className="rdp-progress-view-label">{progress}%</span>
        </div>);

        cardLabel = <span aria-hidden="true" aria-label="Status do curso: Em andamento" className="rdp-card-label rdp-in-progress">Em andamento</span>;
      } else {
        // console.log('concluido', offer.id, offer.loggedUserRating);
        if (cardRating || cardLinkCertificate) {
          cardAction = (<div>
            <span className="rdp-fake-progress">Você já finalizou este curso.</span>
            <div className="rdp-note-certificate">
              {offer.loggedUserRating || myRating ? cardRating : cardToRating}

              {cardLinkCertificate}
              {
                showModalRating
                  ? <ModalRating onClickCloseModalRating={this.onClickCloseModalRating} onSubmitRating={this.onSubmitRating} view="home" />
                  : null
              }
            </div>
          </div>);
        }

        progressLabel = (<div className="rdp-progress-view">
          <div className="rdp-progress-view-line">
            <div className="rdp-progress-view-line-color progress-done" style={{width: "100%"}}></div>
          </div>
          <span className="rdp-progress-view-label">100%</span>
        </div>);

        cardLabel = <span aria-hidden="true" aria-label="Status do curso: Concluído" className="rdp-card-label rdp-completed">Concluído</span>;
      }
    }

    // cardLabel = ""

    let listDescCategory = ''
    // Categoria
    if (offer.categories) {
      if (offer.categories.length > 0) {
        cardCategory = offer.categories.map(function (category, index) {
          listDescCategory = listDescCategory + category.name
          if (index < (offer.categories.length - 2)) {
            listDescCategory = listDescCategory + ', '
          } else {
            if (index < (offer.categories.length - 1)) {
              listDescCategory = listDescCategory + ' e '
            }
          }
          return <span key={index} className={"rdp-card-category rdp-category rdp-card-tag " + (
            comingSoon
              ? "rdp-card-coming-soon"
              : "")} style={{
                backgroundColor: category.color
              }}>{category.name}</span>
        });
      } else {
        cardCategory.push(<span key='0' className={`rdp-card-category rdp-category rdp-card-tag ${comingSoon
          ? 'rdp-card-coming-soon'
          : ''}`} style={{
            opacity: 0,
            pointerEvents: 'none'
          }}>Nenhuma Tag</span>);
      }
    } else {
      cardCategory.push(<span key='0' className={`rdp-card-category rdp-category rdp-card-tag ${comingSoon
        ? 'rdp-card-coming-soon'
        : ''}`} style={{
          opacity: 0,
          pointerEvents: 'none'
        }}>Nenhuma Tag</span>);
    }

    let descCargaHoraria = offer.workload > 1 ? ' horas' : ' hora'
    let descCurso = "Curso " + offer.offerName + ", Áreas: " + listDescCategory + " - " + offer.description + " - Carga horária de " + offer.workload + descCargaHoraria

    return <div data-treeoffer-id={offer.contentOfferId} data-offer-id={offer.id} className="component-card-course rdp-card-courses-container">
      <div className={'rdp-card ' + (
        comingSoon
          ? 'rdp-card-coming-soon rdp-none-events'
          : '')}>
        <Link tabIndex="0" aria-label={descCurso} to={`/course/${offer.id}`}>{cardLabel}</Link>
        <Link tabIndex="0" aria-label={offer.description} className="rdp-card-link" to={isClickable
          ? `/course/${offer.id}`
          : ''}>
          <div className="rdp-course-image-container">
            <img src={offer.thumbnail} alt={offer.offerName} />

            {/*<p className="rdp-course-details">
              {offer.description}
              <span className="rdp-ico-time rdp-course-time">{offer.workload}</span>
              <span className="rdp-course-details-text">Detalhes</span>
            </p>*/}
            <h3 aria-label="Título do curso" className="rdp-card-title">
              <Link tabIndex="-1" to={`/course/${offer.id}`}>{offer.offerName}</Link>
            </h3>
            {progressLabel}
          </div>
        </Link>

        
        
        {/*<div tabIndex={indexProgress} className="rdp-card-actions">
          {cardAction}
        </div>*/}
        {cardCategory}
      </div>
    </div>;
  }
}
