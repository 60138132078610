import React, { Component } from 'react';

import './player.scss';

export default class Player extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focusContent: true
    }

    this.refContent = React.createRef();
  }

  componentDidMount() {
    const { focus } = this.props
    this.setState({ focusContent: focus })

    console.log('focus', focus)
  }


  componentDidUpdate() {
    // console.log(this.refModal);
    const { focus } = this.props
    if (focus) {
      if (this.refContent.current) {
        this.refContent.current.focus();
      }
    }
  }

  componentWillUnmount() {
    const data = this.state.analytics;

    if (data) {
      data.cmi.session_end = Date.now();
      this.props.RDPAnalytics.track(data);
    }
  }

  receiveMessage = e => {
    const { activity } = this.props;

    if (e.data.includes('scorm_variables') && activity.assessment) {
      const { scorm_variables, rise_data } = JSON.parse(e.data);
      this.props.trackProgressAvailable(scorm_variables, rise_data);
    }
  }

  handleIframeLoad = e => {
    this.props.handleIframeLoad(e);
    let { activity } = this.props;

    let lesson_status = null;
    if (typeof activity.interaction === "object") {
      lesson_status = activity.interaction.status;
    }

    this.setState({
      analytics: {
        context: {
          activityId: activity.id
        },
        cmi: {
          session_start: Date.now(),
          lesson_status
        }
      }
    }, () => {
      document.getElementById('scorm-frame').contentWindow.postMessage('RDPEvent:initialize', '*');

      window.removeEventListener('message', this.receiveMessage);
      window.addEventListener('message', this.receiveMessage);
    })
  }

  getUrlActivity(activity) {
    let url = '';

    try {
      url = JSON.parse(activity.properties).url;
      url = url.replace('.zip', '/index.html');
    } catch (e) { } finally { }

    if (activity.assessment) {
      return `https://projetos.dpcontent.com.br/scorm/api/scorm_wrapper_v1.html?url=${url}`;
    }

    return url;
  }

  render() {
    let { activity } = this.props;

    let urlActivity = this.getUrlActivity(activity);

    return <div ref={this.refContent} tabIndex="1" className="rdp-player-container" data-name="player">{
      urlActivity
        ? <iframe sandbox="allow-scripts allow-forms allow-same-origin allow-pointer-lock allow-presentation allow-modals allow-popups allow-popups-to-escape-sandbox" id="scorm-frame" title={activity.title} onLoad={this.handleIframeLoad} className="rdp-player-iframe" src={urlActivity}></iframe>
        : null
    }</div>
  }
}
