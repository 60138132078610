import React, { Component } from 'react';
import { Redirect } from 'react-router';

import { ButtonArrow } from '../../components';

// import { RDP_Utils } from '../../utils';

import './modal.css';

export default class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null
    }

    this.refModal = React.createRef();
    this.openOffer = this.openOffer.bind(this);
  }

  componentDidUpdate(){
    // console.log(this.refModal);

    if (this.refModal.current) {
      this.refModal.current.focus();
      let item = document.getElementById('rdp-modal-course-btn-sim')
      console.log('item', item)
      item.focus();
    }

  }

  openOffer() {
    // var activityId = 'first';

    this.setState({
      redirectTo: '/edition/' + this.props.offerId + '/atividade/first'
    }, function() {
      this.props.toggle(false);
    });
  }

  setFocusElement = (id) => {
    if (this.props.opened) {
      let item = document.getElementById(id)
      item.setAttribute("tabIndex", "0");
      item.focus();
    }
  }

  render() {
    var {
      opened,
      toggle
    } = this.props;

    var isOpened = opened ? ' rdp-active' : '';

    if (this.props.opened) {
      // console.log(this.state.redirectTo);
      if (this.state.redirectTo) {
        return <Redirect to={this.state.redirectTo}/>
      } else {
        return (
          <div role="dialog" ref={this.refModal} tabIndex="-1" className={'rdp-modal' + isOpened}>
            <div className="rdp-modal-content">
              <div role="button" aria-pressed="false" className="rdp-modal-info">
                <h2 id="rdp-modal-content" tabIndex="-1" className="rdp-modal-title">
                  <span id="rdp-modal-content-title">Confirmar início no curso? <small>{this.props.offerName}</small></span>
                  
                  <div className="rdp-form-group rdp-form-actions">
                    <ButtonArrow
                      back="back"
                      tabIndex="0"
                      type="text"
                      onClick={() => toggle(false)}
                      textButton="Não"
                      className="rdp-bt-action rdp-btn-arrow-gray rdp-btn-back"
                    />

                    <ButtonArrow
                      type="text"
                      tabIndex="0"
                      id="rdp-modal-course-btn-sim"
                      onClick={this.openOffer}
                      textButton="Sim"
                      className="rdp-bt-action rdp-btn-arrow-orange"
                    />
                  </div>
                  <div tabIndex="0" onFocus={()=>this.setFocusElement('rdp-modal-content-title')}></div>

                </h2>
                {/* <button aria-label="Fechar" className="rdp-btn-close rdp-ico-close" onClick={() => toggle(false)}>
                  <span className="rdp-sr-only">fechar</span>
                </button> */}
              </div>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  }
}
