import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {authenticationService} from '../../services';
import {RDP_Utils} from '../../utils';
import $ from 'jquery';
import './dropdown-system.css';

// import imageAvatar from './avatar.svg';

export default class DropdownSystem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpened: false
    };

    this.toggleDropdownSystem = this.toggleDropdownSystem.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  toggleDropdownSystem(event) {
    event.stopPropagation();

    this.setState({
      dropdownOpened: !this.state.dropdownOpened
    });
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick(event) {
    // console.log('handleClick', event.target);
    event.stopPropagation();

    if (this.node2.contains(event.target)) {
      this.setState({dropdownOpened: true});
    } else {
      if ($(event.target).hasClass('rdp-ico-close')) {
        return;
      }
      this.setState({dropdownOpened: false});
    }
  }

  render() {
    var user = authenticationService.getLocalUserData();

    if (user) {
      return (<div onClick={this.handleClick} className={`rdp-dropdown-system ${this.state.dropdownOpened
          ? 'rdp-active'
          : ''}`}>
        <button className={`rdp-dropdown-button ${this.state.dropdownOpened
            ? 'rdp-active rdp-ico-close'
            : 'rdp-ico-menu'}`} onClick={this.toggleDropdownSystem} aria-label="Menu do sistema">
          <span className="rdp-sr-only">Menu do sistema</span>
        </button>

        <div ref={node => this.node = node} className="rdp-dropdown-container">
          <ul className="rdp-dropdown-list" ref={node2 => this.node2 = node2}>
            <li className="rdp-dropdown-list-item">
              <NavLink tabIndex="0" className="list-item-link" to="/">Todos os cursos</NavLink>
            </li>
            <li className="rdp-dropdown-list-item">
              <NavLink tabIndex="0" className="list-item-link" to="/faq">Perguntas frequentes</NavLink>
            </li>
            {
              RDP_Utils.onLine()
                ? <li className="rdp-dropdown-list-item">
                    <NavLink tabIndex="0" className="list-item-link" to="/support">Suporte</NavLink>
                  </li>
                : null
            }
            <li className="rdp-dropdown-list-item">
              <NavLink tabIndex="0" className="list-item-link" to="/terms-of-use">Termos de uso</NavLink>
            </li>
            <li className="rdp-dropdown-list-item">
              <NavLink tabIndex="0" className="list-item-link" to="/about">Sobre</NavLink>
            </li>
            <li className="rdp-dropdown-list-item">
              <NavLink tabIndex="0" className="list-item-link" to="/privacy-policy">Política de privacidade</NavLink>
            </li>
            {/* <li className="rdp-dropdown-list-item">
              <NavLink tabIndex="0" className="list-item-link" to="/credits">Créditos</NavLink>
            </li> */}
          </ul>
        </div>
      </div>);
    } else {
      return null;
    }
  }
}
