import React, { Component, Fragment } from 'react';

export default class CustomInputFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fakeLabel: 'Anexar arquivo',
      validArchive: false
    }

    this.onImageChange = this.onImageChange.bind(this);
    this.resetInput = this.resetInput.bind(this);
  }

  onImageChange(event) {
    var _this = this;

// console.log(event.target.files);
    if (event.target.files) {
      _this.setState({
        validArchive: true,
        fakeLabel: event.target.files[0].name
      });

      if (!!_this.props.onImageChange) {
        _this.props.onImageChange(event.target);
      } else {
        // console.log('file was selected but no callback was set.', event.target);
      }
    }
  }

  resetInput(event) {
    var _this = this;
    _this.setState({ validArchive: false });
  }

  render() {
    return (
      <Fragment>
        <label htmlFor="file" className="rdp-sr-only">Selecione para anexar arquivos ponto jpg ou ponto png</label>
        <div className="rdp-custom-input-file">
          <input
            type="file"
            id="file"
            onChange={this.onImageChange}
            className="rdp-form-control"
            name="file"
            accept="image/*"
          />
        </div>
        <p className="rdp-text-help">Anexar arquivos .jpg ou .png</p>
      </Fragment>
    );
  }
}
