/*global CordovaExec*/
import $ from 'jquery';
import {RDP_CONFIG} from './config';

var Utils = function() {
  var _this = this;

  this.isValid = function(form) {
    let required = true;
    let validity = true;

    Object.entries(form).forEach(function(item) {
      let element = item[1];

      // elementos que importam
      if (element.name && element.name !== '') {
        // obrigatórios
        if (element.required && (element.value === '' || element.value === null)) {
          required = false;
        }

        // formato válido
        if (element.type === 'email') {
          validity = element.value.indexOf('@') > 1;
        }
      }
    });

    return required && validity;
  }

  this.getOfferProgressFromActivities = function(offer) {
    // console.log('RDP_Utils', 'getOfferProgressFromActivities', offer);

    var totalProgress = 0;

    let getActivitiesInOrffer = function(offer) {
      // console.log('RDP_Utils', 'getActivitiesInOrffer', offer);

      let activities = [];

      if (offer.hasOwnProperty('treeOffer')) {
        var treeOffer = offer.treeOffer;

        if (treeOffer.hasOwnProperty('contexts')) {
          treeOffer.contexts.map(function(context, index) {
            if (context.hasOwnProperty('nodes')) {
              context.nodes.map(function(node, index) {
                if (node.hasOwnProperty('activities')) {
                  node.activities.map(function(activity, index) {
                    activities.push(activity);

                    return activity;
                  });
                }

                return node;
              });
            }

            return context;
          })
        }
      }

      return activities;
    }

    var activities = getActivitiesInOrffer(offer);

    var proportion = 100 / activities.length;

    // console.log('RDP_Utils', activities);

    activities.map(function(activity) {
      if (activity.hasOwnProperty('interaction') && activity.interaction.hasOwnProperty('status')) {
        if (activity.interaction.status.toLowerCase() === 'on_going') {
          totalProgress += (proportion / 2);
        } else if (activity.interaction.status.toLowerCase() === 'done') {
          totalProgress += proportion;
        }
      }

      return activity;
    });

    // console.log('RDP_Utils', offer.id, 'activities.length:' + activities.length, 'proportion: ' + proportion, 'totalProgress: ' + totalProgress);

    totalProgress = totalProgress / 100 > 1
      ? 1
      : totalProgress / 100;

    // console.log('RDP_Utils', totalProgress, offer.progress);
    if (totalProgress > offer.progress) {
      return totalProgress;
    } else {
      return offer.progress;
    }
  }

  this.isMobile = {
    Android: function() {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
      return (_this.isMobile.Android() || _this.isMobile.BlackBerry() || _this.isMobile.iOS() || _this.isMobile.Opera() || _this.isMobile.Windows());
    }
  };

  this.objectToFormWithAttach = function(params) {

    if (params.description && params.description.value) {
      params.description.value = encodeURIComponent(params.description.value);
    }

    let queryParams = '?';
    let formData = new FormData();

    Object.entries(params).forEach(function(item, index) {
      let element = item[1];
      if (element.name) {
        if (index > 0 && index < Object.entries(params).length - 1) {
          queryParams += '&';
        }

        if (element.name !== 'file') {
          queryParams += element.name + '=' + element.value;
        }

        if (element.name === 'file') {
          if (element.files.length > 0) {
            formData.append('file', element.files[0], element.files[0].name);
          }
        }
      }
    });

    // remove caracter adicional
    if (queryParams[queryParams.length - 1] === "&") {
      queryParams = queryParams.substring(0, queryParams.length - 1);
    }

    return {formData: formData, queryParams: queryParams}
  }

  this.getCookie = function(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  this.hasStorageReference = function() {
    return localStorage.getItem('iammobile');
  }

  this.hasCookie = function() {
    return this.getCookie('iammobile');
  }

  this.hasCordova = function() {
    return window.hasOwnProperty('cordova');
  }

  this.inApp = function() {
    if (this.hasCookie() || this.hasCordova() || this.hasStorageReference()) {
      localStorage.setItem('iammobile', true);
      window.iammobile = true;
    } else {
      window.iammobile = false;
    }

    return window.iammobile;
  }

  this.onLine = function() {
    return window.online === 'true' || window.online === true;
  }

  this.connectionStatus = function() {
    return window.online === 'true' || window.online === true;
  }

  this.request = function(params) {
     //console.log(params);

    if (RDP_CONFIG.azureApisHeaderParam !== undefined && RDP_CONFIG.azureApisHeaderParam !== null){
      let _header = {};
      Object.assign(_header, RDP_CONFIG.azureApisHeaderParam);
      if (params.headers !== undefined)
        Object.assign(_header, params.headers);
        params.headers = _header;
    }

    return new Promise(function(resolve, reject) {
      // console.log(params);
      var onlyAjax = true;
      if (onlyAjax === true || !_this.inApp() || (params.hasOwnProperty('xhrType') && params.xhrType === 'ajax')) {
        $.ajax(params).done(function(response) {
          // console.log('SUCCESS Ajax', response);
          resolve(response);
        }).fail(function(jqXHR) {
          // console.log('ERROR Ajax:', jqXHR);

          var reason;
          if (jqXHR.responseJSON) {
            reason = jqXHR.responseJSON;
          } else {
            reason = RDP_CONFIG.defaultReason;
          }

          reject(reason);
        });
      } else {
        var plugin = params.class
          ? params.class
          : "ManagerApiPlugin";
        var method = params.method
          ? params.method
          : "Process";

        var paramsPost = requestUtil.extractMobileParameters(params.data, "GetOrPost");
        var headers = requestUtil.extractMobileParameters(params.headers, "Header");
        var paramsGet = requestUtil.extractUrlParameters(params.url);

        params.pathname = requestUtil.extractPathName(params.url);
        params.method = requestUtil.extractMobileMethod(params);
        params.parameters = paramsPost.concat(headers).concat(paramsGet);
        // params.updateCacheIfPossible = true;  TODO: melhorar a abordagem
        params.ignoreCache = requestUtil.tempIgnoreCache(params); // TODO: melhorar a abordagem

        //remove conteúdo já importado
        delete params.data;
        delete params.headers;
        delete params.url;
        delete params.type;

        CordovaExec(function(response) {
          response = JSON.parse(response);
          // console.log('SUCCESS Cordova:', response, "request: ", params);

          resolve(response);
        }, function(reason) {
          // console.log('ERROR Cordova:', reason);

          try {
            reason = JSON.parse(reason);
          } catch (e) {
            reason = RDP_CONFIG.defaultReason;
          } finally {}

          reject(reason);
        }, plugin, method, [params]);

        // console.log('REQUEST Cordova:', params);
      }
    });
  }

  this.AppReady = function() {
    return new Promise(function(resolve, reject) {
      if (RDP_Utils.inApp()) {
        let timer = setInterval(function() {
          if (document.readyState === 'complete' && RDP_Utils.hasCordova() && window.cordova.hasOwnProperty('exec')) {
            clearInterval(timer);

            resolve();
          }
        }, 1000);
      } else {
        // console.log('else');
        document.addEventListener('DOMContentLoaded', resolve);
      }
    });
  }

  var requestUtil = {};

  requestUtil.map = {};

  requestUtil.tempIgnoreCache = function(params) {
    var key = JSON.stringify(params);
    var request = requestUtil.map[key];

    if (request) {
      var diff = Date.now() - request;

      if (diff < 1000 * 60 * 10) {
        return true;
      }
    }

    requestUtil.map[key] = Date.now();
    return false;
  }

  requestUtil.extractPathName = function(url) {
    var urlObj = new URL(url);
    return urlObj.pathname;
  };

  requestUtil.extractMobileMethod = function(params) {
    if (params.type === "POST" && params.contentType !== false) {
      return "POSTJSON";
    }

    if (params.type === "PATCH") {
      return "PATCHJSON";
    }

    return params.type;
  }

  requestUtil.extractMobileParameters = function(object, type) {
    var result = [];

    if (typeof object === "string") {
      object = JSON.parse(object);
    }

    for (var key in object) {
      //Deve ser adicionado pela a aplicação
      if (type === "Header" && key === "Authorization")
        continue;

      var data = {
        "name": key,
        "value": object[key],
        "type": type
      };
      result.push(data);
    }

    return result;
  }

  this.openUrl = function(url) {
    if (this.inApp()) {
      CordovaExec(function(response) {
        // console.log('response', response);
      }, function(reason) {
        // console.log('reason', reason);
      }, "ShelfPlugin", "OpenUrl", [url]);
    // console.log("ShelfPlugin", "OpenUrl", [url]);
    } else {
      window.open(url, '_blank');
    }
  }

  requestUtil.extractUrlParameters = function(url) {
    // console.log("url: " + url);

    var urlObj = new URL(url);
    var result = [];

    if (urlObj.search) {
      var allParams = urlObj.search.replace("?", "");
      var params = allParams.split("&");

      for (var i = 0; i < params.length; i++) {
        var entry = params[i].split("=");
        var obj = {
          "name": entry[0],
          "value": entry[1],
          "type": "GetOrPost"
        };

        result.push(obj);
      }
    }

    return result;
  }

  this.setup = function() {
    _this.inApp();
  }

  this.setup();

  return this;
}

var RDP_Utils = new Utils();

export {
  RDP_Utils
};
