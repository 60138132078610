import React, { Component } from 'react';
import './modal-terms.css';

import { termsService } from '../../services';

export default class ModalTerms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      terms: '',
    }

    this.agreeTerms = this.agreeTerms.bind(this);
  }

  componentWillMount() {
    this.getTerms();
  }

  getTerms() {
    var _this = this;

    _this.setState({ loading: true }, function() {
      termsService.getTerms().then(function(terms) {
        _this.setState({
          terms,
          loading: false
        });
      }, function(reason) {
        _this.setState({
          error: reason,
          loading: false
        });
      });
    });
  }

  agreeTerms() {
    if (this.props.toggleModal) {
      this.props.toggleModal(null, false);
    }
  }

  render() {
    return (
      <div className="rdp-modal-terms">
        <div className="rdp-modal-content">
          <div className="rdp-modal-header">
            <h2>Termos de uso</h2>
          </div>

          <div className="rdp-modal-body" dangerouslySetInnerHTML={{__html :this.state.terms}}></div>

          <div className="rdp-modal-footer">
            <button onClick={this.agreeTerms} className="rdp-btn-accept">Concordo</button>
          </div>
        </div>
      </div>
    );
  }
}
