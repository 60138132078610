import {RDP_Utils} from '../utils';
import {RDP_CONFIG} from '../config';
import {authenticationService} from './authentication';

// import {mockService} from './mock';

var Offer = function() {
  // var _this = this;

  this.getAll = function(params) {
    // console.log('offerService', 'getAll', params);

    // loadActivities e loadNodes estão ativos para ajudar no calculo de progresso
    params['loadActivities'] = params.loadNodes || false;
    params['loadNodes'] = params.loadNodes || false;
    params['language'] = 'PT_BR';
    params['size'] = '6';

    return new Promise(function(resolve, reject) {
      var RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/customContentOffers/v2/getCardOffersByUserPageable',
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        },
        data: params,
        ignoreCache: false,
        updateCacheIfPossible: true,
        name: 'getCardOffersByUserPageable'
      }).then(function(response) {
        //  trecho para garantir que o progresso da oferta reflita o progresso do player
        // response.content.map(function(offer) {
        //   offer.progress = RDP_Utils.getOfferProgressFromActivities(offer);
        //   return offer;
        // });
        //
        //  ordena por data
        // if (params.hasOwnProperty('orderBy') && params.orderBy) {
        //   var type = params.orderBy.split(' ')[0];
        //   var direction = params.orderBy.split(' ')[1];
        //
        //   response.content.sort(function(a, b) {
        //     if (direction === 'asc') {
        //       if (a.treeOffer[type] > b.treeOffer[type]) {
        //         return 1;
        //       }
        //       if (a.treeOffer[type] < b.treeOffer[type]) {
        //         return -1;
        //       }
        //     }
        //
        //     return 0;
        //   });
        // }

        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.postActivitiesHistory = function(params) {
    // console.log(params);
    return new Promise(function(resolve, reject) {
      var RDP_USER = authenticationService.getLocalUserData();

      // params['loadActivities'] = false;
      // params['loadNodes'] = false;
      // params['language'] = 'PT_BR';

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/lastActivity',
        contentType: 'application/json',
        type: 'POST',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        },
        data: JSON.stringify(params),
        name: 'lastActivity'
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.getLastAccessedActivity = function(params) {
    // console.log(params);
    return new Promise(function(resolve, reject) {
      var RDP_USER = authenticationService.getLocalUserData();

      // params['loadActivities'] = false;
      // params['loadNodes'] = false;
      // params['language'] = 'PT_BR';
      // console.log("params", params);

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'api/protected/lastActivity/' + params,
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        },
        ignoreCache: true,
        name: 'lastActivity'
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.getHighlights = function(params) {
    // console.log('offerService', 'getHighlights', params);

    return new Promise(function(resolve, reject) {
      var RDP_USER = authenticationService.getLocalUserData();
      // console.log('offerService', RDP_USER);
      params['loadActivities'] = false;
      params['loadNodes'] = false;
      params['language'] = 'PT_BR';

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/customContentOffers/getCardOffersByUserPageable',
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        },
        data: params,
        ignoreCache: true,
        name: 'getCardOffersByUserPageable'
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.getSuggestions = function(params) {
    // console.log('offerService', 'getSuggestions', params);

    return new Promise(function(resolve, reject) {
      params['loadActivities'] = false;
      params['loadNodes'] = false;
      params['language'] = 'PT_BR';

      var RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/customContentOffers/getCardOffersByUserPageable',
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        },
        data: params,
        ignoreCache: true,
        name: 'getCardOffersByUserPageable'
      }).then(function(response) {
        // response.content.map(function(offer) {
        //   offer.progress = RDP_Utils.getOfferProgressFromActivities(offer);
        //   return offer;
        // });

        var dateToday = new Date();
        var _responseFiltered = [];

        for (var i = 0; i < response.content.length; i++) {
          // verifica se a oferta está dentro do periodo vingente
          if ((response.content[i].progress * 100) < 1 && dateToday > new Date(response.content[i].treeOffer.offerStartAt)) {
            if (params.hasOwnProperty('currentOfferId')) {

              // evita que a propria oferta seja adicionado a lista de sugestões
              if (Number(params.currentOfferId) !== response.content[i].id) {
                _responseFiltered.push(response.content[i]);
              }
            } else {
              _responseFiltered.push(response.content[i]);
            }
          }
        }

        response.content = _responseFiltered;

        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.getById = function(params) {
    // console.log('offerService', 'getById', params);

    return new Promise(function(resolve, reject) {
      var RDP_USER = authenticationService.getLocalUserData();

      params['loadActivities'] = params.loadNodes || false;
      params['loadNodes'] = params.loadNodes || false;

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/customContentOffers/getFullOffersByUserAndOfferedId/' + params.id,
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        },
        data: params,
        ignoreCache: false,
        updateCacheIfPossible: true,
        name: 'getFullOffersByUserAndOfferedId'
      }).then(function(response) {
        if (response.length > 0) {
          //response[0].progress = RDP_Utils.getOfferProgressFromActivities(response[0]);
          resolve(response[0]);
        } else {
          reject(RDP_CONFIG.defaultReason);
        }
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.getByTerm = function(params) {
    console.log('offerService', 'getByTerm', params);
    return new Promise(function(resolve, reject) {
      params['loadActivities'] = false;
      params['loadNodes'] = false;
      params['language'] = 'PT_BR';
      params['size'] = '1000';
      params['q'] = params.search;

      var RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/customContentOffers/v2/getCardOffersByUserPageable',
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        },
        data: params,
        ignoreCache: true,
        name: 'getCardOffersByUserPageable'
      }).then(function(response) {
        resolve(response.content);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.getEnrolled = function(params) {
    // console.log('offerService', 'getEnrolled', params);

    return new Promise(function(resolve, reject) {
      params['loadActivities'] = false;
      params['loadNodes'] = false;
      params['language'] = 'PT_BR';
      params['curso'] = '1';
      params['size'] = '1000';

      // var filterByEnrolled = function(list) {
      //   // console.log('offerService', 'filterByEnrolled', list);
      //
      //   var result = [];
      //
      //   for (var i = 0; i < list.length; i++) {
      //     var item = list[i];
      //
      //     // Possui Progresso
      //     if (item.progress !== undefined && item.progress > 0) {
      //       // item já está disponivel
      //       if (item.offerStartAt < (new Date())) {
      //         result.push(item);
      //       }
      //     }
      //   }
      //
      //   return result;
      // }

      var RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/customContentOffers/v2/getCardOffersByUserPageable',
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        },
        ignoreCache: true,
        name: 'getCardOffersByUserPageable',
        data: params
      }).then(function(response) {
        // response.content.map(function(offer) {
        //    console.log('offerService', offer.progress.toString());
        //   offer.progress = RDP_Utils.getOfferProgressFromActivities(offer);
        //    console.log('offerService', offer.progress.toString());
        //   return offer;
        // });
        resolve(response.content);
        //resolve(filterByEnrolled(response.content));
      }, function(reason) {
        reject(reason);
      });
    });
  }

  this.getAllCategories = function() {
    // console.log('offerService', 'getAll', params);

    return new Promise(function(resolve, reject) {
      var RDP_USER = authenticationService.getLocalUserData();

      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/category',
        type: 'GET',
        xhrType: 'ajax',
        headers: {
          'Authorization': 'Bearer ' + RDP_USER.authentication_token
        }
      }).then(function(response) {
        resolve(response._embedded.categories);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  return this;
}

var offerService = new Offer();

export {
  offerService
};
