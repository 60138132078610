import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';

import './header-with-content.css';

import { offerService } from '../../services';

export default class HeaderWithContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrolled: false,
      totalCourses: 0,
    }

    this.updateHeader = this.updateHeader.bind(this);
  }

  componentDidMount() {
    if (!this.props.noBgTransition) {
      window.addEventListener('scroll', this.updateHeader);
    }

    let params = {
      orderBy: 'offerStartAt asc',
      size: 6,
      page: 0
    }


    offerService.getAll(params).then(offers => {
      console.log('offers', offers.content.length)
      this.setState({ totalCourses: offers.content.length })
    });

    if (this.props.initialPage) {
      let item = document.getElementById("rdp-header-startPage")
      item.setAttribute("tabindex", "1");
      item.focus();
    }

  }

  componentWillUnmount() {
    if (!this.props.noBgTransition) {
      window.removeEventListener('scroll', this.updateHeader);
    }
  }

  updateHeader() {
    this.setState({
      scrolled: window.scrollY > 1
    });
  }

  render() {
    var {
      className,
      initialPage
    } = this.props;
    var buttonBack;
    var tabIndexMeusCursos = 1;

    if (this.state.totalCourses === 0) tabIndexMeusCursos = -1

    className = className
      ? (' ' + className)
      : '';

    if (this.props.buttonBack) {
      tabIndexMeusCursos = '-1'
      buttonBack = (<NavLink tabIndex="1" exact={true} to="/" className="rdp-btn-course-header-back" aria-label="Volte para a página do curso">
        <span>Voltar</span>
      </NavLink>);
    }

    if (this.props.onClickBack) {
      tabIndexMeusCursos = '-1'
      buttonBack = (<button tabIndex="1" type="button" onClick={this.props.onClickBack} className="rdp-btn-course-header-back" aria-label="Volte para a página do curso">
        <span>Voltar</span>
      </button>);
    }

    var setBg = this.state.scrolled || this.props.noBgTransition
      ? ' rdp-header-scrolled'
      : '';
    var fullWidth = this.props.fullWidth
      ? ' rdp-header-full-width'
      : '';
    var withButtonBack = this.props.buttonBack
      ? ' rdp-with-button-back'
      : '';
    var headerOnEdition = this.props.headerOnEdition
      ? ' rdp-header-on-edition'
      : '';
    var { readLogo = true } = this.props
    var tabIndexLogo = readLogo ? 0 : -1

    //console.log('tabIndexMeusCursos', tabIndexMeusCursos)

    return (<header role='banner' className="rdp-header-with-content">
      {initialPage && <span tabIndex={1} id="rdp-header-startPage" aria-label="Página inicial"></span>}
      <div className="rdp-header-with-content-items">
        <div className="rdp-container">
          <div className="rdp-header-logo-primary"></div>
          {this.props.navigation}
        </div>
        
        {this.props.children}
      </div>
    </header>);
  }
}
