import React, {Component} from 'react';

import './no-results.css';

export default class NoResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.title || this.getDefault().title,
      context: this.props.context || this.getDefault().context
    }

// console.log('NoResults', 'constructor', this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.title !== prevProps.title) {
      if(this.props.title) {
        this.setState({
          title: this.props.title
        })
      } else {
        this.setState({
          title: this.getDefault().title
        })
      }
    }
  }

  getDefault(){
    return {
      title: "Sua pesquisa não retornou nenhum conteúdo.",
      context: "Verifique se todas as palavras estão escritas corretamente ou tente palavras-chave diferentes."
    }
  }

  render() {
// console.log('NoResults', 'render', this);

    let context;

    if (this.props.context || this.props.context === undefined) {
      context = <p className="rdp-no-results-context">{this.state.context}</p>;
    }

    return (<section className="rdp-no-results-container">
      <div className="rdp-container">
        <h2 className="rdp-no-results-title">{this.state.title}</h2>
        {context}
      </div>
    </section>);
  }
}
