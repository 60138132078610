import React, {Component} from 'react';
import Slider from 'react-slick';
import $ from 'jquery';

import {CardCourse} from '../../components';

import 'slick-carousel/slick/slick.css';
import './slider-courses.css';

export default class SliderCourses extends Component {
  render() {
    // console.log('SliderCourses', 'render', this);

    var offers = this.props.offers || [];
    var courseList = [];

    for (var i = 0; i < offers.length; i++) {
      courseList.push(<CardCourse key={i} offer={offers[i]} indexProgress={false}/>);
    }

    var slickWidth = window.innerWidth;

    let ie = $('html').hasClass('ie');

    if (ie) {
      slickWidth = Math.min(slickWidth, 1024);
    }

    let itemsDisplayed = Math.floor(slickWidth / 280);

    let sliderSettings = {
      speed: 500,
      slidesToShow: itemsDisplayed,
      slidesToScroll: itemsDisplayed,
      infinite: (window.innerWidth >= 768)
    }

    if (courseList.length > itemsDisplayed) {
      return (
        <div className="rdp-cards-container rdp-container rdp-component-slider-courses">
          <div className="rdp-cards-courses">
            <Slider {...sliderSettings}>{courseList}</Slider>
          </div>
        </div>
      );
    } else if (courseList.length === 4) {
      return (
        <div className="rdp-component-slider-courses no-slider rdp-cards-container rdp-grid-4">
          {courseList}
        </div>
      );
    } else {
      return (
        <div className="rdp-component-slider-courses no-slider rdp-cards-container">
          {courseList}
        </div>
      );
    }
  }
}
