import React, { Component } from 'react';
import './modal-social-name.css';

export default class ModalSocialName extends Component {

  setFocusElement = () => {
    if (this.props.enableField) {
      let item = document.getElementById("rdp-group-modal-social-name")
      item.setAttribute("tabindex", "0");
      item.focus();
    }
  }

  render() {
    const { opened, toggle, enableField } = this.props;
    const tabIndex = opened ? 0 : -1

    if (this.props.typeModal === 'accept') {
      var typeModal;

      typeModal = (
        <div id="rdp-group-modal-social-name">
          <p className="rdp-modal-text">Nome social é o nome pelo qual pessoas trans e travestis preferem ser chamadas cotidianamente, em contraste com o nome oficialmente registrado, que não reflete sua identidade de gênero.</p>
          <p className="rdp-modal-text">O direito ao uso de nome social para tratamento nominal de pessoas transexuais e travestis obedece ao Decreto Nº 55.588, de 17 de março de 2010.</p>
          <p className="rdp-modal-text">Você confirma que se encaixa nessa lei e está ciente desse preenchimento?</p>
          <div className="rdp-group-btn">
            <button tabIndex={tabIndex} className="rdp-btn-filled-orange rdp-btn-no" id="rdp-group-btn-no" onClick={() => toggle()}>Não</button>
            <button tabIndex={tabIndex} className="rdp-btn-filled-orange rdp-btn-yes" id="rdp-group-btn-yes" onClick={() => enableField()}>Sim</button>
          </div>
        </div>
      );
    } else {
      typeModal = (
        <div id="rdp-group-modal-social-name">
          <p className="rdp-modal-text">Nome social é o nome pelo qual pessoas trans e travestis preferem ser chamadas cotidianamente, em contraste com o nome oficialmente registrado, que não reflete sua identidade de gênero.</p>
          <p className="rdp-modal-text">O direito ao uso de nome social para tratamento nominal de pessoas transexuais e travestis obedece ao Decreto Nº 55.588, de 17 de março de 2010.</p>
          <div className="rdp-group-btn rdp-ok">
            <button className="rdp-btn-filled-orange" onClick={() => toggle()}>Ok</button>
          </div>
        </div>
      );
    }

    return (
      <div tab-index={tabIndex} aria-pressed="false" role="button" id="rdp-modal-socialname" className={`rdp-modal ${opened ? 'rdp-active' : ''}`}>
        <div className="rdp-modal-content">
          <div className="rdp-modal-info-social-name">
            <h2 className="rdp-modal-title">
              Alerta
          </h2>
            {typeModal}
            <button tabIndex={tabIndex} aria-label="Fechar" className="rdp-ico-close" id="rdp-group-btn-close" onClick={() => toggle()}>
              {/*<span className="rdp-sr-only">fechar</span>*/}
            </button>
            <div tabIndex={tabIndex} onFocus={this.setFocusElement}></div>
          </div>
        </div>
      </div>
    );
  }
}
