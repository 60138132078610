import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { Version } from '../../components';
import { RDP_Utils } from '../../utils';

import moment from 'moment';
import 'moment/locale/pt-br';

import './footer.css';

export default class Footer extends Component {

  openLink(link) {
    RDP_Utils.openUrl(link);
  }

  render() {
    return (
      <footer role="contentinfo" className="rdp-footer">
        <div className="rdp-footer-social">
          <div className="rdp-container">
            <div className="rdp-footer-social-items">
              <span className="rdp-footer-social-item-facebook" onClick={() => this.openLink("https://www.facebook.com/hagecompliance")}></span>
              {/* <span className="rdp-footer-social-item-twitter" onClick={() => this.openLink("https://twitter.com/hagecomplianceeducacao")}></span> */}
              {/* <span className="rdp-footer-social-item-youtube" onClick={() => this.openLink("https://www.youtube.com/channel/hagecompliance")}></span> */}
              <span className="rdp-footer-social-item-instagram" onClick={() => this.openLink("https://www.instagram.com/hagecompliance")}></span>
              <span className="rdp-footer-social-item-linkedin" onClick={() => this.openLink("https://www.linkedin.com/company/hagefonsecasuzart&prud%C3%AAncioconsultoriaemcompliance/?originalSubdomain=br")}></span>
            </div>
            <div className="rdp-footer-social-logo"></div>
          </div>
        </div>

        <div className="rdp-footer-company">
          <div className="rdp-container">
            <nav className="rdp-menu">
              <ul>
                <li>
                  <NavLink aria-label="Sobre o programa" exact={true} to="/about" activeClassName="rdp-active">
                    Sobre
                  </NavLink>
                </li>

                <li>
                  <NavLink exact={true} to="/terms-of-use" activeClassName="rdp-active">
                    Termos de uso
                  </NavLink>
                </li>

                <li>
                  <NavLink exact={true} to="/privacy-policy" activeClassName="rdp-active">
                    Política de privacidade
                  </NavLink>
                </li>

                {/* <li>
                  <NavLink exact={true} to="/credits" activeClassName="rdp-active">
                    Créditos
                  </NavLink>
                </li> */}

                <li>
                  <NavLink exact={true} to="/" activeClassName="rdp-active">
                    Todos os cursos
                  </NavLink>
                </li>

                <li>
                  <NavLink aria-label="Seção de perguntas frequentes" exact={true} to="/faq" activeClassName="rdp-active">
                    Perguntas frequentes
                  </NavLink>
                </li>

                {RDP_Utils.onLine() ? (
                  <li className="rdp-li-support">
                    <NavLink aria-label="Canal de Ajuda" id="text-suport" exact={true} to="/support" activeClassName="rdp-active">
                      Ajuda
                    </NavLink>
                  </li>
                ) : null}

                              
              </ul>
            </nav>

            <span tabIndex="0" id="end-footer" aria-label="Fim do Rodapé" />
          </div>
        </div>

        <div className="rdp-footer-direitos">
          <div className="rdp-company">
            <span>©{moment().format('YYYY')} Page Compliance.</span> Todos os direitos reservados.
            <Version show={true} />
          </div>
          <span className="rdp-digital-pages-logo" onClick={() => this.openLink("http://www.digitalpages.com.br")}></span>
        </div>
      </footer>
    );
  }
}
