import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {RDP_CONFIG} from '../../config';
import {RDP_Utils} from '../../utils';

// import defaultImage from './senac-logo.svg';

import {offerService} from '../../services';

import './course-main-section.css';

export default class CourseMainSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null
    }

    this.openOffer = this.openOffer.bind(this);
    // console.log('CourseMainSection', 'constructor', this.props);
  }

  openOffer() {
  // console.log('openOffer');

    let _this = this;

    let progressList = JSON.parse(window.localStorage.getItem('rdp-offers-progress-hagecompliance') || "[]");
    let activityId = 'first';

    progressList.forEach(function(item) {
      if (Number(item.offerId) === Number(_this.props.offer.id)) {
        activityId = item.activityId;
      }
    });

    // console.log("aqui", this.props.offer);

    var contentOfferId = this.props.offer.treeOffer.id;

    offerService.getLastAccessedActivity(contentOfferId).then(function(activityId) {
      // console.log('remote', activityId);

      _this.setState({
        redirectTo: '/edition/' + _this.props.offer.id + '/atividade/' + activityId
      });
    }, function(error) {
      _this.setState({
        redirectTo: '/edition/' + _this.props.offer.id + '/atividade/' + activityId
      });
    });

  }

  render() {
    // console.log('CourseMainSection', 'render', this.state.hidden);

    if (this.props.offer) {
      var {
        offer
      } = this.props;
      var overlay,
        offerImage,
        overlayBackground,
        actionButton;

      if (offer.hasOwnProperty('categories') && Array.isArray(offer.categories) && offer.categories.length > 0) {
        overlayBackground = {
          backgroundColor: offer.categories[0].color
        };
      } else {
        overlayBackground = {
          backgroundColor: '#ccc'
        };
      }

      overlay = (<span className="rdp-overlay" style={overlayBackground}></span>);

      var isOpen = false;

      if (offer) {
        if (offer.treeOffer) {
          var aux = offer.treeOffer.offerStartAt;
          isOpen = (new Date() > new Date(aux));
        }
      }

      if (offer.progress !== undefined && isOpen) {
        if ((offer.progress * 100) < 1) {
          actionButton = (<button className="rdp-btn-filled-orange rdp-init-course" onClick={() => this.props.onClickSubscribe()} aria-label="Inicie este curso">Iniciar curso</button>);
        } else if ((offer.progress * 100) > 1 && (offer.progress * 100) < RDP_CONFIG.offerProgressToDone) {
          actionButton = (<button onClick={this.openOffer} className="rdp-btn-filled-orange rdp-continue-course" aria-label="Continue este curso">Continuar curso</button>);
        } else {
          actionButton = (<button onClick={this.openOffer} className="rdp-btn-filled-orange rdp-access-course">Acessar curso</button>);
        }
      }

      var defaultImage = "";

      offerImage = {
        backgroundImage: `url(${offer.thumbnail
          ? offer.thumbnail
          : defaultImage})`
      };

      let descCourse = "Esta é a pagina do curso " + offer.offerName

      if (this.state.redirectTo) {
        return (<Redirect to={this.state.redirectTo}/>);
      } else {
        return (<section className={'rdp-course-main' + (
            !RDP_Utils.onLine()
            ? ' rdp-is-offline'
            : '')} style={offerImage}>
          {overlay}
          <div className="rdp-container">
            <h2 tabIndex={0} aria-label={descCourse} className="rdp-course-title">{offer.offerName}</h2>
            {actionButton}
          </div>
        </section>);
      }
    } else {
      return null;
    }
  }
}
