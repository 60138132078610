import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

export default class PrivateRoute extends Component {
  render() {
    // console.log('PrivateRoute', 'render', this);

    if (this.props.user) {
      return (
        <Route
          exact={this.props.exact}
          path={this.props.path}
          component={this.props.component}
        />
      );
    } else {
      return (
        <Redirect to={{
          pathname: '/login',
          push: true,
          exact: true,
          state: { from: this.props.location }
        }}/>
      );
    }

  }
}
