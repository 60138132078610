import React, { Component } from 'react';
import $ from 'jquery';

import './faq-question.css';

export default class FaqQuestion extends Component {

  constructor(props) {
    super(props);

    this.onClickQuestion = this.onClickQuestion.bind(this)
  }

  onClickQuestion(event) {
    $(event.currentTarget).toggleClass('rdp-active').find('.rdp-faq-answer').slideToggle(500);
  }

  render() {
    return (
      <div className="rdp-faq-container">
        <div className="rdp-faq-question" onClick={this.onClickQuestion}>
          <div tabIndex="0" role="button" aria-pressed="true" className="rdp-faq-question-text" dangerouslySetInnerHTML={{__html :this.props.question}}></div>
          <div className="rdp-faq-answer">
            <div tabIndex="0" role="button" aria-pressed="true" className="rdp-faq-answer-text" dangerouslySetInnerHTML={{__html :this.props.answer}}></div>
          </div>
        </div>
      </div>
    );
  }
}
