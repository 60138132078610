import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import InputMask from 'react-input-mask';
import {RDP_Utils} from '../../utils';
import $ from 'jquery';
import './support-login.css';

import {authenticationService, userService, supportService} from '../../services';

import {CustomInputFile, ButtonArrow, ErrorDialog, SuccessDialog, Loader} from '../../components';

export class ViewSupportLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      success: false,
      cpf: '',
      businessPhone: '',
      formFields: {},
      issuesList: [],
      selectedArea: {},
      selectedIssue: {},
      authed: authenticationService.getLocalUserData(),
      onLine: RDP_Utils.onLine()
    }

    this.image = '';
    this.imageName = '';
    this.currentUser = {};
    this.file = {};

    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onHandlerCloseSuccessDialog = this.onHandlerCloseSuccessDialog.bind(this);
  }

  componentWillMount() {}

  componentDidMount() {
    document.title = "Suporte - Page Compliance";

    this.scrollToTop();
  }

  scrollToTop() {
    $('html, body').scrollTop(0);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onImageChange(inputFile) {
    var _this = this;
    _this.imageName = inputFile.files[0].name;
    _this.file = inputFile.files[0];

    userService.convertFileInputToBase64(inputFile).then(function(response) {
      _this.image = response;
    });
  }

  errorFormFields(error) {
    // console.error('ERROR', error);
    var _this = this;
    _this.setState({loading: false, error: error});
  }

  onSubmitForm(event) {
    // // console.log('form', event.targe);

    event.preventDefault();
    var _this = this;
    var formSupport = event.target;

    if (RDP_Utils.isValid(formSupport)) {

      _this.setState({
        loading: true,
        error: false,
        success: false,
      }, function() {
        // console.log(formSupport);
        supportService.sendSuppotRequest(formSupport).then(function(ticketID) {
          // // console.log(ticketID);

          // Reseting form
          formSupport.reset();

          _this.setState({
            loading: false,
            error: false,
            cpf: '',
            businessPhone: '',
            mobilePhone: '',
            success: {
              message: 'Solicitação nº ' + ticketID + ' criada com sucesso!'
            }
          });
        }, function(reason) {
          // console.log(reason);
          _this.errorFormFields(reason);
        });
      });
    } else {
      _this.errorFormFields({message: 'Os campos obrigatórios devem ser preenchidos corretamente'});
    }
  }

  onHandlerCloseSuccessDialog() {
    this.setState({success: false});
  }

  render() {
    var error,
      success,
      loader,
      modalInfo,
      btnSubmit;

    if(this.state.onLine) {
      btnSubmit = <ButtonArrow
        className="rdp-btn-arrow-orange"
        textButton="Enviar"
        type="submit"
      />
    }

    if (this.state.error) {
      error = <ErrorDialog top="top" show="true" error={this.state.error}/>;
    }

    if (this.state.success) {
      success = <SuccessDialog func={this.onHandlerCloseSuccessDialog} top="top" show="true" success={this.state.success}/>;
    }

    if (this.state.authed) {
      return <Redirect to="/"/>;
    }

    if (this.state.loading) {
      loader = <Loader/>;
    }

    return (
      <section className="rdp-view rdp-view-support-login rdp-bg-squares-header">
        {error}
        {success}
        {loader}
        <div className="rdp-container">
          <h1 className="rdp-title-transparency">Transparência e Unicidade</h1>

          <form className="rdp-form-support-login" encType="multipart/form-data" noValidate="noValidate" onSubmit={this.onSubmitForm}>
            <div className="fieldset">
              <div className="legend">Se você está com problemas para realizar sua autenticação no portal, preencha este formulário. Caso esteja com outro tipo de problema e já possui usuário e senha, acesse o suporte técnico em sua área exclusiva.</div>

              <div className="rdp-form-group rdp-col-4">
                <label htmlFor="name">Nome*</label>
                <input
                  type="text"
                  id="name"
                  className="rdp-form-control"
                  name="name"
                  placeholder="Nome"
                  aria-label="Nome"
                  aria-required="true"
                  required="required"
                />
              </div>

              <div className="rdp-form-group rdp-col-4">
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  id="email"
                  className="rdp-form-control"
                  name="email"
                  placeholder="seuemail@email.com.br"
                  aria-label="Email"
                  aria-required="true"
                  required="required"
                />
              </div>

              <div className="rdp-form-group rdp-col-4">
                <label htmlFor="cpf">CPF</label>
                <InputMask
                  onChange={this.handleChange}
                  id="cpf"
                  mask="999.999.999-99"
                  name="cpf"
                  placeholder="___.___.___-__"
                  className="rdp-form-control"
                  aria-label="CPF"
                  value={this.state.cpf}
                />
              </div>

              <div className="rdp-form-group rdp-col-6 rdp-no-padding">
                <label htmlFor="businessPhone">Telefone comercial</label>
                <InputMask
                  onChange={this.handleChange}
                  id="businessPhone"
                  mask="(99) 9999-9999"
                  name="businessPhone"
                  placeholder="(__) ____-____"
                  className="rdp-form-control"
                  aria-label="Telefone comercial"
                  value={this.state.businessPhone}
                />
              </div>

              <div className="rdp-form-group rdp-col-6">
                <label htmlFor="mobilePhone">Telefone celular</label>
                <InputMask
                  onChange={this.handleChange}
                  id="mobilePhone"
                  mask="(99) 99999-9999"
                  name="mobilePhone"
                  placeholder="(__) _____-____"
                  className="rdp-form-control"
                  aria-label="Telefone celular"
                  value={this.state.mobilePhone}
                />
              </div>

              <div className="rdp-form-group">
                <label htmlFor="description">Descreva o problema*</label>
                <textarea
                  id="description"
                  className="rdp-form-control"
                  name="description"
                  placeholder="..."
                  aria-label="Descreva o problema"
                  aria-required="true"
                  required="required">
                </textarea>
              </div>

              <div className="rdp-form-group">
                <CustomInputFile onImageChange={this.onImageChange}/>
              </div>

              <input type="hidden" id="department" name="department" value="NA"/>

              <div className="rdp-form-group rdp-form-actions">
                <ButtonArrow
                  back="back"
                  tag="link"
                  address="/login"
                  className="rdp-btn-arrow-gray"
                  textButton="Voltar"
                />

                {btnSubmit}
              </div>

            </div>
          </form>
        </div>
        {modalInfo}
      </section>
    );
  }
}
