/*global CordovaExec*/
import './polyfill.js';
import './prototypes.js';
import { RDP_Utils } from './utils';

import React, { Component } from 'react';
import { RDP_CONFIG } from './config'
import { Route } from 'react-router-dom';
import DefaultRoute from './routes/default-route/default-route';
import RDPSessionTracker from "./utils/rdp-analytics/react-session-tracker";
import { authenticationService, networkService } from './services';

import {
  View404,
  ViewCourse,
  ViewChangePassword,
  ViewFaq,
  ViewHome,
  ViewLogin,
  ViewCourses,
  ViewMyCourses,
  ViewPlayer,
  ViewProfile,
  ViewRegister,
  ViewSupport,
  ViewTermsOfUse,
  ViewPrivacyPolicy,
  ViewExplore,
  ViewLogout,
  ViewEdition,
  ViewSupportLogin,
  ViewMyCertificates,
  ViewForgotPassword,
  ViewResetPassword,
  ViewError,
  ViewAbout,
  // ViewCredits,
  ViewMedia
} from './views';

import './assets/stylesheets/app.css';

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      iammobile: RDP_Utils.inApp()
        ? 'iammobile'
        : '',
      networkStatus: RDP_Utils.connectionStatus()
        ? 'online'
        : 'offline'
    }

    document.body.setNavigatorClassName();
  }

  componentDidMount() {
    let _this = this;

    // console.log('networkService.getStatus');
    networkService.getStatus().then(function (status) {
      window.online = status;

      _this.setState({
        networkStatus: RDP_Utils.connectionStatus()
          ? 'online'
          : 'offline'
      });
    }, function () {
      window.online = false;

      _this.setState({
        networkStatus: RDP_Utils.connectionStatus()
          ? 'online'
          : 'offline'
      });
    });

    if (RDP_Utils.inApp()) {
      // console.log("ShelfPlugin", "RegisterConnectionStatus");

      CordovaExec(function (status) {
        // console.log("ShelfPlugin", "RegisterConnectionStatus", "status:", status);
        window.online = status;

        _this.setState({
          networkStatus: RDP_Utils.connectionStatus()
            ? 'online'
            : 'offline'
        });
      }, function (error) {
        // console.log("ShelfPlugin", "RegisterConnectionStatus", "error:", error);
      }, "ShelfPlugin", "RegisterConnectionStatus");
    } else {
      _this.setState({ networkStatus: 'online' });
    }
  }

  getRoute(user) {
    return (
      <RDPSessionTracker
        consoleLog={RDP_CONFIG.mode !== 'PRD'}
        disable={RDP_CONFIG.mode !== 'PRD'}
        productName="rdp-hage-compliance/1.0"
      >

        <DefaultRoute
          user={user}
          exact={true}
          path="/edition/:id"
          pathAnalytics="edition/$"
          component={ViewEdition}
        />
        <DefaultRoute
          user={user}
          exact={true}
          path="/edition/:id/atividade/:activityId"
          pathAnalytics="edition/$/activity/$"
          component={ViewEdition}
        />
        <DefaultRoute
          user={user}
          exact={true}
          path="/edition/:id/atividade/first"
          pathAnalytics="edition/$/activity/first"
          component={ViewEdition}
        />
        <Route
          user={user}
          exact={true}
          path="/courses"
          pathAnalytics="courses"
          component={ViewCourses} />
        <DefaultRoute
          user={user}
          exact={true}
          path="/course/:id"
          pathAnalytics="course/$"
          component={ViewCourse} />
        <Route user={user} exact={true} path="/faq" pathAnalytics="faq" component={ViewFaq} />
        <Route exact={true} path="/login" pathAnalytics="login" component={ViewLogin} />
        <Route exact={true} path="/logout" pathAnalytics="logout" component={ViewLogout} />
        <Route user={user} exact={true} path="/my-courses" pathAnalytics="my-courses" component={ViewMyCourses} />
        <Route user={user} exact={true} path="/player" pathAnalytics="player" component={ViewPlayer} />
        <Route user={user} exact={true} path="/profile" pathAnalytics="profile" component={ViewProfile} />
        <Route user={user} exact={true} path="/profile/first" pathAnalytics="profile/first" component={ViewProfile} />
        <Route user={user} exact={true} path="/register" pathAnalytics="register" component={ViewRegister} />
        <Route exact={true} path="/support" pathAnalytics="support" component={ViewSupport} />
        <Route exact={true} path="/support-login" pathAnalytics="support-login" component={ViewSupportLogin} />
        <Route exact={true} path="/terms-of-use" pathAnalytics="terms-of-use" component={ViewTermsOfUse} />
        <Route exact={true} path="/privacy-policy" pathAnalytics="privacy-policy" component={ViewPrivacyPolicy} />
        <Route user={user} exact={true} path="/explore" pathAnalytics="explore" component={ViewExplore} />
        <Route user={user} exact={true} path="/my-certificates" pathAnalytics="my-certificates" component={ViewMyCertificates} />
        <Route user={user} exact={true} path="/" pathAnalytics="home" component={ViewHome} />
        <Route exact={true} path="/forgot-password" pathAnalytics="forgot-password" component={ViewForgotPassword} />
        <Route exact={true} path="/reset-password" pathAnalytics="reset-password" component={ViewResetPassword} />
        <Route exact={true} path="/change-password" pathAnalytics="change-password" component={ViewChangePassword} />
        <Route exact={true} path="/error" pathAnalytics="error" component={ViewError} />
        <Route exact={true} path="/404" pathAnalytics="404" component={View404} />
        <Route exact={true} path="/about" pathAnalytics="about" component={ViewAbout} />
        {/* <Route exact={true} path="/credits" component={ViewCredits}/> */}
        <Route user={user} exact={true} path="/media/:origin/:type/:value" pathAnalytics="media/$/$/$" component={ViewMedia} />
        {/* :origin - origem do recurso
      :type - tipo do recurso (img, video, etc)
      :value - valor do recurso (url, id, etc) */
        }

      </RDPSessionTracker>

    );
  }

  render() {
    // console.log('App', 'render', this);

    var user = authenticationService.getLocalUserData();

    return (
      <div className={`rdp-app ${this.state.networkStatus} ${this.state.iammobile}`}>
        {
          this.state.networkStatus === 'offline'
            ? <div className='offline-warning'>Você está navegando offline</div>
            : null
        }
        {this.getRoute(user)}
      </div>);
  }
}
