import React, {Component} from 'react';
import $ from 'jquery';
import './privacy-policy.css';

// import {authenticationService, fileService, supportService, policyService} from '../../services';
import {authenticationService, policyService} from '../../services';

import {
  // Header,
  HeaderV2,
  Footer,
  Navigation,
  Loader,
  ErrorDialog
} from '../../components';

export class ViewPrivacyPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      policy: '',
      authed: authenticationService.getLocalUserData()
    }
  }

  componentWillMount() {
    this.getPolicy();
  }

  componentDidMount() {
    document.title = "Politica de Privacidade - Page Compliance";

    this.scrollToTop();
  }

  scrollToTop() {
    $('html,body').scrollTop(0);
  }

  getPolicy() {
    var _this = this;

    _this.setState({
      loading: true
    }, () => {
      policyService.getPolicy().then(policy => {
        _this.setState({policy, loading: false});
      }, function(reason){
        _this.setState({error: reason, loading: false});
      });
    });
  }

  render() {
    let loader, error;

    if (this.state.loading) {
      loader = <Loader/>;
    }

    if (this.state.error) {
      error = <ErrorDialog error={this.state.error} />;
    }

    if (!this.state.authed) {
      return (
        <div className="rdp-view rdp-view-privacy-policy rdp-bg-squares">
          <section className="rdp-context-privacy no-login">
            <div className="rdp-container">
              <h2 className="rdp-privacy-title">
                Política de privacidade
                <small className="rdp-privacy-subtitle">Consideramos a privacidade dos nossos usuários.</small>
              </h2>
              <div className="rdp-privacy-context" dangerouslySetInnerHTML={{__html: this.state.policy}}/>
            </div>
          </section>
        </div>
      );
    }

    return (<div className="rdp-view rdp-view-privacy-policy">
      {error}
      {loader}
      {/* <Header noBgTransition/> */}
      <HeaderV2 navigation={<Navigation/>} label="Política de privacidade"/>
      <div className="rdp-main">
        <section className="rdp-context-privacy">
          <div className="rdp-container">
            {/* <h2 className="rdp-title-page">Política de privacidade</h2> */}
            <div className="rdp-privacy-context" dangerouslySetInnerHTML={{__html: this.state.policy}}/>
          </div>
        </section>
        {/* <Navigation/> */}
      </div>
      <Footer/>
    </div>);
  }
}
