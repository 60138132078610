import React, { Component } from 'react';

export default class FormGroup extends Component {
  render() {
    const {
      formGroupClass,
      labelFor,
      labelClass,
      labelText,
      inputName,
      inputType,
      inputClass,
      placeholder,
      required,
      autoComplete
    } = this.props;

    return (
      <div className={formGroupClass}>
        <label htmlFor={labelFor} className={labelClass}>{labelText}</label>
        <input
          id={labelFor}
          name={inputName}
          type={inputType}
          className={inputClass}
          aria-label={placeholder}
          placeholder={placeholder}
          required={required ? 'required' : ''}
          aria-required={required ? 'true' : 'false'}
          autoComplete={autoComplete}
        />
      </div>
    );
  }
}
