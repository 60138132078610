import {RDP_Utils} from '../utils';
import {RDP_CONFIG} from '../config';

var Register = function() {

  this.getFirstAccess = function(params) {
  // console.log('getFirstAccess', params);

    return new Promise(function(resolve, reject) {
      RDP_Utils.request({
        url: RDP_CONFIG.learningURL + 'restapi/api/protected/email/firstAccess',
        type: 'GET',
        xhrType: 'ajax',
        data: params
      }).then(function(response) {
        resolve(response);
      }, function(reason) {
        reject(reason);
      });
    });
  }

  return this;
}

var registerService = new Register();

export {
  registerService
};
