// import {RDP_Utils} from '../utils';
// import {RDP_CONFIG} from '../config';
// import {authenticationService} from './authentication';
import {mockService} from './mock';

var Policy = function() {
  // var _this = this;

  // var RDP_USER = authenticationService.getLocalUserData();

  this.getPolicy = function() {
  // console.log('getPolicy');

    return new Promise(function(resolve, reject) {
      resolve(mockService.policy());
      // RDP_Utils.request({
      //   url: `${RDP_CONFIG.learningURL}restapi/api/protected/policy`,
      //   type: 'GET',
      // xhrType: 'ajax',
      //   headers: {
      //     'Authorization': 'Bearer ' + RDP_USER.authentication_token
      //   }
      // }).then(function(response) {
      //   response.content = mockService.policy(response.content);
      //   resolve(response);
      // }, function(jqXHR) {
      //   var reason;
      //   if (jqXHR.responseJSON) {
      //     reason = jqXHR.responseJSON;
      //   } else {
      //     reason = RDP_CONFIG.defaultReason;
      //   }
      //
      //   reject(reason);
      // });
    });
  }

  return this;
}

var policyService = new Policy();

export {
  policyService
};
