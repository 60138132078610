import React, {Component} from 'react';
import './rating-stars.css';

export default class RatingStars extends Component {
  constructor(props) {
    super(props);

    // console.log(this.props);

    this.state = {
      rating: Math.round(this.props.rating)
    };

    // this.setRating = this.setRating.bind(this);
  }

  componentWillMount() {
    // this.getRating();
  }

  // getRating() {
  //   let _this = this;
  //
  //   ratingService.getRating().then(rating => {
  //     _this.setState({rating});
  //   });
  // }

  // setRating(event) {
  //   event.preventDefault();
  //
  //   if (this.props.type === "editable") {
  //     let _this = this,
  //       target = event.target,
  //       rating = parseInt(target.getAttribute('data-star'), 10),
  //       ratingObj = {
  //         rating
  //       };
  //
  //     ratingService.setRating(ratingObj).then(response => {
  //       _this.setState({rating});
  //     });
  //   }
  // }

  render() {
    // console.log(this.state.rating);
    return (<div aria-label={`avaliação média de ${this.state.rating} estrelas`} className={'component-rating-stars rdp-note-stars star-type-' + this.props.type} data-rating={this.state.rating}>
      <span className="rdp-ico-star" data-star="1">
        <span>Uma estrela</span>
      </span>
      <span className="rdp-ico-star" data-star="2">
        <span>Duas estrelas</span>
      </span>
      <span className="rdp-ico-star" data-star="3">
        <span>Três estrelas</span>
      </span>
      <span className="rdp-ico-star" data-star="4">
        <span>Quatro estrelas</span>
      </span>
      <span className="rdp-ico-star" data-star="5">
        <span>Cinco estrelas</span>
      </span>
    </div>);
  }
}
