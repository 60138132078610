import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export class ViewLogout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logout: false
    }
  }

  componentDidMount() {
    document.title = "Sair - Page Compliance";
    var _this = this;
    window.dispatchEvent(new Event('rdp-session-logout'))
    window.localStorage.removeItem('rdp-new-client-hagecompliance');
    _this.setState({ logout: true });
  }

  render() {
    if (this.state.logout) {
      return (
        <Redirect to={{
          pathname: '/login'
        }} />
      );
    }

    return null;
  }
}
