import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
// import {RDP_Utils} from '../../utils';
import { RDP_CONFIG } from '../../config';
import jwt_decode from 'jwt-decode';

import {
  ErrorDialog,
  Header,
  Loader,
  Track,
  Player,
  ModalRating
} from '../../components';

import { offerService, authenticationService, ratingService, Interactions, ActivityEvaluative } from '../../services';

// console.log(rdpservices);

import './edition.css';

export class ViewEdition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teste: 0,
      user: authenticationService.getLocalUserData(),
      loading: true,
      error: false,
      route: this.getRoute(),
      redirectTo: null,
      offer: null,
      postLastActivity: null,
      currentActivity: null,
      trailType: null,
      trackWidth: 'closed',
      previousActivityId: null,
      nextActivityId: null,
      currentActivityId: null,
      availableActivityId: null,
      currentActivities: null,
      currentActivityIndex: null,
      evaluated: null,
      showModalRating: null,
      contentOfferId: null,
      availableAssessment: false,
      focusPlayer: true
    }

    this.handleIframeLoad = this.handleIframeLoad.bind(this);
    this.onClickToggle = this.onClickToggle.bind(this);
    this.onClickActivity = this.onClickActivity.bind(this);
    this.onClickRating = this.onClickRating.bind(this);
    this.onSubmitRating = this.onSubmitRating.bind(this);
    this.getUserRating = this.getUserRating.bind(this);
    this.onClickCloseModalRating = this.onClickCloseModalRating.bind(this);
    this.onFocusPlayer = this.onFocusPlayer.bind(this);

    // console.log('ViewEdition', 'constructor', this.pros);

    this.interactionsService = new Interactions({ user: authenticationService.getLocalUserData(), config: RDP_CONFIG });
    this.activityEvaluative = new ActivityEvaluative({ user: authenticationService.getLocalUserData(), config: RDP_CONFIG });
  }

  componentWillMount() {
    // console.log('componentWillMount', this.state.currentActivityId);
  }

  getRoute() {
    let _this = this;

    let progressList = JSON.parse(window.localStorage.getItem('rdp-offers-progress-hagecompliance') || "[]");
    let activityId = 'first';

    progressList.forEach(function (item) {
      if (Number(item.offerId) === Number(_this.props.match.params.id)) {
        activityId = item.activityId;
      }
    });

    // console.log(progressList);

    let hostname;
    let hash = `#embed/activity/${this.props.match.params.id}/${activityId}`;
    let route;

    if (window.location.search.indexOf('player=remote') > -1) {
      hostname = window.location.hostname + ':8080/';
      route = 'http://' + hostname + hash;
    } else {
      hostname = "rdphome/index.html";
      route = hostname + hash;
    }

    return route;
  }

  componentDidMount() {
    // console.log('componentDidMount', this.state.currentActivityId);

    var _this = this;
    this.bindRemoteUpdateProgress();

    let params = this.props.match.params;

    params.loadNodes = true;

    _this.getOffer(params);
  }

  componentWillUpdate() {
    // console.log('componentWillUpdate', this.state.currentActivityId);
  }

  componentDidUpdate(prevProps, prevState) {
    let _this = this;

    let activityData;

    let newState = {
      loading: true
    };

    if (this.state.currentActivityId !== prevState.currentActivityId) {

      activityData = _this.getActivityDataById(this.state.offer.treeOffer.contexts, Number(_this.props.match.params.activityId));

      // console.log(activityData);

      if (this.state.trailType === 0) {
        // console.log('sequencial');

        if (activityData.currentActivity) {
          // console.log('tem atividade');
          if (activityData.currentActivity.interaction && activityData.currentActivity.interaction.status === 'DONE') {
            // console.log('tem interaction e já foi concluida');

            newState = Object.assign(activityData, newState);

            _this.setState(newState);
          } else {
            // não tem interaction ou tem mas não foi concluida
            _this.setState(newState, function () {
              window.location.hash = '/edition/' + _this.props.match.params.id + '/atividade/' + activityData.availableActivityId;
            });
          }
        } else {
          // console.log('não tem atividade');

          _this.setState(newState, function () {
            window.location.hash = '/edition/' + _this.props.match.params.id + '/atividade/' + activityData.availableActivityId;
          });
        }
      } else {
        if (activityData.currentActivity.assessment) {
          if (activityData.availableAssessment) {
            newState = Object.assign(activityData, newState);

            _this.setState(newState);
          } else {
            _this.setState(newState, function () {
              // console.log('redirect to: /edition/' + _this.props.match.params.id + '/' + activityData.availableActivityId);
              window.location.hash = '/edition/' + _this.props.match.params.id + '/atividade/' + activityData.availableActivityId;
            });
          }
        } else {
          newState = Object.assign(activityData, newState);

          _this.setState(newState);
        }
      }
      this.saveActivitiesHistory(this.props.match.params.id, this.props.match.params.contentOfferId, jwt_decode(this.state.user.authentication_token).user_id, activityData.currentActivityId);
    } else {
      if (this.props.match.params.activityId === prevProps.match.params.activityId && this.state.currentActivityId && Number(this.props.match.params.activityId) !== this.state.currentActivityId) {
        activityData = _this.getActivityDataById(this.state.offer.treeOffer.contexts, Number(_this.props.match.params.activityId));

        newState = Object.assign(activityData, newState);

        // console.log('newState', newState);

        // console.log('this.state', this.state);

        if (newState.currentActivityId !== this.state.currentActivityId) {
          this.saveActivitiesHistory(this.props.match.params.id, this.props.match.params.contentOfferId, jwt_decode(this.state.user.authentication_token).user_id, activityData.currentActivityId);
          this.setState(newState);
        }
      }
    }
  }

  activeContentHandler() {
    let _this = this;

    window.CordovaExec(function (data) {
      // console.log("ActiveContentHandler", "response", data);

      var index = JSON.parse(data).content_index;

      let activityData = _this.getActivityDataById(_this.state.offer.treeOffer.contexts, Number(_this.state.currentActivities[index].id));

      _this.setState(activityData);
      // atualiza a possição ativa na trilha
    }, function (error) {
      // console.log("ActiveContentHandler", "error", error);
    }, "LearningPlayer", "ActiveContentHandler");
    // console.log("LearningPlayer", "ActiveContentHandler");
  }

  postLastActivity(id, contentOfferId, currentActivityId) {
    // console.log('postLastActivity', id, contentOfferId, currentActivityId);

    let _this = this;

    var params = {
      "contentOfferId": contentOfferId,
      "offerId": id,
      "activityId": currentActivityId,
      "userId": jwt_decode(this.state.user.authentication_token).user_id
    }

    // console.log(params)
    if (!isNaN(Number(params.activityId))) {
      offerService.postActivitiesHistory(params).then(function (response) {
        _this.setState({ loading: false });
      }, function (reason) {
        _this.setState({ error: reason, loading: false })
      })
    }
  }

  contentInfoRequestHandler() {
    let _this = this;

    window.CordovaExec(function contentInfoRequestHandler(data) {
      // console.log("LearningPlayer", "ContentInfoRequestHandler", 'response', data);
      _this.sendContentInfo(JSON.parse(data).content_index);
    }, "LearningPlayer", "ContentInfoRequestHandler");

    // console.log("LearningPlayer", "ContentInfoRequestHandler");
  }

  sendContentInfo(index) {
    let _this = this;

    // console.log('sendContentInfo', index);

    let activity = _this.state.currentActivities[index];
    // RDP_SHELF.current_activity = activity;
    // RDP_SHELF.current_activity_id = activity.id;
    var obj = {
      "activity": activity
    };

    // console.log('activity', activity);

    let url = JSON.parse(activity.properties).url;

    url = url.replace('.zip', '/index.html');

    obj.web_activity = {
      "url": url
    };

    window.CordovaExec(function (response) {
      // console.log("LearningPlayer", "ContentInfo", response);
      _this.setState({ loading: false })
    }, function (erro) {
      // console.log("LearningPlayer", "ContentInfo", erro);
    }, "LearningPlayer", "ContentInfo", [index, obj]);
  }

  setupTrack(offer) {
    // console.log('setupTrack', offer);

    // let _this = this;

    let offerAsSideMenu = {
      "collapsedWidth": 44,
      "expandedWidth": 224
    };

    var setup = {
      "margin_top": 44,
      "total_index": this.state.currentActivities.length,
      "current_index": this.state.currentActivityIndex
    };

    // console.log('state', this.state);

    // console.log('currentActivities', currentActivities);

    setup.margin_left = offerAsSideMenu.collapsedWidth;
    setup.width = offerAsSideMenu.collapsedWidth;
    setup.content_manager = true;

    let offerId = offer.id;

    if (!window.hasOwnProperty('RegisterActiveOffer') || (window.hasOwnProperty('RegisterActiveOffer') && window["RegisterActiveOffer"] !== offerId)) {
      // console.log('novo RegisterActiveOffer', offerId);
      window.CordovaExec(function (response) {
        window['RegisterActiveOffer'] = offerId;
        // console.log("LearningPlayer", "RegisterActiveOffer", 'response', response);
      }, function (reason) {
        // console.log(reason);
      }, "LearningPlayer", "RegisterActiveOffer", [offer]);

      // console.log("LearningPlayer", "RegisterActiveOffer", [offer]);
    }

    // Track
    window.CordovaExec(function setup() { }, "LearningPlayer", "Setup", [setup]);

    // console.log("LearningPlayer", "Setup", [setup]);
  }

  saveActivitiesHistory(id, contentOfferId, offerId, activityId) {
    this.postLastActivity(id, this.state.contentOfferId, activityId);

    var progress;

    if (localStorage.getItem("rdp-offers-progress-" + RDP_CONFIG.clientName) === null) {
      progress = [];
    } else {
      progress = JSON.parse(localStorage.getItem("rdp-offers-progress-" + RDP_CONFIG.clientName));
    };

    if (!isNaN(Number(activityId))) {
      let find = null;

      progress.forEach(function (item) {
        if (offerId === item.offerId) {
          find = true;

          item.activityId = activityId;
        }
      })

      if (!find) {
        progress.push({ "offerId": offerId, "activityId": activityId });

      }
    }

    localStorage.setItem("rdp-offers-progress-" + RDP_CONFIG.clientName, JSON.stringify(progress));
  }

  trackProgressAvailable = ({ lesson_status, score_max, score_min, score_raw, suspend_data }, rise_data) => {
    let progress = 0;

    let status = 'TODO';

    if (['started', 'failed'].includes(lesson_status)) {
      status = 'ON_GOING';
    } else if (['passed', 'completed'].includes(lesson_status)) {
      status = 'DONE';
      progress = 1;
    }

    const activityData = this.getActivityDataById(this.state.offer.treeOffer.contexts, Number(this.state.currentActivityId));
    this.setState({
      trackedActivityEvaluative: true
    })

    if (['passed', 'completed'].includes(lesson_status)) {
      if (activityData.currentActivity && !activityData.currentActivity.interaction) {
        this.setState({
          loading: true
        }, () => {
          this.setInteraction(activityData.currentActivity.id, progress, status);
        })
      }
    }

    if (['passed', 'completed', 'failed'].includes(lesson_status) && score_raw != this.state.lastGrade) {
      let minGrade = Number(score_min);

      try {
        if (rise_data) {
          const courseData = JSON.parse(rise_data);

          if (courseData.course) {
            if (courseData.course.lessons) {
              minGrade = courseData.course.lessons[0].settings.passingScore
            }
          }
        }
      } catch {}

      this.setState({
        loading: true
      }, () => {
        this.activityEvaluative.post({
          contentOffer: { id: this.state.contentOfferId },
          activity: { id: activityData.currentActivity.id },
          userGrade: Number(score_raw),
          minGrade,
          maxGrade: Number(score_max),
          data: JSON.stringify({ scorm_suspend_data: suspend_data })
        }).then(() => {
          this.setState({
            loading: false,
            lastGrade: score_raw
          })
        })
      })
    }
  }

  bindRemoteUpdateProgress() {
    let _this = this;

    var eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";

    var eventer = window[eventMethod];

    var messageEvent = eventMethod === "attachEvent"
      ? "onmessage"
      : "message";

    eventer(messageEvent, function (e) {
      if (e.data || e.message) {
        var data = e.data || e.message;

        if (data.hasOwnProperty('progress')) {
          let progress = data.progress / 100;
          let status = 'TODO';

          if (progress > 0 && progress < 1) {
            status = 'ON_GOING';
          } else {
            status = 'DONE';
          }

          _this.setState({
            loading: true
          }, function () {
            let activityData = this.getActivityDataById(this.state.offer.treeOffer.contexts, Number(this.state.currentActivityId));

            if (activityData.currentActivity && activityData.currentActivity.interaction) {
              let interaction = activityData.currentActivity.interaction;

              interaction.progress = progress;
              interaction.status = status;

              _this.putInteraction(interaction);
            } else {
              _this.setInteraction(activityData.currentActivity.id, progress, status);
            }
          })
        }
      }
    });
  }

  onClickRating() {
    this.setState({ showModalRating: true });
  }

  onSubmitRating(rating) {
    let _this = this;
    let objRating = {
      comment: rating.review,
      score: rating.ratingStars,
      contentOfferId: this.state.contentOfferId,
      featured: false
    }

    if (!this.state.evaluated && objRating.score > 0) {

      ratingService.insert(objRating).then(function (response) {
        // console.log(response);
        _this.setState({ showModalRating: false, evaluated: true });
      }, function (reason) {
        // console.log(reason);
        _this.setState({
          showModalRating: false
        }, function () {
          _this.setState({ error: RDP_CONFIG.defaultReason });
        });
      });
    } else {
      // console.log('else');
    }
  }

  getUserRating(contentOfferId) {
    let _this = this;
    return new Promise(function (resolve, reject) {
      _this.setState({
        loading: true
      }, function () {
        ratingService.getRating(contentOfferId).then(function (response) {
          resolve(response.length > 0);
        }, function (reason) {
          this.setState({ error: reason });
        });
      });
    });
  }

  onClickCloseModalRating() {
    this.setState({ showModalRating: false });
  }

  getActivityDataById(contexts, activityId) {
    // console.log('getActivityDataById', contexts, activityId);

    let newState = {
      previousActivityId: null,
      nextActivityId: null,
      currentActivityId: null,
      currentActivity: null,
      currentActivityIndex: 0,
      availableActivityId: null,
      availableAssessment: null,
      currentActivities: []
    }

    let activityLength = 0;

    contexts.forEach(function (context) {
      context.nodes.forEach(function (node) {
        node.activities.forEach(function (activity, activity_idx) {

          if (!newState.currentActivity) {
            newState.currentActivityIndex = newState.currentActivities.length;

            if (activity.id === activityId || isNaN(activityId)) {
              newState.currentActivity = activity;
              newState.currentActivityId = activity.id;
            } else {
              newState.previousActivityId = activity.id;
            }
          } else if (!newState.nextActivityId) {
            newState.nextActivityId = activity.id;
          }

          if (!newState.availableActivityId && (!activity.interaction || activity.interaction.status !== 'DONE')) {
            newState.availableActivityId = activity.id;
          }

          if (!activity.assessment && (!activity.interaction || activity.interaction.status !== 'DONE')) {
            activityLength++;
          }

          newState.currentActivities.push(activity);
        });
      })
    });

    newState.availableAssessment = activityLength === 0;

    // console.log(newState);

    return newState;
  }

  getOffer(params) {
    // console.log('getOffer', params);

    var _this = this;

    if (this.state) {
      // console.log('antes', this.state.currentActivityId);
      _this.setState({
        loading: true,
        teste: 1
      }, function () {
        // console.log('dentro', _this.state.currentActivityId);
        offerService.getById(params).then(function (offer) {
          let newState = {
            offer: offer,
            contentOfferId: offer.treeOffer.id,
            loading: true,
            trailType: offer.treeOffer.contexts[0].trailType
          };

          document.title = offer.offerName + " - Trilha do Curso - Page Compliance";

          let activityData = _this.getActivityDataById(offer.treeOffer.contexts, Number(_this.props.match.params.activityId));

          _this.getUserRating(newState.contentOfferId).then(function (evaluated) {
            newState.evaluated = evaluated;
            // inicio
            if (!activityData.currentActivity || newState.trailType === 0) {
              // console.log('sequencial');
              if ((!activityData.currentActivity || !activityData.currentActivity.interaction || activityData.currentActivity.interaction.status !== 'DONE') && (activityData.availableActivityId !== activityData.currentActivityId)) {
                // console.log(0);

                _this.setState(newState, function () {
                  // console.log('redirect to: /edition/' + _this.props.match.params.id + '/' + activityData.availableActivityId);
                  window.location.hash = '/edition/' + _this.props.match.params.id + '/atividade/' + activityData.availableActivityId;
                });
              } else {
                // console.log(1);
                newState = Object.assign(activityData, newState);

                // console.log(newState);
                _this.setState(newState);
              }
            } else {
              // console.log('livre');
              if (activityData.currentActivity.assessment) {
                // console.log(2);

                if (activityData.availableAssessment) {
                  // console.log(3);

                  newState = Object.assign(activityData, newState);

                  _this.setState(newState);
                } else {
                  // console.log(4);

                  _this.setState(newState, function () {
                    // console.log('redirect to: /edition/' + _this.props.match.params.id + '/' + activityData.availableActivityId);
                    window.location.hash = '/edition/' + _this.props.match.params.id + '/atividade/' + activityData.availableActivityId;
                  });
                }
              } else {
                // console.log(5);

                newState = Object.assign(activityData, newState);

                _this.setState(newState, function () { });
              }
            }
            // fim
          });
        }, function (error) {
          _this.setState({ loading: false, error: error });
        });
      });
    }
  }

  backToCourseDetails(event) {
    // console.log('backToCourseDetails', event.currentTarget);

    this.setState({
      redirectTo: '/course/' + this.props.match.params.id
    });
  }

  setInteractionByActivityIdInOffer(interaction) {
    // console.log('setInteractionByActivityIdInOffer', interaction);

    let newOffer = this.state.offer;

    let activityCount = 0;
    let activityDone = 0;

    newOffer.treeOffer.contexts.forEach(function (context) {
      context.nodes.forEach(function (node) {
        node.activities.forEach(function (activity, activity_idx) {
          activityCount++;
          if (activity.id === interaction.activityId) {
            activity.interaction = interaction;
          }

          if (activity.interaction && activity.interaction.status === 'DONE') {
            activityDone++;
          }
        });
      })
    });

    let newState = this.getActivityDataById(newOffer.treeOffer.contexts, Number(this.props.match.params.activityId));

    newState.offer = newOffer;
    newState.loading = false;
    newState.offer.progress = ((100 / activityCount) * activityDone) / 100;

    this.setState(newState);
  }

  setInteraction(activityId, progress, status) {
    // console.log('setInteraction', activityId, progress, status);

    let _this = this;

    // Criando uma interação
    let newInteraction = {
      contentOfferId: this.state.offer.treeOffer.id,
      status: status || "DONE",
      performance: 1,
      progress: progress || 1,
      timeSpent: 0,
      activityId: activityId,
      userId: jwt_decode(this.state.user.authentication_token).user_id
    }

    this.setState({
      loading: true
    }, function () {
      _this.interactionsService.set(newInteraction).then(function (response) {
        _this.setInteractionByActivityIdInOffer(response);
      }, function (reason) {
        _this.setState({ error: reason, loading: false })
      });
    })
  }

  putInteraction(interaction) {
    // console.log('putInteraction', interaction);

    let _this = this;

    this.setState({
      loading: true
    }, function () {
      _this.interactionsService.put(interaction).then(function (response) {
        _this.setInteractionByActivityIdInOffer(response);
      }, function (reason) {
        _this.setState({ error: reason, loading: false })
      });
    });
  }

  handleIframeLoad() {
    console.log('handleIframeLoad');

    let _this = this;

    if (_this.state.currentActivity && _this.state.currentActivity.progressStrategy && _this.state.currentActivity.progressStrategy.name === 'ON_ENTER' && !_this.state.currentActivity.interaction) {
      _this.setInteraction(_this.state.currentActivity.id);
    } else {
      this.setState({ loading: false })
    }
  }

  onClickToggle(currentTrackWidth) {
    // console.log('onClickToggle', currentTrackWidth);

    let newState = {
      trackWidth: ''
    }

    if (currentTrackWidth === 'closed') {
      newState.trackWidth = 'opened';
    } else {
      newState.trackWidth = 'closed';
    }

    this.setState(newState);
  }

  onClickActivity(activityId) {
    // let _this = this;

    this.setState({ focusPlayer: true })

    activityId = Number(activityId);

    var loading = window.location.hash === ('#/edition/' + this.props.match.params.id + '/atividade/' + activityId) ? false : true;

    //  trilha sequencia trailType === 0
    if (this.state.trailType === 0) {
      let activityData = this.getActivityDataById(this.state.offer.treeOffer.contexts, Number(activityId));

      if ((activityData.currentActivity.interaction && activityData.currentActivity.interaction.status === 'DONE') || activityData.availableActivityId === Number(activityId)) {
        this.setState({
          trackWidth: 'closed',
          loading: loading
        }, function () {
          window.location.hash = '/edition/' + this.props.match.params.id + '/atividade/' + activityId;
        });
      }
    } else {
      let activityData = this.getActivityDataById(this.state.offer.treeOffer.contexts, Number(activityId));

      if (!activityData.currentActivity.assessment || (activityData.currentActivity.assessment && activityData.availableAssessment)) {
        this.setState({
          trackWidth: 'closed',
          loading: loading
        }, function () {
          window.location.hash = '/edition/' + this.props.match.params.id + '/atividade/' + activityId;
        });
      }
    }
  }

  onFocusPlayer(option) {

    this.setState({ focusPlayer: option })
  }

  render() {
    //console.log('ViewEdition', 'render', this.state);

    var error,
      loader;

    if (!this.state.user) {
      return <Redirect to="/login" />;
    }

    if (this.state.loading) {
      loader = <Loader />;
    }

    if (this.state.error) {
      error = <ErrorDialog error={this.state.error} />;
    }

    if (this.state.redirectTo) {
      return (<Redirect to={this.state.redirectTo} />);
    } else {
      return <div className={`rdp-view rdp-view-edition ${this.state.trackWidth}`}>
        {loader}
        {error}
        <Header className="rdp-header-edition-app" noBgTransition={true} fullWidth={true} buttonBack={true} onClickBack={event => this.backToCourseDetails(event)} headerOnEdition={true} readLogo={false} tabEdition={true} />
        <section data-activity-id={this.state.currentActivityId} className={`rdp-view-edition-container ${this.state.currentActivityId > 0 ? 'fixed' : ''}`} >
          {
            this.state.showModalRating
              ? <ModalRating onClickCloseModalRating={this.onClickCloseModalRating} onSubmitRating={this.onSubmitRating} />
              : null
          }
          {
            this.state.offer && this.state.currentActivity
              ? <Track trailType={this.state.trailType} nextActivityId={this.state.nextActivityId} availableAssessment={this.state.availableAssessment} evaluated={this.state.evaluated} onClickRating={this.onClickRating} width={this.state.trackWidth} currentActivityId={this.state.currentActivityId} onClickActivity={this.onClickActivity} onClickToggle={this.onClickToggle} offer={this.state.offer} onFocusPlayer={this.onFocusPlayer} />
              : null
          }
          {
            this.state.offer && this.state.currentActivity
              ? <Player
                trackProgressAvailable={this.trackProgressAvailable}
                RDPAnalytics={this.props.RDPAnalytics}
                handleIframeLoad={this.handleIframeLoad}
                activity={this.state.currentActivity}
                focus={this.state.focusPlayer} />
              : null
          }

          <div className="rdp-edition-navigation">
            {
              this.state.previousActivityId
                ? <button tabIndex="3" aria-label="conteúdo anterior" type="button" onClick={() => this.onClickActivity(this.state.previousActivityId)} className="nav-btn prev-btn"></button>
                : null
            }
            {
              this.state.nextActivityId
                ? <button tabIndex="2" aria-label="próximo conteúdo" onClick={() => this.onClickActivity(this.state.nextActivityId)} className="nav-btn next-btn"></button>
                : null
            }
          </div>
        </section>
      </div>;
    }
  }
}
