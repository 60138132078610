// import {RDP_CONFIG} from '../config';
// import {RDP_Utils} from '../utils';
// import {authenticationService} from './authentication';
import {mockService} from './mock';

var Terms = function() {
  // var _this = this;

  // var RDP_USER = authenticationService.getLocalUserData();

  this.getTerms = function() {
  // console.log('getTerms');

    return new Promise(function(resolve, reject) {
      resolve(mockService.terms());

      // RDP_Utils.request({
      //   url: `${RDP_CONFIG.learningURL}restapi/api/protected/enums/terms`,
      //   type: 'GET',
      //   headers: {
      //     'Authorization': 'Bearer ' + RDP_USER.authentication_token
      //   }
      // }).then(function(response) {
      //   response.content = mockService.terms(response.content);
      //   resolve(response);
      // }, function(jqXHR) {
      //   var reason;
      //
      //   if (jqXHR.responseJSON) {
      //     reason = jqXHR.responseJSON;
      //   } else {
      //     reason = RDP_CONFIG.defaultReason;
      //   }
      //
      //   reject(reason);
      // });
    });
  }

  return this;
}

var termsService = new Terms();

export {
  termsService
};
