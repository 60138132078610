import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { authenticationService } from '../../services';
import { RDP_Utils } from '../../utils';
import './login.css';
//import alert from './alert.svg'
import { ButtonArrow, Loader, ErrorDialog, Version } from '../../components';

export class ViewLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      redirect: false
    }

    this.onSubmitForm = this.onSubmitForm.bind(this);

    // console.log('ViewLogin', 'constructor');
  }

  componentDidMount() {
    document.title = "Login - Page Compliance";

    if (authenticationService.getLocalUserData()) {
      window.dispatchEvent(new Event("rdp-session-login"))
      this.setState({ redirect: true });
    }
  }

  onSubmitForm(event) {
    var _this = this;

    event.preventDefault();

    var form = event.currentTarget;
    var data = {}

    data['username'] = form['username'].value;
    data['password'] = form['password'].value;

    _this.signIn(data);
  }

  customizeUser(username) {
    //  console.log('customizeUser', username);

    if (username.indexOf('@') < 0) {

      let newUser;

      // remove todos "não números"
      newUser = username.replace(/\D/g, '');

      if (newUser.length === 11) {
        // adiciona a mascara padrão
        username = newUser.slice(0, 3) + "." + newUser.slice(3, 6) + "." + newUser.slice(6, 9) + "." + newUser.slice(9, 11);
      }
    }

    return username;
  }

  signIn(params) {
    var _this = this;

    // adequa o username para possiveis mascaras
    params.username = this.customizeUser(params.username);

    _this.setState({
      loading: true,
      error: false
    }, function () {
      authenticationService.signIn(params).then(function (response) {
        window.dispatchEvent(new Event("rdp-session-login"))
        _this.setState({ loading: false, error: false, redirect: true });
      }, function (reason) {
        _this.setState({
          loading: false,
          error: {
            custom_message: !RDP_Utils.onLine()
              ? 'Sem conexão com a internet. Verifique sua conexão e tente novamente'
              : 'Login ou senha incorretos'
          }
        });
      });
    });
  }

  render() {
    var loader,
      error;

    if (this.state.loading) {
      loader = <Loader />;
    }

    if (this.state.redirect) {
      return <Redirect to="/" />;
    }

    if (this.state.error) {
      error = <ErrorDialog top="top" error={this.state.error} />;
    }

    //  console.log("isOnline: " + this.state.isOnline + ", inApp: " + this.state.inApp);

    return <section className="rdp-view rdp-view-login rdp-bg-squares">
      {error}
      {loader}
      <div className="rdp-container">
        <form className="rdp-form-login" onSubmit={this.onSubmitForm}>
          <div className="fieldset">
            <span tabIndex="1" aria-label="Page Compliance"></span>
            <div className="legend rdp-title-transparency">Page Compliance</div>
            <div className="rdp-form-group">
              <label htmlFor="username" className="rdp-sr-only">Usuário*</label>
              <input tabIndex="0" type="text" name="username" id="username" className="rdp-form-control" placeholder="Usuário" aria-label="Campo de login do sistema" aria-required="true" required="required" />
            </div>

            <div className="rdp-form-group">
              <label htmlFor="password" className="rdp-sr-only">Senha*</label>
              <input tabIndex="0" type="password" name="password" id="password" className="rdp-form-control" placeholder="Senha" aria-label="Campo de senha do sistema" aria-required="true" required="required" />
            </div>

            <div alt="botão para entrar" className="rdp-form-group rdp-form-actions">
              <ButtonArrow tabIndex="0" type="submit" textButton="Entrar" className="rdp-btn-arrow-orange" ariaLabel="Entre" /> {
                RDP_Utils.onLine()
                  ? (<p className="rdp-support-actions">
                    {/* <Link tabIndex="0" to="/support-login" className="rdp-link">Dificuldades no acesso</Link> */}
                    <Link tabIndex="0" to="/forgot-password" className="rdp-link">Esqueci minha senha</Link>
                  </p>)
                  : null
              }

            </div>
          </div>
          <Version show={true} />
        </form>
      </div>
    </section>;
  }
}
