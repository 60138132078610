import $ from 'jquery';
import {RDP_Utils} from '../utils';
import {RDP_CONFIG} from '../config';
import {userService} from './index';

var Authentication = function() {
  var _this = this;

  this.getLocalUserData = function() {
    // console.log('authenticationService', 'getLocalUserData');

    return JSON.parse(window.localStorage.getItem("rdp-new-client-" + RDP_CONFIG.clientName)) || false;
  }

  this.patchLocalUserData = function(params) {
    // console.log('authenticationService', 'patchLocalUserData', params);

    let currentUser = _this.getLocalUserData();

    Object.entries(params).forEach(function([key, value]) {
      currentUser[key] = value;
    });

    window.localStorage.setItem("rdp-new-client-" + RDP_CONFIG.clientName, JSON.stringify(currentUser));
  }

  this.addLocalUserData = function(params) {
    // console.log('authenticationService', 'addLocalToken :', params);

    var localUserData = {
      id: params['jti'],
      login: params['username'],
      authentication_token: params['access_token'],
      system_id: null,
      references_token: null,
      avatar: params['avatar'],
      name: params['name'],
      socialName: params['socialName']
    };

    // console.log('authenticationService', 'localUserData: ', localUserData);

    window.localStorage.setItem("rdp-new-client-" + RDP_CONFIG.clientName, JSON.stringify(localUserData));
  }

  this.signIn = function(params) {
    // console.log('authenticationService', 'signIn', params);

    params['grant_type'] = 'password';
    params['scope'] = 'read';

    return new Promise(function(resolve, reject) {

      console.log(RDP_CONFIG.azureApisHeaderParam);
      var _header = {
        "Authorization": "Basic " + btoa('client-read:3a60fe01-1662-4ccc-8970-de8872d8457f')
      };
      Object.assign(_header, RDP_CONFIG.azureApisHeaderParam);

      $.ajax({
        url: RDP_CONFIG.learningURL + 'manager/oauth/token',
        type: 'POST',
        headers: _header,
        data: params,
        success: function(response) {
          response['username'] = params['username'];

          if (RDP_Utils.hasCordova()) {
            response['email'] = params['username'];

            window.CordovaExec(function() {
              _this.setState({loading: false});
            }, function() {
              _this.setState({loading: false});
            }, "PaywallPlugin", "AuthenticateWithContent", [response]);
          }

          _this.addLocalUserData(response);

          userService.getCurrent().then(function(userResponse) {
            // console.log('authenticationService', 'getCurrent: ', userResponse);
            response['name'] = userResponse['name'];
            response['socialName'] = userResponse['socialName'];

            response['avatar'] = userResponse['avatar'];

            // console.log("response: ", response);

            _this.addLocalUserData(response);
            resolve(response);
          }, function(reason) {
            // console.log('authenticationService', reason);
            reject(reason);
          });
        }
      }).fail(function(jqXHR) {
        var reason;

        // console.log('authenticationService', jqXHR);

        if (jqXHR.responseJSON) {
          if (jqXHR.responseJSON.message === "Bad Credentials" || jqXHR.responseJSON.description === "Bad Credentials") {
            reason = {
              custom_message: "Usuário e/ou senha inválidos"
            }
          } else {
            reason = jqXHR.responseJSON;
          }
        } else {
          reason = {
            custom_message: "Ops, aconteceu algum erro"
          }
        }

        // console.log('authenticationService', reason);
        reject(reason);
      });

    });
  }

  return this;
}

var authenticationService = new Authentication();

export {
  authenticationService
};
