import React, {Component} from 'react';

import {Loader} from '../../components';

import {offerService, searchService} from '../../services';

import './search-bar.css';

export default class SearchBar extends Component {
  constructor(props) {
    super(props);

    // console.log(this.props);

    this.state = {
      loading: false,
      error: false,
      categoriesList: [],
      offers: [],
      searchOptions: []
    }

    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onFilterByCategory = this.onFilterByCategory.bind(this);
    this.onFilterByProgress = this.onFilterByProgress.bind(this);
    this.dispatchCountOffersByCategories = this.dispatchCountOffersByCategories.bind(this);
    this.updateCategoriesWithCount = this.updateCategoriesWithCount.bind(this);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.onResetForm = this.onResetForm.bind(this);
    this.onClickOption = this.onClickOption.bind(this);
  }

  componentWillMount() {
    this.getCategories();
  }

  onSubmitForm(event) {

    // var _this = this;

    event.preventDefault();

    this.props.onSubmitSearch(event.target.toJSON());
  }

  onClickOption(event) {
    var inputSearch = document.getElementById("search");
    inputSearch.value = event.target.textContent;
    var button = document.getElementById("rdp-btn-submit");
    button.click();
    this.setState({searchOptions: []});
  }

  onSearchInputChange(event) {
    var currentVal = event.target.value;
    var _this = this;

    if (currentVal.length === 0) {
      this.buttonElement.click();
      _this.setState({searchOptions: []});
    }

    if (currentVal.length >= 3) {
      searchService.autoComplete(currentVal).then(function(response) {
        _this.setState({searchOptions: response});
      });
    } else {
      _this.setState({searchOptions: []});
    }
  }

  onFilterByCategory(event) {
    event.preventDefault();
    var categoryId = event.target.value;
    this.props.onFilterByCategory(categoryId);
  }

  onFilterByProgress(event) {
    event.preventDefault();
    var progressStatusId = event.target.value;
    this.props.onFilterByProgress(progressStatusId);
  }

  handleLangChange(result) {
    this.props.onGetResult(result);
  }

  onResetForm() {
    this.props.resetFilters();
  }

  getCategories() {
    var _this = this;
    searchService.getAreaList().then(response => {
      var categories = response._embedded.categories.map(function(item) {
        item.quantity = 0;
        return item;
      });

      _this.setState({categoriesList: categories});
    });

    _this.dispatchCountOffersByCategories();
  }

  dispatchCountOffersByCategories() {
    this.props.countOfferByCategories();
  }

  updateCategoriesWithCount() {
    var _this = this;
    if (!_this.state.categoriesList) {
      return;
    }

    var categories = _this.state.categoriesList;
    var numCat = categories.length;

    for (var i = numCat - 1; i >= 0; i--) {
      categories[i].quantity = _this.props.categoriesCount[categories[i].id]
        ? _this.props.categoriesCount[categories[i].id]
        : 0;
    }
  }

  // Methods
  search(params) {
    // console.log('singIn', params);

    var _this = this;

    _this.setState({
      loading: true,
      error: false
    }, function() {
      offerService.getByTerm(params).then(function(response) {
        _this.setState({
          loading: false,
          error: false
        }, function() {
          _this.handleLangChange(response);
        });
      }, function(reason) {
        _this.setState({loading: false, error: reason});
      });
    });
  }

  render() {
    var _this = this;
    var loader,
      areaOptions = [];

    var searchOptions = [];

    this.state.searchOptions.map(function(item, idx) {
      searchOptions.push(<li key={idx} className="rdp-input-option" onClick={_this.onClickOption}>{item}</li>);
      return item;
    });

    if (this.state.loading) {
      loader = <Loader/>;
    }

    this.state.categoriesList.forEach(option => {
      areaOptions.push(<option key={option.id} value={option.id}>
        {option.name}
      </option>);
    });

    var sectionClass = this.props.white
      ? ' rdp-section-white'
      : '';

    return <section tabIndex="-1" className={'rdp-section-search' + sectionClass}>
      {loader}
      <div aria-label="busca de disciplinas" className="rdp-container">
        <form className="rdp-form-search" onSubmit={this.onSubmitForm}>
          <div className="fieldset">
            <div className="rdp-form-group">
              <label htmlFor="search" className="rdp-sr-only">Campo de texto para pesquisa do curso de seu interesse.</label>
              <input type="text" id="search" className="rdp-form-control" name="search" placeholder="Pesquisar curso..." autoComplete="off" onChange={this.onSearchInputChange}/>

              <button ref={btnSearch => this.buttonElement = btnSearch} id="rdp-btn-submit" type="submit" className="rdp-ico-search">
                <span>pesquisar</span>
              </button>
              <div className="rdp-search-options">
                <ul>
                  {searchOptions}
                </ul>
              </div>
            </div>

            {/* <div className="rdp-filters">
              <div className="rdp-form-group rdp-form-group-all-areas">
                <label htmlFor="filter-by-area" className="rdp-sr-only">Filtro de busca compreendendo todas as áreas dos cursos.</label>
                <div className="rdp-custom-select">
                  <select id="filter-by-area" name="filter-by-area" onChange={this.onFilterByCategory} aria-label="Filtro de busca compreendendo todas as áreas dos cursos. Navegue entre as caixas de combinação através do tab e utilize as setas cima e baixo para selecionar os itens.">
                    <option value="0">Todas as áreas</option>
                    {areaOptions}
                  </select>
                </div>
              </div>

              
              <div className="rdp-form-group">
                <label htmlFor="courses" className="rdp-sr-only">Filtro de busca compreendendo todos cursos disponíveis até o momento.</label>
                <div className="rdp-custom-select">
                  <select id="courses" name="courses" onChange={this.onFilterByProgress} aria-label="Filtro de busca compreendendo todos cursos disponíveis até o momento. Navegue entre as caixas de combinação através do tab e utilize as setas cima e baixo para selecionar os itens.">
                    <option tabIndex="-1" value="0">Todos os cursos</option>
                    <option tabIndex="-1" value="1">Meus cursos</option>
                    <option tabIndex="-1" value="2">Em andamento</option>
                    <option tabIndex="-1" value="3">Concluídos / Certificados</option>
                  </select>
                </div>
              </div>
              
            </div> */}
            <div className="rdp-form-group rdp-form-group-actions">
              <button type="button" onClick={this.onResetForm} className="rdp-btn-reset">
                <label htmlFor="courses" className="rdp-sr-only">Limpe as seleções dos filtros.</label>
                Limpar filtros
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>;
  }
}
