import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {offerService, authenticationService} from '../../services';
import $ from 'jquery';
import './my-courses.css';

import {
  Courses,
  // Header,
  HeaderV2,
  Footer,
  Navigation,
  EmptyExplore,
  ErrorDialog,
  Loader
} from '../../components';

export class ViewMyCourses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      offers: null,
      authed: authenticationService.getLocalUserData()
    }
  }

  componentWillMount() {
    if (this.state.authed) {
      this.getEnrolledOffers({});
    }
  }

  componentDidMount() {
    document.title = "Meus Cursos - Page Compliance";

    this.scrollToTop();
  }

  scrollToTop() {
    $('html,body').scrollTop(0);
  }

  getEnrolledOffers(params) {
    // // console.log('getEnrolledOffers', params);

    var _this = this;

    _this.setState({
      loading: true,
      error: false
    }, function() {
      offerService.getEnrolled(params).then(function(response) {
        _this.setState({loading: false, error: false, offers: response});
      }, function(reason) {
        _this.setState({loading: false, error: reason});
      });
    });
  }

  render() {
    // // console.log('ViewMyCourses', 'render', this);

    var loader,
      error,
      view;

    if (!this.state.authed) {
      return <Redirect to="/login"/>;
    }

    if (this.state.loading) {
      loader = <Loader/>;
    }

    if (this.state.error) {
      error = <ErrorDialog error={this.state.error}/>;
    }

    // // console.log(this.state.offers);

    if(this.state.offers) {
      if (this.state.offers.length > 0) {
        view = <Courses offers={this.state.offers}/>;
      } else {
        view = <EmptyExplore/>;
      }
    }
    // Conheça os Cursos disponíveis

    return (<div className="rdp-view rdp-view-my-courses">
      {error}
      {loader}
      {/* <Header noBgTransition/> */}
      <HeaderV2 navigation={<Navigation/>} label="Meus Cursos"/>
      <div className="rdp-main">
        {view}
        {/* <Navigation/> */}
      </div>
      <Footer/>
    </div>);
  }
}
