import React, {Component} from 'react';
import $ from 'jquery';
// import RDP_Utils from 'rdputils';
import {authenticationService} from '../../services';

import {Header, Footer, Navigation, Loader, ErrorDialog} from '../../components';

import './media.css';

export class ViewMedia extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      user: authenticationService.getLocalUserData()
    }

    // console.log(this);
  }

  componentDidMount() {
    document.title = "Midia - Page Compliance";

    this.scrollToTop();
  }

  scrollToTop() {
    $('html, body').scrollTop(0);
  }

  getMedia(params) {
    let media;

    params.value = window.decodeURIComponent(params.value);

    if (params.type === 'img') {
      media = <img className='media-tag' src={params.value} alt='media'/>;
    } else if (params.type === 'video') {
      media = <video className='media-tag' src={params.value} controls/>;
    } else if (params.value === 'audio') {
      media = <audio className='media-tag' src={params.value} controls/>;
    }

    return media;
  }

  render() {
    let loader,
      error;

    if (this.state.loading) {
      loader = <Loader/>;
    }

    if (this.state.error) {
      error = <ErrorDialog error={this.state.error}/>;
    }

    let media = this.getMedia(this.props.match.params);

    return (<div className="rdp-view rdp-view-media">
      {error}
      {loader}
      <Header noBgTransition="noBgTransition"/>
      <div className="rdp-main">
        <section className="rdp-context-container">
          <div className="rdp-container">
            <div className="rdp-media-wrap">
              {media}
            </div>
          </div>
        </section>
        <Navigation/>
      </div>
      <Footer/>
    </div>);
  }
}
