import React, {Component} from 'react';

import './rating.css';

export default class ModalRating extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ratingStars: null,
      review: '',
      textAreaInteraction: false
    }

    this.onClickSendRating = this.onClickSendRating.bind(this);
    this.onClickRatingStar = this.onClickRatingStar.bind(this);
    this.setTextReview = this.setTextReview.bind(this);
    this.onChangeTextArea = this.onChangeTextArea.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
  }

  componentDidMount() {
  // console.log('componentDidMount()');
  }

  onClickClose() {
    this.props.onClickCloseModalRating();
  }

  onChangeTextArea(event) {
  // console.log('onChangeTextArea()');
    this.setState({review: event.target.value, textAreaInteraction: true});
  }

  setTextReview() {
  // console.log('setTextReview()');
    if (!this.state.textAreaInteraction) {
    // console.log('setTextReview() entrou');
      var starValue = this.state.ratingStars;

      if (starValue === 5) {
        this.setState({review: "O curso superou minhas expectativas."});
      } else if (starValue === 4) {
        this.setState({review: "O curso foi muito interessante."});
      } else if (starValue === 3) {
        this.setState({review: "O curso atendeu às minhas expectativas."});
      } else if (starValue === 2) {
        this.setState({review: "O curso poderia ser mais esclarecedor."});
      } else if (starValue === 1) {
        this.setState({review: "O curso ficou aquém das minhas expectativas."});
      }
    }
  }

  onClickSendRating() {
    let _this = this;
  // console.log("onClickSendRating()");

    var reviewElement = document.getElementsByClassName("rdp-rating-comments")[0];
    var reviewText = reviewElement.value;

  // console.log(reviewText);

    this.setState({review: reviewText}, function() {
    // console.log("state", _this.state);
      _this.props.onSubmitRating(_this.state);
    });

  }

  onClickRatingStar(event) {
  // console.log("onClickRatingStar()");

    var starValue = event.target.dataset.star;
    var element;
    var i = 1;
    for (; i <= 5; i++) {
      element = "star-button-" + i;
      if (i <= starValue) {
        document.getElementById(element).classList.add("active");
      } else {
        document.getElementById(element).classList.remove("active");
      }
    }

    this.setState({ratingStars: parseInt(starValue, 10)}, function() {
        this.setTextReview();
    });
  }

  render() {
  // console.log('RatingComponent render');

    return(
      <div className={"rdp-modal-rating"}>
        <div className="rdp-rating-content">
          <h2 className="rdp-rating-title"> Parabéns, você concluiu o curso!
            <small className="rdp-rating-subtitle">Gostaria de deixar uma avaliação?</small>
          </h2>

          <button className="rdp-button-close" id="rdp-ico-close" onClick={this.props.onClickCloseModalRating}></button>

          <div className="rdp-rating-stars">
            <button type="button" id="star-button-1" className="rdp-ico-star-off" onClick={this.onClickRatingStar} data-star="1"><span></span></button>
            <button type="button" id="star-button-2" className="rdp-ico-star-off" onClick={this.onClickRatingStar} data-star="2"><span></span></button>
            <button type="button" id="star-button-3" className="rdp-ico-star-off" onClick={this.onClickRatingStar} data-star="3"><span></span></button>
            <button type="button" id="star-button-4" className="rdp-ico-star-off" onClick={this.onClickRatingStar} data-star="4"><span></span></button>
            <button type="button" id="star-button-5" className="rdp-ico-star-off" onClick={this.onClickRatingStar} data-star="5"><span></span></button>
          </div>

          <form>
            <textarea className="rdp-rating-comments" id="rdp-rating-comments" onChange={this.onChangeTextArea} placeholder="Até 250 caracteres..."></textarea>
            <button className="send-rating" type="button" onClick={this.onClickSendRating}>Enviar</button>
          </form>
        </div>
      </div>
    );
  }
}
