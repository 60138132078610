import React, { Component, createRef } from 'react';
import { NavLink } from 'react-router-dom';
import {RDP_Utils} from '../../utils';

import {
  DropdownUser,
  DropdownSystem
} from '../../components';
import './navigation.css';

export default class Navigation extends Component {
  constructor(props) {
    super(props);

    this.dropdownUser = createRef();
  }

  updateProfileImage(image) {
    var _this = this;
    _this.dropdownUser.current.updateProfileImage(image);
  }

  render() {

    return (
      <nav className="rdp-navigation">
        <ul className="rdp-navigation-list">
          <li className="rdp-navigation-item rdp-navigation-item-mobile">
            <DropdownSystem/>
          </li>

          <li className="rdp-navigation-item rdp-navigation-item-mobile">
            <NavLink exact={true} to="/explore" className="rdp-ico-explore" activeClassName="rdp-active" aria-label="Explorar">
              <span>Explorar</span>
            </NavLink>
          </li>

          <li className="rdp-navigation-item rdp-navigation-item-mobile">
            <NavLink exact={true} to="/my-courses" className="rdp-ico-courses" activeClassName="rdp-active" aria-label="Catálogo de Cursos">
              <span>Catalogo de cursos</span>
            </NavLink>
          </li>

          <li className="rdp-navigation-item rdp-navigation-item-desktop">
            <NavLink tabIndex="1" exact={true} to="/" activeClassName="rdp-active" aria-label="Todos os Cursos">
              <span>Todos os cursos</span>
            </NavLink>
          </li>

          <li className="rdp-navigation-item rdp-navigation-item-desktop">
            <NavLink tabIndex="2" exact={true} to="/faq" activeClassName="rdp-active" aria-label="Seção de perguntas frequentes">
              <span>Perguntas frequentes</span>
            </NavLink>
          </li>

          <li className={'rdp-navigation-item rdp-navigation-item-desktop' + (!RDP_Utils.onLine() ? ' rdp-navigation-hide-offline' : '')}>
            <NavLink tabIndex="2" exact={true} to="/support" className="rdp-ico-support" activeClassName="rdp-active" aria-label="Canal de ajuda">
              <span>Ajuda</span>
            </NavLink>
          </li>

          {/* <li className={'rdp-navigation-item rdp-navigation-item-desktop' + (!RDP_Utils.onLine() ? ' rdp-navigation-hide-offline' : '')}>
            <NavLink tabIndex="4" exact={true} to="/logout" className="rdp-ico-support" activeClassName="rdp-active" aria-label="Sair" alt="sair do site">
              <span>Sair</span>
            </NavLink>
          </li> */}

          <li className="rdp-navigation-item drop-menu-item">
            <DropdownUser tabIndex="2" ref={this.dropdownUser}/>
          </li>
        </ul>
      </nav>
    );
  }
}
